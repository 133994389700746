export function pathWithName(context: any, path: string) {
  return path
    .replace(/steps\[\?\(@.id==([0-9]+)*\)\]/, (item, $1) => {
      const step = context ? context.steps.find((step) => step.id == $1) : null;
      return step ? `steps[${step.name}]` : item;
    })
    .replace(/actorsState\[([0-9]+)*\]/, (item, $1) => {
      const actor = context ? context.actors[$1] : null;
      return actor ? `actorsState[${actor.name}]` : item;
    });
}
export function getParameterIdFromPath(path: string): string {
  if (path && path.includes("@.id==")) {
    return path.split("@.id==")[1].split(")]")[0];
  } else return path;
}

export function comparePath(context: any, path: string) {
  const idTabStep = path.match(/steps\[\?\(@.id==([0-9]+)*\)\]/);
  const idTabEquipment = path.match(
    /equipmentsState\[\?\(@.equipmentId==([0-9]+)*\)\]/
  );
  const idTabParameter = path.match(/parameters\[\?\(@.Id==([0-9]+)*\)\]/);

  if (path.includes("parameters")) {
    return path.replace(/parameters\[([0-9]+)*\]/, (item, $1) => {
      return idTabParameter && idTabParameter[1]
        ? `parameters[?(@.Id==${idTabParameter[1]})]`
        : item;
    });
  }

  return path
    .replace(/steps\[([0-9]+)*\]/, (item, $1) => {
      return idTabStep && idTabStep[1]
        ? `steps[?(@.Id==${idTabStep[1]})]`
        : item;
    })
    .replace(/equipmentsState\[([0-9]+)*\]/, (item, $1) => {
      return idTabEquipment && idTabEquipment[1]
        ? `equipmentsState[?(@.equipmentId==${idTabEquipment[1]})]`
        : item;
    });
}

export function getAttributesFromPathBehavior(path, scenario) {
  let partialScheme = {
    descriptionBehavior: "",
    abstractValuesBehavior: [],
    nameBehavior: "",
    unit: [],
    isOK: false,
  };
  const idsBehavior = path.match(/behaviorStates\[([0-9]+)*\]/);
  const idBehavior = idsBehavior && idsBehavior[1] ? +idsBehavior[1] : null;
  const behavior = scenario.behaviorStates[idBehavior]?.behavior;
  if (!behavior) {
    return partialScheme;
  }
  const idsValues = path.match(/values\[([0-9]+)*\]/);
  const idValue = idsValues && idsValues[1] ? +idsValues[1] : null;
  const value = behavior.parameters[idValue];
  if (!value) {
    return partialScheme;
  }
  return {
    descriptionBehavior: behavior.description,
    abstractValuesBehavior: value.abstractValues,
    nameBehavior: behavior.name,
    unit: [value.unit],
    isOK: true,
  };
}

export function getAttributesFromPathEquipmentState(path, scenario) {
  let partialScheme = {
    descriptionEquipment: "",
    abstractValuesEquipment: [],
    nameEquipement: "",
    unit: [],
    isOK: false,
  };
  const tabIdSteps = path.match(/steps\[\?\(@.id==([0-9]+)*\)\]/);
  const idStep = tabIdSteps && tabIdSteps[1] ? tabIdSteps[1] : null;
  const steps = scenario.steps.find((step) => step.id == idStep);
  if (!steps) {
    return partialScheme;
  }

  const tabIdActors = path.match(/actorsState\[([0-9]+)*\]/);
  const idActor = tabIdActors && tabIdActors[1] ? +tabIdActors[1] : null;
  const actor = steps.actorsState[idActor];
  if (!actor) {
    return partialScheme;
  }

  const tabIdEquipment = path.match(
    /equipmentsState\[\?\(@.equipmentId==([0-9]+)*\)\]/
  );
  const idEquipment =
    tabIdEquipment && tabIdEquipment[1] ? +tabIdEquipment[1] : null;
  const equipment = scenario.actors[idActor].equipments.find(
    (eq) => eq.id === idEquipment
  );
  if (!equipment) {
    return partialScheme;
  }

  const tabIdValues = path.match(/values\[([0-9]+)*\]/);
  const idValue = tabIdValues && tabIdValues[1] ? +tabIdValues[1] : null;
  const parameter = equipment.parameters[idValue];
  if (!parameter) {
    return partialScheme;
  }

  return {
    descriptionEquipment: equipment.description,
    abstractValuesEquipment: parameter.abstractValues,
    nameEquipement: parameter.name,
    unit: parameter.unit ? [parameter.unit] : null,
    isOK: true,
  };
}

export function uuidNumber() {
  return Math.floor(Math.random() * 999999) + 10000000;
}

// This is different from Angular's SimpleChange as it adds generic type T
export interface SimpleChange<T> {
  firstChange: boolean;
  previousValue: T;
  currentValue: T;
  isFirstChange: () => boolean;
}

export function generatePaths(root, className = "Scenario", dataModel) {
  let paths = [];
  let nodes = [
    {
      obj: root,
      scheme: null,
      subSchemes: dataModel.getParameters(className),
      path: "$",
    },
  ];
  while (nodes.length > 0) {
    let node = nodes.pop();
    Object.keys(node.obj || {}).forEach((k) => {
      let path;
      let scheme;
      if (Array.isArray(node.obj)) {
        if (typeof node.obj[k] === "object" && "id" in node.obj[k]) {
          path = `${node.path}[?(@.id==${node.obj[k].id})]`;
        } else {
          path = `${node.path}[${k}]`;
        }
        scheme = node.scheme;
      } else {
        path = `${node.path}.${k}`;
        scheme = node.subSchemes.find((s) => s.name == k);
      }
      let subSchemes = dataModel.getParameters(
        scheme ? scheme.className : null
      );
      if (typeof node.obj[k] === "object") {
        nodes.unshift({
          obj: node.obj[k],
          scheme,
          subSchemes,
          path: path,
        });
      }

      if (subSchemes.find((s) => s.className == "MultiLevelParam"))
        paths.push(path);
    });
  }

  return paths;
}

export function searchTree(element, id) {
  if (element.id == id) {
    return element;
  } else if (element.children != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], id);
    }
    return result;
  }
  return null;
}

export function agregateScenarios(
  existingScenarios = [],
  matchingScenarios = []
) {
  if (!matchingScenarios || !matchingScenarios.length) {
    return existingScenarios;
  }

  const idsScenariosToUpdate = existingScenarios.map(
    (scenario) => scenario.resource.id
  );
  const newScenarios =
    matchingScenarios.filter(
      (scenario) => idsScenariosToUpdate.indexOf(scenario.resource.id) < 0
    ) || [];
  const updatedExistingScenarios = existingScenarios.map((scenario) => {
    const newScenario = matchingScenarios.find(
      (scenarioAdded) => scenarioAdded.resource.id === scenario.resource.id
    );
    if (newScenario) {
      return {
        ...scenario,
        children: scenario.children.concat(newScenario.children),
        nbChildren: scenario.children.length + newScenario.children.length,
      };
    }
    return scenario;
  });
  if (newScenarios.length) {
    return [...updatedExistingScenarios, ...newScenarios];
  }
  return updatedExistingScenarios;
}

/**
 * Decorator useful to avoid get and set in the component
 * @param callback this is call when value change
 * @returns Onchange function
 * Intuitive, easy to use, less code, better readability.
  As powerful as ngOnChanges since simpleChange is available
 Hide _cachedValue from developer, no more “ghost property”.
Better typing. SimpleChange.previousValue is typed to a generic type.
It can also be used with a non-@Input property.
It’s not specific to Angular. So it can be used as long as it’s TypeScript such as React in TypeScript.
NB: The TypeScript decorator is experimental and is bound to change as the specs move along. So, use it with caution.
 */
export function OnChange<T = any>(
  callback: (value: T, simpleChange?: SimpleChange<T>) => void
) {
  const cachedValueKey = Symbol();
  const isFirstChangeKey = Symbol();
  return (target: any, key: PropertyKey) => {
    Object.defineProperty(target, key, {
      set: function (value) {
        // change status of "isFirstChange"
        if (this[isFirstChangeKey] === undefined) {
          this[isFirstChangeKey] = true;
        } else {
          this[isFirstChangeKey] = false;
        }
        // No operation if new value is same as old value
        if (!this[isFirstChangeKey] && this[cachedValueKey] === value) {
          return;
        }
        const oldValue = this[cachedValueKey];
        this[cachedValueKey] = value;
        const simpleChange: SimpleChange<T> = {
          firstChange: this[isFirstChangeKey],
          previousValue: oldValue,
          currentValue: this[cachedValueKey],
          isFirstChange: () => this[isFirstChangeKey],
        };
        callback.call(this, this[cachedValueKey], simpleChange);
      },
      get: function () {
        return this[cachedValueKey];
      },
    });
  };
}
