import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
} from "@angular/common/http";
import { KeycloakService } from "keycloak-angular";

import { ConfigureService } from "service/ng4-configure/ng4-configure.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommunService } from "app/shared/commun.service";

declare var require: any;

var config = require("assets/config.json");

@Injectable({
  providedIn: "root",
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    public configService: ConfigureService,
    public keycloakService: KeycloakService,
    public snackBar: MatSnackBar,
    protected router: Router,
    public communservice: CommunService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let skipError = false;
    if (request.params) {
      skipError = request.params.has("skipError");
      request = this.handleRequestParams(request);
    }

    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((err) => {
        if (!skipError) {
          console.error(err);
          let messageOnly;
          // Make sure we handle our defined status code
          if (err.error && err.error.message && !err.url.includes("/image/")) {
            console.error("errGot", err);
            if (err.status == 401) {
              this.keycloakService.login();
            } else if ([400, 404].indexOf(err.status) >= 0) {
              messageOnly = err.error.message.substring(
                err.error.message.indexOf(": ") + 2,
                err.error.message.length
              );
            } else if (err.status === 500) {
              messageOnly = "Internal Server Error";
            } else {
              messageOnly = err.error.message.substring(
                err.error.message.indexOf("Exception: "),
                err.error.message.length
              );
            }
          } else if (err.error && err.message && !err.url.includes("/image/")) {
            messageOnly = "The server could not be reached";
          }
          if (messageOnly && messageOnly !== "") {
            console.error("messageOnly", messageOnly);

            this.snackBar.open(messageOnly + "\n", "Ok", { duration: 5000 });
          }
        }
        return throwError(() => err.error.message || err.statusText);
      })
    );
  }

  private handleRequestParams(request: HttpRequest<any>) {
    const parameters: HttpParams = request.params;
    let httpParams = new HttpParams();
    parameters.keys().forEach((key) => {
      if (key === "filters") {
        httpParams = this.fillHttpParamsWithFilters(
          httpParams,
          parameters.get("filters")
        );
      } else {
        const parameter = parameters.getAll(key);

        for (let j = 0; j < parameter.length; j++) {
          httpParams = httpParams.append(key, parameter[j]);
        }
      }
    });
    httpParams = httpParams.delete("skipError");
    request = request.clone({
      params: httpParams,
      withCredentials: request.url.includes(config.apiUrl),
    });
    return request;
  }

  private fillHttpParamsWithFilters(httpParams: HttpParams, filters: string) {
    if (!filters || filters === "[object Object]") return httpParams;
    const filtersAsobject: object[] = JSON.parse(filters);
    if (!filtersAsobject) return httpParams;

    // Workaround
    if (
      filtersAsobject &&
      filtersAsobject.length === 1 &&
      filtersAsobject[0] &&
      filtersAsobject[0]["parameter"] &&
      filtersAsobject[0]["operator"]
    )
      httpParams = httpParams.append("filters", "");

    for (let j = 0; j < filtersAsobject.length; j++) {
      const filter = filtersAsobject[j];

      if (
        filter &&
        filter["parameter"] &&
        filter["operator"] &&
        filter["value"] !== null &&
        filter["value"] !== undefined &&
        filter["value"] !== ""
      ) {
        httpParams = httpParams.append(
          "filters",
          `${filter["parameter"].name}:${filter["operator"].operator}:${filter["value"]}`
        );
      }
    }

    if (
      filtersAsobject &&
      filtersAsobject.length > 0 &&
      !!filtersAsobject[0]["field"]
    ) {
      filtersAsobject.forEach((filter) => {
        httpParams = httpParams.append(
          "filters",
          `${filter["field"]}:${filter["operator"]}:${filter["value"]}`
        );
      });
    }
    return httpParams;
  }
}
