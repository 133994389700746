import { Injectable } from "@angular/core";
import { IFilter } from "../../model/filter";
import { LocalStorageService } from "./local-storage.service";

@Injectable()
export class FiltersService {
  constructor(private localStorageService: LocalStorageService) {}

  public createFilterWithObject(filters: IFilter[], filter: IFilter) {
    filters.push(filter);
    this.localStorageService.setItem("filters", filters);
  }

  public createFiltersWithObjects(
    filters: IFilter[],
    newfilters: IFilter[],
    keepInMemory: boolean = true
  ) {
    filters.push(...newfilters);
    if (keepInMemory) {
      this.localStorageService.setItem("filters", filters);
    }
    return filters;
  }

  public forceUpdateFilter(
    filters: IFilter[],
    key: number,
    newFilter: IFilter
  ) {
    filters[key] = newFilter;
    this.localStorageService.setItem("filters", filters);
  }

  public forceUpdateFilters(
    filters: IFilter[],
    indexes: number[],
    newFilters: IFilter[],
    keepInMemory: boolean = true
  ) {
    filters = filters.filter((filter, index) => indexes.indexOf(index) < 0);
    filters.push(...newFilters);
    if (keepInMemory) {
      this.localStorageService.setItem("filters", filters);
    }
    return filters;
  }

  public removeFilter(filters: IFilter[], key: string) {
    const index = filters.findIndex((filter) => filter.parameter.name === key);
    this.removeFilterByIndex(filters, index);
  }

  public removeFilterByIndex(filters: IFilter[], index: number) {
    if (index >= 0) {
      filters.splice(index, 1);
    }
    this.localStorageService.setItem("filters", filters);
  }

  public removeFiltersByIndexes(filters: IFilter[], indexes: number[]) {
    filters = filters.filter((f, i) => indexes.indexOf(i) < 0);
    this.localStorageService.setItem("filters", filters);
    return filters;
  }

  // Called each time container changes
  public initScenariosFilters() {
    return this.localStorageService.getItem("filters") || [];
  }
}
