import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PictureViewComponent } from "./picture-view/picture-view.component";
import { PictureUpdateDialogComponent } from "./picture-update-dialog/picture-update-dialog.component";
import { MaterialModule } from "layout/material.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SpriteViewComponent } from "./sprite-view/sprite-view.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ParameterViewComponent } from "./parameter-view/parameter-view.component";
import { MentionModule } from "angular-mentions";
import { MultiLevelParamViewComponent } from "./multilevelparam-view/multilevelparam-view.component";
import { MultiLevelParamUpdateComponent } from "./multilevelparam-update/multilevelparam-update.component";
import { ProfileDialogComponent } from "./profile-dialog/profile-dialog.component";
import { ReferenceViewComponent } from "./reference-view/reference-view.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { SortableTableHeaderComponent } from "./sortable-table-header/sortable-table-header.component";
import { AttachmentViewComponent } from "./attachment-view/attachment-view.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { ChartCardComponent } from "./chart-card/chart-card.component";
import { DataObjectViewComponent } from "./data-object-view/data-object-view.component";
import { FileUpdateDialogComponent } from "./file-update-dialog/file-update-dialog.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { FiltersListComponent } from "./filters-list/filters-list.component";
import { LightboxModule } from "ngx-lightbox";
import { ExporterDialogComponent } from "./exporter-dialog/exporter-dialog.component";
import { ExportDialogUsecaseComponent } from "./export-dialog-usecase/export-dialog-usecase.component";

// pipes
import {
  SimpleScenarioPipe,
  ClassificationCodePipe,
} from "../../pipes/simple-scenario.pipe";
import { PropertiesFilter } from "../../pipes/properties-filter.pipe";
import { GroupsFilterPipe } from "../../pipes/groups-filter.pipe";
import { NgbModule } from "layout/ngb.module";
import { ParamSetSelectorComponent } from "./param-set-selector/param-set-selector.component";
import { FiltersDialogComponent } from "./filters-dialog/filters-dialog.component";
import { FiltersDialogInputValueComponent } from "./filters-dialog/filters-dialog-input-value/filters-dialog-input-value.component";
import { FiltersDialogInputConstraintComponent } from "./filters-dialog/filters-dialog-input-constraint/filters-dialog-input-constraint.component";
import { FiltersDialogInputParameterComponent } from "./filters-dialog/filters-dialog-input-parameter/filters-dialog-input-parameter.component";
import { HistoryDialogComponent } from "./history-dialog/history-dialog.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import {
  CollectionFilterPipe,
  LabelFilterPipe,
  FieldFilterPipe,
} from "./label-filter.pipe";
import { OrderByPipe } from "./order-by.pipe";

import { MarkdownModule } from "ngx-markdown";
import { ContainerDialogComponent } from "./container-dialog/container-dialog.component";
import { ContainerComponent } from "./container/container.component";
import { ContainersListComponent } from "./containers-list/containers-list.component";
import { NewUserDialogComponent } from "./new-user-dialog/new-user-dialog.component";
import { NgChartsModule } from "ng2-charts";
import { SecurePipe } from "./secure.pipe";
import { ThumbGeneratorModule } from "app/thumb-generator/thumb-generator.module";
import { ParamDescriptionComponent } from "./param-description/param-description.component";
import { FooterComponent } from "./footer/footer.component";
import { LoadmoreComponent } from "./loadmore/loadmore.component";
import { CustomEnumViewComponent } from "./custom-enum-view/custom-enum-view.component";
import { AnnotationComponent } from "./annotation/annotation.component";
import { LateralpositionViewComponent } from "./lateralposition-view/lateralposition-view.component";
import { CollectionParentPipe } from "./collection-parent.pipe";
import { AddCollectionItemComponent } from "./add-collection-item/add-collection-item.component";
import { GroupComponent } from "./group/group.component";
import { TreeGraphComponent } from "./tree-graph/tree-graph.component";
import { JsonpathComponent } from "./jsonpath/jsonpath.component";
import { TextareaMentionComponent } from "./textarea-mention/textarea-mention.component";
import { TextMentionPipe } from "./text-mention.pipe";
import { WidgetComponent } from "./widget/widget.component";
import { CustomEnumItemComponent } from "./custom-enum-item/custom-enum-item.component";
import { ManageItemDialogComponent } from "./manage-item-dialog/manage-item-dialog.component";
import { CollectionsByContainersPipe } from "./collections-by-containers.pipe";
import { EspaceHTMLPipe } from "./espace-html.pipe";
import { SwaggerComponent } from "./swagger/swagger.component";
import { TimingComponent } from "./timing/timing.component";
import { TimingModalComponent } from "./timing-modal/timing-modal.component";
import { RemoveNullPipe } from "./remove-null.pipe";
import { FilterSearchPipe } from "./filter.pipe";
import { ChangeActorComponent } from "./change-actor/change-actor.component";
import { AddCollectionItemDropdownComponent } from "./add-collection-item-dropdown/add-collection-item-dropdown.component";
import { NotificationBadgeComponent } from "./notification-badge/notification-badge.component";
import { ContextualHelpIconComponent } from "./contextual-help-icon/contextual-help-icon.component";
import { DisplayReferencePipe } from "./display-reference.pipe";
import {
  LoadImageDirective,
  SimpleAccordionDirective,
} from "./load-image.directives";
import { ErrorAlertComponent } from "./error-alert/error-alert.component";
import { LayoutContainerComponent } from "./layout-container/layout-container.component";
import { LayoutContainerStatisticComponent } from "./layout-container-statistic/layout-container-statistic.component";
import { CollectionViewComponent } from "./collection-view/collection-view.component";
import { MetadataViewComponent } from "./metadata-view/metadata-view.component";
import { LibraryHeaderComponent } from "./library-header/library-header.component";
import { CollectionFormFilterComponent } from "./collection-form-filter/collection-form-filter.component";
import { LayoutItemCollectionComponent } from "./layout-item-collection/layout-item-collection.component";
import { BtnActionsComponent } from "./btn-actions/btn-actions.component";
import { AccessComponent } from "./access/access.component";
import { AddParametersDialogComponent } from "./add-parameters-dialog/add-parameters-dialog.component";
import { SearchCollectionDialogComponent } from "./search-collection-dialog/search-collection-dialog.component";
import {
  WrapFnPipe,
  PathToSchemePipe,
  WithLoadingPipe,
  SimpleWithLoadingPipe,
  JoinTriggerPipe,
  FormatCombinatorialPipe,
  ViewTypePipe,
  SchemeToAttributePipe,
  FilterParameterPipe,
  SubtitleLibraryPipe,
  FormatTolerancePipe,
} from "./wrap-fn.pipe";
import { LogicalParamViewComponent } from "./logical-param-view/logical-param-view.component";
import { SingleInputEnumComponent } from "./single-input-enum/single-input-enum.component";
import { AdsystemFormComponent } from "./adsystem-form/adsystem-form.component";
import { ParamViewTypeComponent } from "./param-view-type/param-view-type.component";
import { EditCombinatorialParamComponent } from "./edit-combinatorial-param/edit-combinatorial-param.component";
import { SingleInputBooleanComponent } from "./single-input-boolean/single-input-boolean.component";
import { SelectPathParamComponent } from "./select-path-param/select-path-param.component";
import { StatusByLevelChartComponent } from "./status-by-level-chart/status-by-level-chart.component";
import { NewAbstractValueComponent } from "./new-abstract-value/new-abstract-value.component";
import { PathReferenceBadgeComponent } from "./path-reference-badge/path-reference-badge.component";
import { EditToleranceComponent } from "./edit-tolerance/edit-tolerance.component";
import { OddCollectionViewComponent } from "./odd-collection-view/odd-collection-view.component";
import { OddAddCategoryComponent } from "./odd-add-category/odd-add-category.component";
import { OddConstraintsListComponent } from "./odd-constraints-list/odd-constraints-list.component";
import { CollectionItemPathViewComponent } from "./collection-item-path-view/collection-item-path-view.component";
import { StorageSharedService } from "app/store/services/shared.service";
import { FilterReducer, initialState } from "app/store/reducers/shared.reducer";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { LMarkdownEditorModule } from "ngx-markdown-editor";
import { BehaviorParamUpdateComponent } from "./behaviorparam-update/behaviorparam-update.component";
import { BehaviorParamComponent } from "./behaviorparam/behaviorparam.component";
import { DynamicValueComponent } from "./behaviorparam/dynamic-value/dynamic-value.component";
import { TruncatePipe } from "./truncate-text.pipe";
import { RegexDirective } from "./directives/regex-directive";
import { NoDataComponent } from "./no-data/no-data.component";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MentionModule,
    NgSelectModule,
    NgbNavModule,
    NgOptionHighlightModule,
    RouterModule,
    NgbModule,
    LightboxModule,
    LMarkdownEditorModule,
    StoreModule.forFeature("filters", FilterReducer, { initialState }),
    MarkdownModule.forChild(),
    NgChartsModule,
    ThumbGeneratorModule,
    FormsModule,
    ReactiveFormsModule,
    RegexDirective,
  ],
  declarations: [
    DynamicValueComponent,
    PictureViewComponent,
    SpriteViewComponent,
    TruncatePipe,
    ParameterViewComponent,
    BehaviorParamUpdateComponent,
    BehaviorParamComponent,
    MultiLevelParamViewComponent,
    MultiLevelParamUpdateComponent,
    ReferenceViewComponent,
    SidenavComponent,
    SortableTableHeaderComponent,
    AttachmentViewComponent,
    BreadcrumbComponent,
    ChartCardComponent,
    DataObjectViewComponent,
    HeaderComponent,
    FiltersListComponent,
    ParamSetSelectorComponent,
    DashboardComponent,
    ContainerComponent,
    ContainersListComponent,
    NoDataComponent,
    ParamViewTypeComponent,
    PictureUpdateDialogComponent,
    ConfirmDialogComponent,
    ConfirmationDialogComponent,
    ProfileDialogComponent,
    FileUpdateDialogComponent,
    FiltersDialogComponent,
    FiltersDialogInputValueComponent,
    FiltersDialogInputConstraintComponent,
    FiltersDialogInputParameterComponent,
    HistoryDialogComponent,
    ExportDialogUsecaseComponent,

    FieldFilterPipe,
    CollectionFilterPipe,
    SimpleScenarioPipe,
    ClassificationCodePipe,
    PropertiesFilter,
    GroupsFilterPipe,
    LabelFilterPipe,
    WrapFnPipe,
    PathToSchemePipe,
    SimpleWithLoadingPipe,
    JoinTriggerPipe,
    FilterParameterPipe,
    SubtitleLibraryPipe,
    FormatCombinatorialPipe,
    FormatTolerancePipe,
    WithLoadingPipe,
    ViewTypePipe,
    SchemeToAttributePipe,
    OrderByPipe,
    ContainerDialogComponent,
    NewUserDialogComponent,
    SecurePipe,
    DisplayReferencePipe,
    ParamDescriptionComponent,
    FooterComponent,
    LoadmoreComponent,
    CustomEnumViewComponent,
    AnnotationComponent,
    LateralpositionViewComponent,
    AddCollectionItemComponent,
    CollectionParentPipe,
    GroupComponent,
    TreeGraphComponent,
    JsonpathComponent,
    TextareaMentionComponent,
    TextMentionPipe,
    WidgetComponent,
    CustomEnumItemComponent,
    ManageItemDialogComponent,
    CollectionsByContainersPipe,
    EspaceHTMLPipe,
    SwaggerComponent,
    TimingComponent,
    TimingModalComponent,
    RemoveNullPipe,
    FilterSearchPipe,
    ExporterDialogComponent,
    ChangeActorComponent,
    AddCollectionItemDropdownComponent,
    NotificationBadgeComponent,
    LoadImageDirective,
    SimpleAccordionDirective,
    ContextualHelpIconComponent,
    ErrorAlertComponent,
    LayoutContainerComponent,
    LayoutContainerStatisticComponent,
    CollectionViewComponent,
    MetadataViewComponent,
    LibraryHeaderComponent,
    CollectionFormFilterComponent,
    LayoutItemCollectionComponent,
    BtnActionsComponent,
    AccessComponent,
    AddParametersDialogComponent,
    SearchCollectionDialogComponent,
    LogicalParamViewComponent,
    SingleInputEnumComponent,
    AdsystemFormComponent,
    ParamViewTypeComponent,
    EditCombinatorialParamComponent,
    SingleInputBooleanComponent,
    SelectPathParamComponent,
    StatusByLevelChartComponent,
    NewAbstractValueComponent,
    PathReferenceBadgeComponent,
    EditToleranceComponent,
    OddCollectionViewComponent,
    OddAddCategoryComponent,
    OddConstraintsListComponent,
    CollectionItemPathViewComponent,
  ],
  exports: [
    DynamicValueComponent,
    TruncatePipe,
    PictureViewComponent,
    SpriteViewComponent,
    ParameterViewComponent,
    BehaviorParamUpdateComponent,
    BehaviorParamComponent,
    MultiLevelParamViewComponent,
    MultiLevelParamUpdateComponent,
    ReferenceViewComponent,
    NoDataComponent,
    SidenavComponent,
    SortableTableHeaderComponent,
    AttachmentViewComponent,
    BreadcrumbComponent,
    ChartCardComponent,
    DataObjectViewComponent,
    HeaderComponent,
    FiltersListComponent,
    ParamSetSelectorComponent,
    DashboardComponent,
    ContainerComponent,
    ContainersListComponent,
    ParamViewTypeComponent,
    PictureUpdateDialogComponent,
    ConfirmDialogComponent,
    ConfirmationDialogComponent,
    ProfileDialogComponent,
    FileUpdateDialogComponent,
    FiltersDialogComponent,
    FiltersDialogInputValueComponent,
    FiltersDialogInputConstraintComponent,
    FiltersDialogInputParameterComponent,
    HistoryDialogComponent,
    ExportDialogUsecaseComponent,

    SimpleScenarioPipe,
    ClassificationCodePipe,
    PropertiesFilter,
    GroupsFilterPipe,
    LabelFilterPipe,
    WrapFnPipe,
    PathToSchemePipe,
    WithLoadingPipe,
    ViewTypePipe,
    SchemeToAttributePipe,
    SimpleWithLoadingPipe,
    JoinTriggerPipe,
    FilterParameterPipe,
    SubtitleLibraryPipe,
    FormatCombinatorialPipe,
    FormatTolerancePipe,
    OrderByPipe,
    SecurePipe,
    DisplayReferencePipe,
    ParamDescriptionComponent,
    FooterComponent,

    MarkdownModule,
    NgChartsModule,
    NgbModule,
    LoadmoreComponent,
    AnnotationComponent,
    AddCollectionItemComponent,
    CollectionParentPipe,
    TextareaMentionComponent,
    TextMentionPipe,
    WidgetComponent,
    ManageItemDialogComponent,
    CollectionsByContainersPipe,
    EspaceHTMLPipe,
    TimingComponent,
    RemoveNullPipe,
    FilterSearchPipe,
    CollectionFilterPipe,
    FieldFilterPipe,
    ChangeActorComponent,
    AddCollectionItemDropdownComponent,
    NotificationBadgeComponent,
    LoadImageDirective,
    SimpleAccordionDirective,
    ContextualHelpIconComponent,
    ErrorAlertComponent,
    LayoutContainerComponent,
    CollectionViewComponent,
    LibraryHeaderComponent,
    MetadataViewComponent,
    CollectionFormFilterComponent,
    LayoutItemCollectionComponent,
    BtnActionsComponent,
    AccessComponent,
    AddParametersDialogComponent,
    SearchCollectionDialogComponent,
    SingleInputEnumComponent,
    SingleInputBooleanComponent,
    LogicalParamViewComponent,
    AdsystemFormComponent,
    SelectPathParamComponent,
    PathReferenceBadgeComponent,
    RegexDirective,
  ],
  providers: [
    SecurePipe,
    OrderByPipe,
    TextMentionPipe,
    EspaceHTMLPipe,
    DisplayReferencePipe,
    StorageSharedService,
  ],
})
export class SharedModule {}
