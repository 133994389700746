import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ConstantsService } from '../../../../service/scenariomanager-api/constants.service';
import { IFilterParameter } from 'model/filterParameter';
import { IFilterOperator } from 'model/filterOperator';

@Component({
  selector: 'app-filters-dialog-input-constraint',
  templateUrl: './filters-dialog-input-constraint.component.html',
  styleUrls: ['./filters-dialog-input-constraint.component.scss']
})
export class FiltersDialogInputConstraintComponent implements OnInit {

  public stringConstraints: any;
  public simpleConstraints: any;
  public advancedConstraints: any;
  public customConstraints: any;
  public customObjectConstraints: any;

  @Input()
    set currentFilter(filter: IFilterParameter) { this.setCurrentFilter(filter) };
    get currentFilter() { return this._currentFilter }
  private _currentFilter: IFilterParameter;

  @Input()
    set currentConstraint(constraint: IFilterOperator) { this.setCurrentConstraint(constraint) };
    get currentConstraint() { return this._currentConstraint }
  @Output() currentConstraintChange = new EventEmitter(true); // true so we emit async
  private _currentConstraint: IFilterOperator;

  constructor(
    private constantsService: ConstantsService
  ) {
    this.stringConstraints = this.constantsService.getStringConstraints();
    this.simpleConstraints = this.constantsService.getSimpleConstraints();
    this.advancedConstraints = this.constantsService.getAdvancedConstraints();
    this.customConstraints = this.constantsService.getCustomConstraints();
    this.customObjectConstraints = this.constantsService.getCustomObjectConstraints();
  }

  ngOnInit() { }

  private setCurrentFilter(filter: IFilterParameter) {
    this._currentFilter = filter;
    this.setCurrentConstraint(null);
  }

  private setCurrentConstraint(constraint: IFilterOperator) {
    if ((constraint) && this.isNewConstraint(this._currentConstraint, constraint)) {
      this._currentConstraint = constraint;
      this.currentConstraintChange.emit(this._currentConstraint);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    let currentFilter = changes.currentFilter;
   if(currentFilter) {
      if (currentFilter.previousValue && this.isNewConstraint(currentFilter.currentValue, currentFilter.previousValue)) {
        this._currentConstraint = null;
        this.currentConstraintChange.emit(this._currentConstraint);
      }
    }
  }

  private isNewConstraint(obj1, obj2) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }

  isCustomEnum(){
    return this.currentFilter && this.currentFilter['x-custom-enum'];
  }

  isRef(){
    return this.currentFilter && ((this.currentFilter['items'] && this.currentFilter['items']['$ref']) || (this.currentFilter['$ref'] && this.currentFilter['$ref'] != '#/definitions/MultiLevelParam'));
  }
  
}
