<div *ngFor="let subProp of getSubProperties(scheme.className); let i = index"
  [ngClass]="{ 'level-0': level == 0, 'level-1': level == 1, 'level-2': level == 2, 'level-3': level == 3, 'd-flex mb-2': inlined }">
  <ng-container *ngIf="!subProp.hidden">
    <!-- Key -->
    <div class="key help" [ngClass]="{ 'inlined-label': inlined, 'vertical-label': !inlined }">
      {{ subProp.title ? subProp.title : subProp.name }}
      <div [ngClass]="{'opacity': showInfo(annotation[subProp.name])}" triggers="hover"
        ngbTooltip="{{ readonly ? 'View' : 'Edit' }} parameter annotations" container="body">
        <app-annotation *ngIf="(path && subProp['viewType']!='object') && !(subProp | displayReference)"
          [readonly]="readonly" [path]="path+subProp.name" [annotations]="annotations" [context]="context"
          [simulation]="context.simulation || simulation" (annotationChange)="annotationChange($event, subProp.name)">
        </app-annotation>
      </div>
      <app-contextual-help-icon [propertyScheme]="subProp" [objectScheme]="scheme"></app-contextual-help-icon>
    </div>
    <!-- Value view -->
    <ng-container *ngIf="model">
      <div class="row m-0 w-100">
        <div class="col-1 m-0 p-0 d-flex align-items-center" *ngIf="hasAnnotation(annotation[subProp.name])"
          style="max-width: 35px;">
          <span class="badge badge-warning text-light" *ngIf="annotation[subProp.name]?.isUnknown">?</span>
          <span class="badge badge-success" *ngIf="annotation[subProp.name]?.mandatory">M</span>
          <span class="badge badge-secondary" *ngIf="annotation[subProp.name]?.optional">Opt</span>
        </div>
        <div class="p-0" [ngClass]="hasAnnotation(annotation[subProp.name]) ? 'col-11' : 'col-12'"
          [style.maxWidth]="hasAnnotation(annotation[subProp.name]) ? 'calc(100% - 35px)' : none">

          <span *ngIf="readonly" [ngClass]="{ 'text-primary': highlightValue(subProp) == true }"
            [ngSwitch]="subProp['viewType']">
            <ng-container *ngSwitchCase="'boolean'">
              <span *ngIf="isBoolean(model[subProp.name]) && model[subProp.name]"
                class="badge badge-pill badge-info">Yes</span>
              <span *ngIf="isBoolean(model[subProp.name]) && !model[subProp.name]"
                class="badge badge-pill badge-light">No</span>
              <ng-container *ngIf="!isBoolean(model[subProp.name])">
              <no-data>
                {{ undefinedLabel(annotation[subProp.name]) }}
              </no-data>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'multilevel'">
              <app-multilevelparam-view [scheme]="subProp" [parentScheme]="scheme" [context]="context"
                [model]="model[subProp.name]" [annotation]="annotation[subProp.name]" [readonly]="readonly"
                [lastUpdatedAttr]="lastUpdatedAttr" [stepIndex]="stepIndex" [attributes]="attributes" [hiddenElements]="hiddenElements"
                [path]="path ? path+subProp.name : null" [level]="level" [reference]="model.reference"
                (submittedEvent)="setLastUpdatedAttr($event)" (propagatedEvent)="propagateValue($event)"
                 [statistics]="statistics">
              </app-multilevelparam-view>
            </ng-container>
            <ng-container *ngSwitchCase="'object'">
              <app-lateralposition-view *ngIf="subProp.className == 'LateralPosition'"
                [path]="path ? path+subProp.name+'.' : null" [annotations]="annotations" [stepIndex]="stepIndex"
                [context]="context" [scheme]="subProp" [model]="model[subProp.name]" [hiddenElements]="hiddenElements"
                [prevModel]="prevModel ? prevModel[subProp.name] : null" [readonly]="readonly"
                 (propagatedEvent)="propagateValue($event)" [level]="level+1" [statistics]="statistics">
              </app-lateralposition-view>
              <app-parameter-view *ngIf="subProp.className != 'LateralPosition'"
                [path]="path ? path+subProp.name+'.' : null" [stepId]="stepId" [actorId]="actorId"
                [annotations]="annotations" [stepIndex]="stepIndex" [context]="context" [scheme]="subProp"
                [model]="model[subProp.name]" [prevModel]="prevModel ? prevModel[subProp.name] : null"  [hiddenElements]="hiddenElements"
                [nextModel]="nextModel ? nextModel[subProp.name] : null" [readonly]="readonly" [stepIndex]="stepIndex"
                [lastUpdatedAttr]="lastUpdatedAttr" (lastUpdatedEvent)="setLastUpdatedAttr($event, subProp.name)"
                (propagatedEvent)="propagateValue($event)" [level]="level+1" [parentModel]="model"
                [statistics]="statistics">
              </app-parameter-view>
            </ng-container>
            <span *ngSwitchCase="'enum'" class="value multiline">
              <ng-container *ngIf="model[subProp.name]">{{ model[subProp.name].toUpperCase() }}</ng-container>
              <ng-container *ngIf="!model[subProp.name]">
                <no-data>{{ undefinedLabel(annotation[subProp.name]) }}</no-data>
              </ng-container>
            </span>
            <span *ngSwitchCase="'enums'" class="value">
              <ng-container *ngIf="model[subProp.name]">{{ model[subProp.name].join(', ') }}</ng-container>
              <ng-container *ngIf="!model[subProp.name] || (model[subProp.name] && !model[subProp.name].length)">
                <no-data>{{ undefinedLabel(annotation[subProp.name]) }}</no-data>
              </ng-container>
            </span>
            <span *ngSwitchCase="'ref'" class="value multiline">
              <app-reference-view [hiddenElements]="hiddenElements" (update)="propagateValue($event)" [context]="context" [scheme]="subProp" [model]="model[subProp.name]" [stepId]="stepId"
                [actorId]="actorId" [readonly]="readonly" [parentModel]="model" [statistics]="statistics">
              </app-reference-view>
            </span>
            <span *ngSwitchCase="'file'" class="value multiline">
              <app-attachment-view  [filable]="model" [readonly]="true"></app-attachment-view>
            </span>
            <span *ngSwitchCase="'number'" class="value multiline">
              <ng-container *ngIf="!model[subProp.name] && model[subProp.name] !== 0">
                <no-data>{{ undefinedLabel(annotation[subProp.name]) }}</no-data>
              </ng-container>
              <ng-container *ngIf="model[subProp.name] || model[subProp.name] === 0">{{ model[subProp.name] }}
              </ng-container>
            </span>
            <span *ngSwitchCase="'array'" class="value multiline">
              <div *ngFor="let item of model[subProp.name]" class="py-3 my-1 separator">
                <app-parameter-view (update)="propagateValue($event)" [context]="context" [scheme]="subProp" [model]="item" [readonly]="readonly"
                  [level]="level+1" [hiddenElements]="hiddenElements"
                  [attributes]="item.paramDescription ? { unitLabel: item.paramDescription.unit, abstractValues: item.paramDescription.abstractValues} : null">
                </app-parameter-view>
              </div>
              <div *ngIf="!model[subProp.name].length"><no-data>Not defined</no-data></div>
            </span>
            <span *ngSwitchCase="'custom-enum'" class="value">
              <app-custom-enum-view (update)="propagateValue($event)" [context]="context" [scheme]="subProp" [model]="model[subProp.name]"
                [dataSource]="subProp['custom-enum']" [readonly]="readonly" [path]="path">
              </app-custom-enum-view>
            </span>
            <span *ngSwitchDefault class="value multiline">
              <ng-container *ngIf="!model[subProp.name]">
                <no-data>{{ undefinedLabel(annotation[subProp.name])}}</no-data>
              </ng-container>
              <ng-container *ngIf="model[subProp.name]">{{ model[subProp.name] }}</ng-container>
            </span>
          </span>
          <!-- Value edition -->
          <ng-container *ngIf="!readonly" [ngSwitch]="subProp['viewType']">
            <textarea *ngSwitchCase="'text'" rows="3" class="form-control form-control-sm"
              [placeholder]="subProp.description" [(ngModel)]="model[subProp.name]">
            </textarea>
            <div *ngSwitchCase="'boolean'" class="boolean-switch">
              <ng-select appendTo="body" [(ngModel)]="model[subProp.name]" placeholder="Not defined">
                <ng-option [value]="true">
                  YES
                </ng-option>
                <ng-option [value]="false">
                  NO
                </ng-option>
              </ng-select>
            </div>
            <span *ngSwitchCase="'file'">
              <app-attachment-view [filable]="model" [readonly]="false" (updateFileEmitter)="emitNewValue($event)"></app-attachment-view>
            </span>
            <ng-container *ngSwitchCase="'multilevel'">
              <app-multilevelparam-view [scheme]="subProp" [parentScheme]="scheme" [context]="context"
                [model]="model[subProp.name]" [readonly]="readonly" [annotation]="annotation[subProp.name]"
                [lastUpdatedAttr]="lastUpdatedAttr" [stepIndex]="stepIndex" [reference]="model.reference"
                [attributes]="attributes" [path]="path ? path+subProp.name : null" [level]="level" [hiddenElements]="hiddenElements"
                (submittedEvent)="setLastUpdatedAttr($event)" (propagatedEvent)="propagateValue($event)"
                (update)="model[subProp.name] = $event" [formulaEnabled]="formulaEnabled">
              </app-multilevelparam-view>
            </ng-container>
            <ng-container *ngSwitchCase="'object'">
              <app-lateralposition-view *ngIf="subProp.className == 'LateralPosition'"
                [path]="path ? path+subProp.name+'.' : null" [annotations]="annotations" [stepIndex]="stepIndex"
                [context]="context" [scheme]="subProp" [model]="model[subProp.name]" [hiddenElements]="hiddenElements"
                [prevModel]="prevModel ? prevModel[subProp.name] : null" [readonly]="readonly" [stepIndex]="stepIndex"
                [lastUpdatedAttr]="lastUpdatedAttr" (lastUpdatedEvent)="setLastUpdatedAttr($event, subProp.name)"
                (propagatedEvent)="propagateValue($event)" [level]="level+1" [formulaEnabled]="formulaEnabled"
                [statistics]="statistics">
              </app-lateralposition-view>
              <app-parameter-view *ngIf="subProp.className != 'LateralPosition'"
                [path]="path ? path+subProp.name+'.' : null" [stepId]="stepId" [actorId]="actorId"
                [annotations]="annotations" [stepIndex]="stepIndex" [context]="context" [scheme]="subProp"
                [model]="model[subProp.name]" [readonly]="readonly" [stepIndex]="stepIndex" [hiddenElements]="hiddenElements"
                [lastUpdatedAttr]="lastUpdatedAttr" (lastUpdatedEvent)="setLastUpdatedAttr($event, subProp.name)"
                (propagatedEvent)="propagateValue($event)" [level]="level+1" [parentModel]="model"
                [formulaEnabled]="formulaEnabled" [statistics]="statistics">
              </app-parameter-view>
            </ng-container>
            <ng-container *ngSwitchCase="'enum'">
              <ng-select (changes)="propagateValue($event)" appendTo="body" [(ngModel)]="model[subProp.name]" [placeholder]="firstLine(subProp.description)">
                <ng-option *ngFor="let item of subProp.enum" value="{{item}}">
                  {{item.toUpperCase()}}
                </ng-option>
              </ng-select>
            </ng-container>
            <ng-container *ngSwitchCase="'enums'">
              <ng-select (changes)="propagateValue($event)" appendTo="body" [items]="subProp.items.enum || []" [addTag]="!subProp.items.enum" [multiple]="true"
                [(ngModel)]="model[subProp.name]" [placeholder]="firstLine(subProp.description)">
              </ng-select>
            </ng-container>
            <ng-container *ngSwitchCase="'custom-enum'">
              <app-custom-enum-view (update)="propagateValue($event)" [context]="context" [scheme]="subProp" [model]="model[subProp.name]" [path]="path"
                [readonly]="readonly" [dataSource]="subProp['custom-enum']" (update)="model[subProp.name]=$event">
              </app-custom-enum-view>
            </ng-container>
            <ng-container *ngSwitchCase="'ref'">
              <app-reference-view [hiddenElements]="hiddenElements" (update)="propagateValue($event)" [context]="context" [scheme]="subProp" [model]="toNumber(model[subProp.name])"
                [stepId]="stepId" [actorId]="actorId" [readonly]="readonly" (update)="model[subProp.name]=$event"
                [parentModel]="model" [statistics]="statistics">
              </app-reference-view>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <input class="form-control form-control-sm" type="number" step="any" [placeholder]="subProp.description"
                [min]="subProp.minimum" [(ngModel)]="model[subProp.name]" />
            </ng-container>
            <ng-container *ngSwitchCase="'array'">
              <div *ngIf="!readonly" class="toolbar btn-group" role="group">
                <button class="btn btn-success btn-sm" disabled="true">
                  <mat-icon>edit</mat-icon>
                </button>
                <button class="btn btn-success btn-sm" (click)="pushToArray(model[subProp.name],subProp )">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <div *ngFor="let item of model[subProp.name]; let i = index"
                class="position-relative py-3 my-1 separator">
                <app-parameter-view (update)="propagateValue($event)" [context]="context" [scheme]="subProp" [model]="item" [readonly]="readonly"
                  [formulaEnabled]="formulaEnabled" [hiddenElements]="hiddenElements"
                  [attributes]="item.paramDescription ? { unitLabel: item.paramDescription.unit, abstractValues: item.paramDescription.abstractValues} : null">
                </app-parameter-view>
                <app-confirm-dialog #deleteParamDialog (confirmed)="removeFromArray(i,model[subProp.name])"
                  confirm-label="Delete" icon="warning">
                  Please confirm the suppression of the parameter
                </app-confirm-dialog>
                <button *ngIf="!readonly" class="btn btn-danger btn-sm position-absolute" style="top: 0; right: 0;"
                  (click)="deleteParamDialog.show()">
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-jsonpath *ngIf="subProp.name == 'jsonpath'" [model]="model[subProp.name]"
                [placeholder]="subProp.description" [context]="context" (select)="model[subProp.name] = $event">
              </app-jsonpath>
              <ng-container  *ngIf="subProp.name === 'name' && subProp.type === 'string'">
                <input class="form-control form-control-sm" type="text" required
                [placeholder]="subProp.description" [(ngModel)]="model[subProp.name]" regexCheck [disabledRegex]="subProp.format !== 'variable'" regex="^[a-zA-Z0-9_]*$" (displayError)="regexError = $event" (ngModelChange)="emitNewValue($event)"/>
                <span *ngIf="regexError" class="text-danger">Only alphabetic characters, numbers and the "_" symbol are accepted</span>
              </ng-container>
              <input *ngIf="subProp.name != 'jsonpath' && subProp.name !== 'name'" class="form-control form-control-sm" type="text"
                [placeholder]="subProp.description" [(ngModel)]="model[subProp.name]" (ngModelChange)="emitNewValue($event)"/>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!-- No model here -->
    <ng-container *ngIf="!model">
      <i class="text-danger">MODEL ERROR</i>
    </ng-container>
  </ng-container>
</div>