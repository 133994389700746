import { MosarStatsModule } from "@MOSAR/mosar-statistics-dashboard";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  DoBootstrap,
  NgModule,
  SecurityContext,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { NgSelectModule } from "@ng-select/ng-select";
import { MentionModule } from "angular-mentions";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { MaterialModule } from "../layout/material.module";
import { NgbModule } from "../layout/ngb.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// main pages
import { DataModelViewerComponent } from "../pages/schema/data-model-viewer.component";
// services
import { AppStatusService } from "service/app-status/app-status.service";
import { ConfigureOptions } from "../service/ng4-configure/configure-options";
import { NgConfigureModule } from "../service/ng4-configure/ng4-configure.module";
import { ConstantsService } from "../service/scenariomanager-api/constants.service";
import { FiltersService } from "../service/scenariomanager-api/filters.service";
import { LocalStorageService } from "../service/scenariomanager-api/local-storage.service";
// providers
import { CustomHttpInterceptor } from "../interceptors/custom-http";

const keycloakService = new KeycloakService();

import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";

import { SharedModule } from "./shared/shared.module";

import { DataModelService } from "@MOSAR/mosar-dashboard-datamodel";
import { MarkdownModule } from "ngx-markdown";

import { StoreModule } from "@ngrx/store";
import { NgChartsModule } from "ng2-charts";
import { ConfigureService } from "service/ng4-configure/ng4-configure.service";
import { ErrorComponent } from "../pages/error/error.component";
import { CanDeactivateGuard } from "guards/authGuard";
import { TimeoutInterceptor } from "interceptors/timeout-interceptor";
@NgModule({
  declarations: [AppComponent, ErrorComponent, DataModelViewerComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgbModule,
    NgSelectModule,
    NgOptionHighlightModule,
    MaterialModule,
    NgConfigureModule.forRoot(),
    KeycloakAngularModule,
    MentionModule,
    SharedModule,
    StoreModule.forRoot({}, {}),
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
    }),
    MosarStatsModule,
    NgChartsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ConfigureOptions,
    NgbActiveModal,
    AppStatusService,
    ConstantsService,
    LocalStorageService,
    FiltersService,
    CanDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private dataService: DataModelService,
    private configService: ConfigureService
  ) {}
  ngDoBootstrap(appRef: ApplicationRef) {
    keycloakService
      .init({
        config: "assets/keycloak.json",
        initOptions: {
          checkLoginIframe: false,
          onLoad: "check-sso",
          silentCheckSsoRedirectUri:
            window.location.origin + "/assets/silent-check-sso.html",
        },
      })
      .then(() => {
        keycloakService.isLoggedIn().then((isLoggedIn) => {
          if (isLoggedIn) {
            this.dataService
              .init(this.configService.config)
              .then(() => {
                appRef.bootstrap(AppComponent);
              })
              .catch((e) => appRef.bootstrap(ErrorComponent));
          } else keycloakService.login();
        });
      })
      .catch((e) => appRef.bootstrap(ErrorComponent));
  }
}
