import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayReference'
})
export class DisplayReferencePipe implements PipeTransform {

  transform(subProp: any): boolean {
    return (subProp['x-reference'] === '#long' && subProp['name'] === 'reference') || (subProp['x-reference'] === '#speedReference' && subProp['name'] === 'reference') ;
  }

}
