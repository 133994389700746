import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigureService } from '../../service/ng4-configure/ng4-configure.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  constructor(
    public http: HttpClient,
    public configService: ConfigureService
  ) { }

  createCollection(collection, type, container) {
    collection['id'] = Date.now();
    return this.http.post(`${this.configService.config.apiUrl}/${type}`, collection, { params: { container } });
  }

  findCollections(type, container, skipError?: boolean) {
    let params = skipError ? { skipError: JSON.stringify(skipError) } : {};
    return this.http.get<any>(`${this.configService.config.apiUrl}/${type}`, { params: { container, ...params } });
  }

  findCollectionsTree(type, container) {
    return this.http.get<any>(`${this.configService.config.apiUrl}/${type}/tree`, { params: { container } });
  }

  getCollectionById(id, type) {
    return this.http.get<any>(`${this.configService.config.apiUrl}/${type}/${id}`);
  }

  deleteCollections(id, type, deleteChildren?, skipError?) {
    let params = skipError ? { skipError: JSON.stringify(skipError) } : {};
    return this.http.delete(`${this.configService.config.apiUrl}/${type}/${id}`, {params: {...params, deleteChildren}});
  }

  updateCollections(id, data, type) {
    return this.http.put(`${this.configService.config.apiUrl}/${type}/${id}`, data);
  }

  duplicateCollection(id, container, type, newParent, copyParents, copyChildren, move) {
    let params = { container, newParent, copyParents, copyChildren, move };
    return this.http.post(`${this.configService.config.apiUrl}/${type}/${id}/duplicate`, null, { params });
  }

  getHistory(type, id){
    return this.http.get<any>(`${this.configService.config.apiUrl}/${type}/${id}/history`);
  }

  //
  // Images API
  //

  storeStepImage(data) {
    return this.http.post(`${this.configService.config.apiUrl}/image`, data).toPromise();
  }

  getStepImageStoreUrl() {
    return `${this.configService.config.apiUrl}/image`;
  }

  storeFile(data) {
    return this.http.post(`${this.configService.config.apiUrl}/file`, data).toPromise();
  }

  getFileStoreUrl() {
    return `${this.configService.config.apiUrl}/file`;
  }

  getThumbnail(imageId: string){
    return this.http.get(`${this.configService.config.apiUrl}/thumbnail/${imageId}`, {params: { skipError: 'true' }});
  }

  setThumbnail(imageId: string, thumbnail: any){
    return this.http.post(`${this.configService.config.apiUrl}/thumbnail/${imageId}`, thumbnail);
  }

}
