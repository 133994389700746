import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CollectionService } from "../../collection/collection.service";

@Component({
  selector: "app-picture-update-dialog",
  templateUrl: "./picture-update-dialog.component.html",
  styleUrls: ["./picture-update-dialog.component.scss"],
})
export class PictureUpdateDialogComponent {
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("dialogTemplate", { static: true })
  private dialogModal: TemplateRef<any>;

  @Input("image-id") imageId: String;
  @Output() update = new EventEmitter<String>();

  form: FormGroup;
  loading: boolean = false;
  fileLabel = "Choose file";
  modal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private restService: CollectionService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    // Create form
    this.form = this.fb.group({
      picture: [null, Validators.required],
    });
  }

  show(content) {
    this.modal = this.modalService.open(this.dialogModal);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get("picture").setValue(file);
      this.fileLabel = file.name;
    }
  }

  removePicture() {
    this.modal.close();
    this.update.emit("");
    this.snackBar.open("Picture removed", "Ok", { duration: 1500 });
  }

  onSubmit() {
    this.loading = true;

    // Prepare data to send
    let data = new FormData();
    data.append("imageFile", this.form.get("picture").value);

    this.restService
      .storeStepImage(data)
      .then((response: any) => {
        this.loading = false;
        this.imageId = response.newid;
        this.update.emit(response.newid);
        this.modal.close();
        this.snackBar.open("Picture uploaded", "Ok", { duration: 1500 });
      })
      .catch((err) => {
        this.loading = false;
        this.modal.close();
        this.snackBar.open("Error uploading file !" + "\n", "Ok", {
          duration: 5000,
        });
      });
  }
}
