import jsonpath from "jsonpath";
import { HighLevelParameter } from "model/collection";

export function addUsecaseParameter(
  manageUsecaseParameters,
  relevantSubsets,
  response
) {
  const paramUsecase = manageUsecaseParameters.find(
    (param) => param.id === response.id
  );
  if (!paramUsecase) {
    manageUsecaseParameters = [...manageUsecaseParameters, response];
    // Add new column of combination if relevants subsets already have params corresponding to usecase parameter INPUT
    if (relevantSubsets.length && response.type === "INPUT") {
      relevantSubsets = [
        ...relevantSubsets.map((relevants) => {
          return {
            usecaseParameterList: [
              ...relevants.usecaseParameterList,
              {
                id: response.id,
                value: { value: {} },
              },
            ],
          };
        }),
      ];
    }
  } else {
    manageUsecaseParameters = [
      ...manageUsecaseParameters.map((params) => {
        if (params.id === response.id) {
          return { ...params, ...response };
        }
        return params;
      }),
    ];
  }
  return { manageUsecaseParameters, relevantSubsets };
}

export function removeUsecaseParameter(
  manageUsecaseParameters,
  relevantSubsets,
  item
) {
  manageUsecaseParameters = manageUsecaseParameters.filter(
    (params) => params.id !== item.id
  );
  relevantSubsets = [
    ...relevantSubsets.map((relevants) => ({
      usecaseParameterList: relevants.usecaseParameterList.filter(
        (subset) => subset.id !== item.id
      ),
    })),
  ];
  return { manageUsecaseParameters, relevantSubsets };
}

export function usecaseParametersToAnnotations(manageUsecaseParameters = []) {
  const arrayUsecaseParams = [...manageUsecaseParameters];
  return arrayUsecaseParams.reduce((acc, { id, path, name, type }) => {
    // Check if param exists with same path => param is trigger and input
    const idxAnnotation = acc.findIndex((param) => param.path === path);
    const annotation = {
      id,
      path,
      name,
      trigger: type == "TRIGGER",
      input: type == "INPUT",
      expected: type == "EXPECTED",
    };
    if (idxAnnotation > -1) {
      // Keep id from INPUT use case parameter when they have same path => Important to keep value of input use case parameter
      if (type == "TRIGGER") {
        id = acc[idxAnnotation].id;
        name = acc[idxAnnotation].name;
      }
      acc[idxAnnotation] = {
        ...annotation,
        name: name,
        trigger: true,
        input: true,
        expected: false,
        id,
      };
    } else {
      acc = [...acc, annotation];
    }
    return acc;
  }, []);
}
export function filterRelevantSubsets(relevantSubsets, annotation) {
  return relevantSubsets.map((relevants) => ({
    usecaseParameterList: relevants.usecaseParameterList.filter(
      (subset) => subset.id !== annotation?.id
    ),
  }));
}

export function uuidNumber() {
  return Math.floor(Math.random() * 999999) + 10000000;
}

export function getUsecaseParameter({
  annotation,
  scenario,
  type = "TRIGGER",
  id = uuidNumber(),
  create = false,
}) {
  let valueFromPath;
  try {
    valueFromPath = jsonpath.query(scenario, annotation.path)[0];
  } catch (error) {
    console.error("Error from path of parameter " + error);
  }
  let value = {};
  // This condition is useful to identify primitive param, The value_From_Path of primitive is undefined or typeof is primitive like 'string , 'boolean' etc... because is directly property
  if (valueFromPath && typeof valueFromPath === "object") {
    value = create ? valueFromPath?.value : { value: valueFromPath?.value };
  } else {
    value = create
      ? { primitive: [valueFromPath] }
      : { value: { primitive: [valueFromPath] } };
  }

  return {
    id,
    path: annotation.path,
    name: annotation.name,
    description: annotation["description"] ? annotation.description : "",
    type,
    value,
    nominalValue: { value: {} },
  };
}
export function annotationsToUsecaseParameters({
  annotations = [],
  manageUsecaseParameters = [],
  scenario,
  scenarioUpdated,
  relevantSubsets = [],
}) {
  // Filter annotations don't have a right type TRIGGER, INPUT, EXPECTED
  const newManageUsecaseParameters = annotations
    .filter((annotation) => {
      if (!getTypeAnnotation(annotation)) {
        // Remove column relevant subset of this use case parameter
        relevantSubsets = [
          ...filterRelevantSubsets(relevantSubsets, annotation),
        ];
      }
      return getTypeAnnotation(annotation);
    })
    .reduce((acc, annotation) => {
      // Check param type INPUT, TRIGGER, EXPECTED 
      const type = getTypeAnnotation(annotation);

      // Get usecase params exists before go to overview scenario
      const usecaseParam = manageUsecaseParameters.find(
        (param) => param.id === annotation?.id
      );

      if (usecaseParam) {
        const idxUsecaseParam = acc.findIndex(
          (param) => param.id === usecaseParam.id
        );
        const updateUsecaseParam = {
          ...usecaseParam,
          name: annotation.name,
          type,
        };

        // Update usecase parameter if already exists in acc, this occurs when annotation is trigger and input
        if (idxUsecaseParam > -1) {
          acc[idxUsecaseParam] = updateUsecaseParam;
        } else {
          acc = [...acc, updateUsecaseParam];
        }
        // If current annotation is input and trigger add usecase parameter trigger
        if (annotation.input && annotation.trigger) {
          acc = [...acc, getUsecaseParameter({ annotation, scenario })];
        }
        return acc;
      }

      const newId = uuidNumber();
      // Add new column of combination if relevants subsets already have params
      if (relevantSubsets.length && type === "INPUT") {
        relevantSubsets = [
          ...relevantSubsets.map((relevants) => {
            return {
              usecaseParameterList: [
                ...relevants.usecaseParameterList,
                {
                  id: newId,
                  value: { value: {} },
                },
              ],
            };
          }),
        ];
      }

      // Create new param usecase
      let newUseCaseParameter = getUsecaseParameter({
        annotation,
        scenario,
        type,
        id: newId,
      });


      if (scenarioUpdated?.parameters.length) {
        const parameter: HighLevelParameter =
          jsonpath.query(scenarioUpdated, annotation.path)[0] ?? null;
        // Set value from HLV
        if (parameter && isObjectEmpty(parameter?.value?.value)) {
          newUseCaseParameter = {
            ...newUseCaseParameter,
            value: parameter?.value,
          };
        }
      }

      // Add usecase parameter input and trigger
      if (annotation.input && annotation.trigger) {
        return [
          ...acc,
          newUseCaseParameter,
          getUsecaseParameter({ annotation, scenario }),
        ];
      }
      // Add single usecase parameter
      return [...acc, newUseCaseParameter];
    }, []);
  return {
    manageUsecaseParameters: newManageUsecaseParameters,
    relevantSubsets,
  };
}

export function getTypeAnnotation(annotation): string {
  if (annotation.input) return "INPUT";
  if (annotation.expected) return "EXPECTED";
  if (annotation.trigger) return "TRIGGER";
  return null;
}

export function annotationsToUsecaseParamCreate(scenario, annotations = []) {
  return annotations
    .filter(
      (annotation) =>
        annotation.input || annotation.expected || annotation.trigger
    )
    .reduce((acc, annotation) => {
      const type = getTypeAnnotation(annotation);
      const usecaseParam = getUsecaseParameter({
        annotation,
        scenario,
        type,
        create: true,
      });
      if (annotation.input && annotation.trigger) {
        return [
          ...acc,
          usecaseParam,
          getUsecaseParameter({ annotation, scenario }),
        ];
      }
      return [...acc, usecaseParam];
    }, []);
}

export function isObjectEmpty(obj) {
  if (!obj) return false;
  return Object.keys(obj).length;
}

export function getIdStep(trigger, scenario) {
  // Method used to get id from step for TRIGGER
  try {
    const pathParsed = jsonpath.parse(trigger.path);
    let query = "";
    pathParsed.forEach((part, index) => {
      if (part.operation === "member" && part.expression.value === "steps") {
        query = "steps[" + pathParsed[index + 1].expression.value + "]";
        return;
      }
    });
    const step = jsonpath.query(scenario, query)[0];
    if (step) return step.id;
  } catch (e) {
    return null;
  }
  return null;
}

export const schemeForLogiclParam = {
  //FIXME: Utilisations douteuses de l'objet, implems liées à revoir
  name: "value",
  className: "ParamValue",
  title: "Value",
  description:
    "Scenario parameter object that can hold a value on multiple abstraction level",
  type: "object",
  properties: {
    value: {
      $ref: "#/definitions/MultiLevelParam",
      unit: [],
      enum: [],
      name: "value",
      className: "MultiLevelParam",
      title: "MultiLevelParam",
      description:
        "Scenario parameter object that can hold a value on multiple abstraction level",
      type: "object",
    },
  },
  enum: [],
};

export const configFormFilterLibrary = {
  nameApi: "usecase",
  nameField: "Name of use case",
  placehoder: "Use case name",
  libraryField: {
    title: "Scenario",
    subtitle: "Related scenario",
    searchField: "scenario",
    nameApi: "scenario",
    show: true,
  },
  systemField: {
    show: true,
  },
};
