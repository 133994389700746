import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ParamScheme } from "@MOSAR/mosar-dashboard-datamodel";
import { searchTree } from "../utils-shared";

@Component({
  selector: "app-custom-enum-view",
  templateUrl: "./custom-enum-view.component.html",
  styleUrls: ["./custom-enum-view.component.scss"],
})
export class CustomEnumViewComponent implements OnInit {
  @Input("context") context: any; // Scenario, infrastructure or actor
  @Input("readonly") readonly: boolean;
  @Input("single") single: boolean;
  @Input("scheme") scheme: ParamScheme; // Data scheme
  @Input("model") model: any; // Data value
  @Input("dataSource") dataSource: any; // Source of the enum possible values
  @Input() path;
  @Output() update = new EventEmitter<string[]>();
  @Input("isFromFilter") isFromFilter = false;

  public itemsList: any = [];

  public isSingleLevel: boolean;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http.get("assets/" + this.dataSource).subscribe((data) => {
      this.itemsList = data;
      this.isSingleLevel = !this.itemsList.find((item) => item.children);
    });
  }

  getItem(itemsList, id) {
    return searchTree({ id: null, children: itemsList }, id);
  }

  getDescriptionClassificationCode(itemsList, id) {
    let description = "";
    const ids = id.split(".") || [];
    const firstParent = itemsList.find((item) => item.id === ids[0]);
    description = firstParent?.description || "Error: No description";
    if (firstParent?.children && ids.length > 1) {
      description += ` / ${
        firstParent?.children.find((item) => item.id === id).description ||
        "Info: No description"
      }`;
    }
    return description;
  }

  addElement(element) {
    if (this.model && !this.single) {
      this.model.push(element.id);
    } else this.model = [element.id];
    this.update.emit(this.model);
  }

  removeElement(i) {
    this.model.splice(i, 1);
    this.update.emit(this.model);
  }
}
