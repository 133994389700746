import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CollectionInfo, ContainerInfo } from "model/collection";

@Injectable()
export class ConstantsService {
  public mainCollections = [
    {
      label: "Use Case",
      link: "usecase",
      nameApi: "usecase",
      description:
        "A use-case is the application of a scenario for a specific system.",
      doc: "MOSAR-Scenario-DataModel-Reference.html#_usecases",
      showReport: false,
      showTree: false,
    },
    {
      label: "Test Protocol",
      link: "testprotocol",
      nameApi: "testprotocol",
      description: "A test protocol is a set of configured use-cases.",
      doc: "MOSAR-Scenario-DataModel-Reference.html#_usecases",
      showReport: false,
      showTree: false,
    },
    {
      label: "Driving Automation System",
      link: "drivingautomationsystem",
      nameApi: "drivingautomationsystem",
      description:
        "Vehicle or component of a vehicle that is to be considered as the system in a use-case. \
    This typically refers to an ADAS (advanced driver-assistance system), an ADS (autonomous driving \
    system) or a family of such features.",
      doc: "MOSAR-Scenario-DataModel-Reference.html#_drivingautomationsystem",
      showReport: false,
      showTree: true,
    },
    {
      label: "Scenarios",
      link: "scenarios",
      nameApi: "scenario",
      description: "",
      doc: "MOSAR-Scenario-DataModel-Reference.html#_scenario",
      showReport: true,
      showTree: true,
    },
  ];
  constructor(private router: Router) {}

  public getStringConstraints() {
    return [
      {
        label: "CONTAINS",
        operator: "lk",
      },
      {
        label: "DOESN'T CONTAINS",
        operator: "nlk",
      },
      {
        label: "START WITH",
        operator: "sw",
      },
      {
        label: "START NOT WITH",
        operator: "snw",
      },
      {
        label: "END WITH",
        operator: "ew",
      },
      {
        label: "END NOT WITH",
        operator: "enw",
      },
    ];
  }

  public getSimpleConstraints() {
    return [
      {
        label: "IS",
        operator: "eq",
      },
      {
        label: "IS NOT",
        operator: "neq",
      },
    ];
  }

  public getAdvancedConstraints() {
    return [
      {
        label: "EQUAL",
        operator: "eq",
      },
      {
        label: "LESS OR EQUAL",
        operator: "loe",
      },
      {
        label: "GREATER OR EQUAL",
        operator: "goe",
      },
      {
        label: "BETWEEN",
        operator: "bt",
      },
    ];
  }

  public getCustomConstraints() {
    return [
      {
        label: "MATCH",
        operator: "sw",
      },
      {
        label: "DOESN'T MATCH",
        operator: "snw",
      },
    ];
  }

  public getCustomObjectConstraints() {
    return [
      {
        label: "CONTAINS",
        operator: "lk",
      },
      {
        label: "DOESN'T CONTAINS",
        operator: "nlk",
      },
    ];
  }

  public getBasicPagination() {
    return {
      page: 1,
      rowPerPage: 100,
      nbRows: null,
    };
  }

  /**
   * Returns the library or collection meta-data depending on the current url.
   *
   * FIXME: #9602: Whole feature to be refactored: should be a collection and sections related service
   * (not utility function of constants.service ?!), and wording to be changed (collections...).
   */
  public getCurrentCollectionInfo(): CollectionInfo {
    let libraryInfo = this.getCollectionsAndLibrariesInfo().filter(
      (collection) => {
        return this.router.url.includes(collection.link.replace("/", ""));
      }
    );
    return libraryInfo ? libraryInfo[0] : null;
  }

  public getCollectionsInfo(): CollectionInfo[] {
    return [
      {
        label: "Infrastructure elements",
        link: "/collections/infrastructureelement",
        nameApi: "infrastructureelement",
        description:
          "Elements positioned on the infrastructure. \
        It is a generic data structure which makes it possible to specify a wide variety of elements: \
        it can be road infrastructure equipment, objects or possibly temporary features that can be positioned.",
        doc: "MOSAR-Scenario-DataModel-Reference.html#_infrastructure_elements",
        showReport: false,
        showTree: true,
      },
      {
        label: "Infrastructures",
        link: "/collections/infrastructure",
        nameApi: "infrastructure",
        description:
          "The Infrastructure describes the static environment on which the scenario takes place. \
        This is the road infrastructure - or terrain - as well as the fixed objects that are placed on it.",
        doc: "MOSAR-Scenario-DataModel-Reference.html#_infrastructures",
        showReport: false,
        showTree: true,
      },
      {
        label: "Actors",
        link: "/collections/actor",
        nameApi: "actor",
        description:
          "The actors represent all the dynamic elements (potentially in motion) of the scenario. \
        It can be the ego vehicle as well as other road users, cyclists, pedestrians or animals.",
        doc: "MOSAR-Scenario-DataModel-Reference.html#_actors",
        showReport: false,
        showTree: true,
      },
      {
        label: "Behaviors",
        link: "/collections/behavior",
        nameApi: "behavior",
        description:
          "In addition to the scenes that can be described in the storyboard, behaviors can be specified \
        to add more specific parameters to actors dynamic.",
        doc: "MOSAR-Scenario-DataModel-Reference.html#_behaviors",
        showReport: false,
        showTree: true,
      },
      {
        label: "Equipments",
        link: "/collections/equipment",
        nameApi: "equipment",
        description:
          "Equipment can be associated with actors and make it possible to specify some of their \
        characteristics which may be relevant in the situation described by the scenario.",
        doc: "MOSAR-Scenario-DataModel-Reference.html#_equipments",
        showReport: false,
        showTree: true,
      },
      {
        label: "Tags",
        link: "/collections/tag",
        nameApi: "tag",
        description:
          "A tag is a keyword that describes a characteristic of the scenario. It can be associated \
        to any scenario and then be used to simplify research or grouping of the scenarios of the database.",
        doc: "MOSAR-Scenario-DataModel-Reference.html#_tags",
        showReport: false,
        showTree: true,
      },
    ];
  }

  public getCollectionsAndLibrariesInfo(): CollectionInfo[] {
    const collectionForHomePage = this.getCollectionsInfo();
    return [...collectionForHomePage, ...this.mainCollections];
  }

  public getLibrariesInfo(): ContainerInfo[] {
    return [
      {
        title: "Scenario library",
        subtitle: "Scenarios",
        link: "/scenarios/",
        shortName: "scenario",
        nameApi: "scenario",
        titleStats: "Scenario library",
        showStats: false,
        showContainerStatistic: true,
        showSearch: true,
      },
      {
        title: "Use cases library",
        subtitle: "Use cases",
        link: "/usecase/",
        shortName: "user case",
        nameApi: "usecase",
        titleStats: "Use case library",
        showStats: false,
        showContainerStatistic: true,
        showSearch: false,
      },
      {
        title: "Test protocol library",
        subtitle: "Test protocol",
        link: "/testprotocol/",
        shortName: "test protocol",
        nameApi: "testprotocol",
        titleStats: "Test protocol library",
        showStats: false,
        showContainerStatistic: true,
        showSearch: false,
      },
      {
        title: "Driving Automation Systems library",
        subtitle: "Driving Automation Systems",
        link: "/drivingautomationsystem/",
        shortName: "DAS",
        nameApi: "drivingautomationsystem",
        titleStats: "Driving Automation Systems library",
        showStats: false,
        showContainerStatistic: true,
        showSearch: false,
      },
    ];
  }

  //FIXME: #9602: L'information des conteneurs associés devrait venir du modèle de données ?
  public getAssociatedContainers(key: string) {
    switch (key) {
      case "scenarios":
      case "scenario":
        return {
          actors: [],
          infrastructures: [],
          infrastructureelements: [],
          behaviors: [],
          equipments: [],
          tags: [],
        };

      case "usecase":
        return {
          scenarios: [],
          drivingautomationsystem: [],
          behaviors: [],
          tags: [],
        };

      case "testprotocol":
        return {
          drivingautomationsystem: [],
          tags: [],
          infrastructures: [],
          scenarios: [],
        };

      case "drivingautomationsystem":
        return {
          actors: [],
          equipments: [],
          behaviors: [],
          infrastructures: [],
          infrastructureelements: [],
          tags: [],
        };

      default:
        return null;
    }
  }

  //FIXME: #9602: Duplication de ce qu'il y a dans getAssociatedContainers() ?
  getTypesForLibrary(key) {
    const typeForLibrary: Map<string, any[]> = new Map();
    typeForLibrary
      .set(
        "usecase",
        ["scenario", "drivingautomationsystem", "tag", "behavior"].map((key) =>
          this.getCollectionsAndLibrariesInfo().find(
            (type) => type.nameApi === key
          )
        )
      )
      .set(
        "testprotocol",
        ["drivingautomationsystem", "tag", "infrastructure"].map((key) =>
          this.getCollectionsAndLibrariesInfo().find(
            (type) => type.nameApi === key
          )
        )
      )
      .set(
        "scenario",
        [
          "actor",
          "infrastructure",
          "infrastructureelement",
          "behavior",
          "equipment",
          "tag",
        ].map((key) =>
          this.getCollectionsAndLibrariesInfo().find(
            (type) => type.nameApi === key
          )
        )
      )
      .set(
        "drivingautomationsystem",
        [
          "actor",
          "equipment",
          "behavior",
          "infrastructure",
          "infrastructureelement",
          "tag",
        ].map((key) =>
          this.getCollectionsAndLibrariesInfo().find(
            (type) => type.nameApi === key
          )
        )
      );

    return typeForLibrary.get(key);
  }
}
