import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-collection-item-path-view",
  templateUrl: "./collection-item-path-view.component.html",
  styleUrls: ["./collection-item-path-view.component.scss"],
})
export class CollectionItemPathViewComponent implements OnInit {
  @Input() path: string;
  @Input() availableObjects;
  @Input() availableObjectsReady: boolean = true;

  collectionName = "";

  ngOnInit(): void {}

  pathPipe(path: string) {
    if (path) {
      let match = path.match(/^([0-9]*)(.*)/);
      if (match.length > 2) {
        let collection = this.availableObjects.find(
          (elem) => elem?.container.id == match[1]
        );
        this.collectionName = collection ? collection.container.name : "";
        return match[2]?.replace(/\//g, " / ");
      } else {
        this.collectionName = "";
        return path;
      }
    }
    return "";
  }
}
