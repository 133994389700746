import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  Optional,
  Host,
  OnInit,
} from "@angular/core";
import { ParamScheme } from "@MOSAR/mosar-dashboard-datamodel";
import { LateralpositionViewComponent } from "../lateralposition-view/lateralposition-view.component";

interface Reference {
  id: number;
  label: string;
  label1?: string;
  label2?: string;
  path?: any;
  key?: string;
}

@Component({
  selector: "app-reference-view",
  templateUrl: "./reference-view.component.html",
  styleUrls: [
    "./reference-view.component.scss",
    "../parameter-view/parameter-view.component.scss",
  ],
})
export class ReferenceViewComponent implements OnChanges, OnInit {
  @Input("key") key: string; // Parameter name (new reference version)
  @Input("titleOverride") titleOverride: string; // String to use as replacement of the parameter title

  @Input("context") context: any; // Scenario, infrastructure or actor
  @Input("path") path: string; // path of the param
  @Input("annotations") annotations: any;
  @Input() multiple = false;
  @Input("readonly") readonly: boolean;
  @Input("scheme") scheme: ParamScheme; // Data scheme
  @Input("model") model: any; // Data value
  @Input("stepId") stepId: any;
  @Input("stepIndex") stepIndex: any;
  @Input("actorId") actorId: string;
  @Input("parentModel") parentModel: any; // Data value of the parent object
  @Input("prevModel") prevModel: any = null; // Previous data value, used if param in array to highlight changes
  @Input("statistics") statistics: boolean;
  @Input() hiddenElements = [];
  @Output() update = new EventEmitter<string>();

  annotation;

  public references: Reference[] = [];
  public referenceError = false;
  public notDefined = false;
  private initialValue = null;
  private key_model: string;

  constructor(
    @Optional() @Host() private parent: LateralpositionViewComponent
  ) {
  }

  ngOnChanges() {
    this.updateValues();
    this.updateReferences();
    this.checkModel();
  }

  ngOnInit() {
    if (this.parent) this.parent.update.subscribe(() => this.updateValues());
  }

  /**
   * Use the attribute _key_ to fill all other component inputs.
   */
  updateValues() {
    if(this.model && this.model.length && this.model.length > 0) {
      this.model = this.model.map(el => Number(el));
    }
    if (this.key) {
      this.context = this.parent.context;
      this.path = this.parent.path;
      this.annotations = this.parent.annotations;
      this.readonly = this.parent.readonly;
      if (!this.scheme) this.scheme = this.parent.subProps[this.key];
      if (!this.model) this.model = this.parent.model[this.key];
      this.stepId = this.parent.stepId;
      this.actorId = this.parent.actorId;
      this.parentModel = this.parent.model;
      this.prevModel = this.parent.prevModel
        ? this.parent.prevModel[this.key]
        : null;
      this.stepIndex = this.parent.stepIndex;
      this.statistics = this.parent.statistics;
    }
  }

  annotationChange(annotation) {
    setTimeout(() => {
      this.annotation = annotation;
    });
  }

  showInfo(annotation) {
    return annotation
      ? annotation.mandatory ||
          annotation.optional ||
          annotation.input ||
          annotation.trigger ||
          annotation.expected
      : false;
  }

  updateReferences() {
    this.references = this.getReferences().filter(
      (ref) => !this.hiddenElements.includes(ref.label)
    );
    this.references.forEach((r) => (r.key = JSON.stringify(r.path)));
    //For ng-select default value
    this.key_model = JSON.stringify(this.model);
  }

  compareFn(val1, val2) {
    return val1 === val2;
  }

  checkModel() {
    // Check that a value is specified
    if ((!this.model && this.model !== 0 )|| this.model.length === 0) {
      this.referenceError = false;
      this.notDefined = true;
      return;
    } else {
      this.initialValue = this.model;
    }
    // Check that the value references an existing item
    if (this.getLabel() == null) {
      this.referenceError = true;
      this.notDefined = false;
      return;
    }
    // No problem detected
    this.referenceError = false;
    this.notDefined = false;
  }

  getLabel() {
    if (this.model || this.model === 0) {
      if (this.scheme.className == "Path") {
        const reference = this.references.find((r) => r.key == this.key_model);
        if (reference)
          //Can be null if the segments have changed and this path does not exist anymore
          return reference.label;
      } else {
        if( !Array.isArray(this.model)) {
          for (const ref of this.references) {
            if (this.model === ref.id) {
              return ref.label;
            }
          }
        } else {
          let label = '';
          for (const ref of this.references) {
            if (this.model.includes(ref.id)) {
              label += ' ' + ref.label + ' ,';
            }
          }
          return label.slice(0, label.length-1);
        }

      }
    }
    return null;
  }

  updatePathValue(data) {
    this.parent.model[this.key] = data ? data.path : null;
    this.model = this.parent.model[this.key];
  }

  updateValue(data) {
    if (this.initialValue != data) {
      this.clearDependantParameters();
    }
    if (this.key) {
      this.parent.model[this.key] = data;
      this.model = this.parent.model[this.key];
    }
    this.update.emit(data);
  }

  private clearDependantParameters() {
    // Clear strip value if the segment value change
    if (this.scheme["x-reference"] == "#segment") {
      if (this.parentModel && this.parentModel["strip"]) {
        this.parentModel["strip"] = null;
      }
    }
  }

  highlightValue(scheme: ParamScheme) {
    if (!this.parent.prevModel)
      //No highlight if first
      return false;
    if (
      this.parent.isIntersection(this.parent.model) !=
      this.parent.isIntersection(this.parent.prevModel)
    )
      return true;
    return JSON.stringify(this.model) != JSON.stringify(this.prevModel);
  }

  toNumber(value) {
    if (isNaN(value)) return value;
    else return Number(value);
  }

  undefinedLabel(annotation) {
    if (annotation) {
      if (annotation.isUnknown) return "Unknown";
      if (annotation.mandatory) return "To be defined";
    }
    return "Not defined";
  }

  hasAnnotation(annotation) {
    return annotation
      ? annotation.mandatory || annotation.optional || annotation.isUnknown
      : false;
  }

  /**
   * Returns the possibile values for the specified reference type and context.
   * @returns Array of possible values
   */
  private getReferences() {
    if (this.context) {
      if (this.scheme.className == "Path") {
        return this.getPathReferences();
      } else {
        switch (this.scheme["x-reference"]) {
          // Reference to a step
          case "#Step":
            return this.getStepReferences();
          // Reference to an actor
          case "#Actor":
            return this.getActorReferences();
          // Reference to a segment
          case "#RoadSegment":
            return this.getSegmentReferences();
          // Reference to a strip
          case "#Strip":
            return this.getStripReferences();
          // speed reference
          case "#speedReference":
            return this.getSpeedReferences();
          case "#Lateral":
            return this.getLateralReferences();
          // Longitudinal position reference
          case "#long": //FIXME: Rename reference to '#Longitudinal' for uniformity
            return (this.references = this.getLongitudinalPositionReferences());
          default:
            console.error(
              "Unknown reference type " + this.scheme["x-reference"]
            );
        }
      }
    }

    return [];
  }

  /**
   * Get the possible paths of an intersection
   * @returns Array of references
   */
  private getPathReferences(): Reference[] {
    const possibleReferences: Reference[] = [];

    // If the context is a scenario
    let segments = null;
    if (this.context["infrastructure"]) {
      segments = this.context["infrastructure"]["segments"];
    }
    // If the context is an infrastructure
    if (this.context["segments"]) {
      segments = this.context["segments"];
    }

    //Set all combinations
    if (segments) {
      for (const segment1 of segments) {
        for (const strip1 of segment1.strips) {
          const label1 = segment1.name + " / " + strip1.name;
          for (const segment2 of segments) {
            for (const strip2 of segment2.strips) {
              if (strip1 == strip2) continue;
              const label2 = segment2.name + " / " + strip2.name;
              const path = {
                from: { segment: segment1.id, strip: strip1.id },
                to: { segment: segment2.id, strip: strip2.id },
              };
              possibleReferences.push({
                id: possibleReferences.length,
                label: label1 + " -> " + label2,
                path: path,
                label1: label1,
                label2: label2,
              });
            }
          }
        }
      }
    }

    return possibleReferences;
  }

  /**
   * Returns the possible references to the steps of the scenario.
   * @returns Array of references
   */
  private getStepReferences(): Reference[] {
    const possibleReferences: Reference[] = [];

    // If the context is a scenario
    let steps = null;
    if (this.context["steps"]) {
      steps = this.context["steps"];
    }

    if (steps) {
      for (const step of steps) {
        if (step.id != this.stepId)
          possibleReferences.push({ id: step.id, label: step.name });
      }
    }
    return possibleReferences;
  }

  /**
   * Returns the possible references to the actors of the scenario.
   * It does not return the current actor.
   * @returns Array of references
   */
  private getActorReferences(): Reference[] {
    const possibleReferences: Reference[] = [];

    // If the context is a scenario
    let actors = null;
    if (this.context["actors"]) {
      actors = this.context["actors"];
    }

    if (actors) {
      for (const actor of actors) {
        if (this.actorId != actor.id)
          possibleReferences.push({ id: actor.id, label: actor.name });
      }
    }
    return possibleReferences;
  }

  /**
   * Returns the possible references for a speed, that is any actors of the
   * scenario (current actor exepted) or a value indicating an absolute speed (0).
   * @returns Array of references
   */
  private getSpeedReferences(): Reference[] {
    const possibleReferences: Reference[] = [];

    possibleReferences.push({ id: 0, label: "ABSOLUTE_SPEED" });

    // If the context is a scenario
    let actors = null;
    if (this.context["actors"]) {
      actors = this.context["actors"];
    }

    if (actors) {
      for (const actor of actors) {
        if (this.actorId != actor.id)
          possibleReferences.push({
            id: actor.id,
            label: "Actor / " + actor.name,
          });
      }
    }
    return possibleReferences;
  }

  /**
   * Returns the possible references to a road segment, that is all the segments
   * of the infrastructure.
   * @returns Array of references
   */
  private getSegmentReferences(): Reference[] {
    const possibleReferences: Reference[] = [];

    // If the context is a scenario
    let segments = null;
    if (this.context["infrastructure"]) {
      segments = this.context["infrastructure"]["segments"];
    }
    // If the context is an infrastructure
    if (this.context["segments"]) {
      segments = this.context["segments"];
    }
    if (segments) {
      for (const segment of segments) {
        possibleReferences.push({ id: segment.id, label: segment.name });
      }
    }
    return possibleReferences;
  }

  /**
   * Returns the possible references to a strip, that is all the strips of the current segment.
   * @returns Array of references
   */
  private getStripReferences(): Reference[] {
    const possibleReferences: Reference[] = [];

    if (this.parentModel && this.parentModel["segment"]) {
      const segId = this.parentModel["segment"];

      // If the context is a scenario
      let segments = null;
      if (this.context["infrastructure"]) {
        segments = this.context["infrastructure"]["segments"];
      }
      // If the context is an infrastructure
      if (this.context["segments"]) {
        segments = this.context["segments"];
      }

      const segment = this.getSegmentFromId(segments, segId);
      const strips = segment ? segment["strips"] : null;

      if (strips) {
        for (const strip of strips) {
          possibleReferences.push({ id: strip.id, label: strip.name });
        }
      }
    }
    return possibleReferences;
  }

  private getSegmentFromId(segments, id) {
    if (!segments) return null;
    for (const segment of segments) {
      if (segment.id == id) {
        return segment;
      }
    }
    return null;
  }

  /**
   * Returns all the possible references for a longitudinal position, that is:
   * - Segment start or end ;
   * - any infrastructure element ;
   * - any actor.
   * @returns Array of references
   */
  private getLongitudinalPositionReferences(): Reference[] {
    const possibleReferences: Reference[] = [];
    // Infrastructure static references
    possibleReferences.push({ id: -1, label: "Road / SEGMENT_START" });
    possibleReferences.push({ id: -2, label: "Road / SEGMENT_END" });
    // Infrastructure dynamic references (context is a scenario or infrastructure)
    if (this.context["infrastructure"] || this.context["segments"]) {
      const infrastructure = this.context["infrastructure"] || this.context;
      if (infrastructure["segments"]) {
        for (const segment of infrastructure["segments"]) {
          if (segment.elements)
            for (const elementInst of segment["elements"]) {
              const element = elementInst["element"];
              const id = element["id"];
              const name =
                "Infra / " + segment["name"] + " / " + element["name"];
              possibleReferences.push({ id: id, label: name });
            }
        }
      }
    }
    // Actors references (context is a scenario)
    if (this.context["actors"]) {
      const actors = this.context["actors"];
      for (const item of actors) {
        if (this.actorId != item.id)
          possibleReferences.push({
            id: item["id"],
            label: "Actor / " + item["name"],
          });
      }
    }
    return possibleReferences;
  }

  /**
   * Returns all the possible references for a lateral position, that is:
   * - A road segment if the position is "ASOLUTE" (i.e. relative to the road)
   * - An actor if the position is "RELATIVE".
   * @returns Array of references
   */
  private getLateralReferences(): Reference[] {
    const references = [];
    // Insert segments references
    for (const reference of this.getSegmentReferences()) {
      reference.label = "Road / " + reference.label;
      references.push(reference);
    }
    // Insert actors references
    for (const reference of this.getActorReferences()) {
      reference.label = "Actor / " + reference.label;
      references.push(reference);
    }
    return references;
  }
}
