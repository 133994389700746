import { ThumbnailItemView } from "../thumbnail-item-view";
import { ThumbnailText } from "./thumbnail-text-model";

export class ThumbnailTextView extends ThumbnailItemView {
  protected declare item: ThumbnailText;
  protected static readonly TEXT_CONTROL = "text";
  protected static readonly COLOR_CONTROL = "color";

  constructor(item: ThumbnailText, parent: Element, id: number) {
    super(item, parent, id);
    this.setControlEnabled(ThumbnailItemView.DRAG_CONTROL, true);
    this.setControlEnabled(ThumbnailItemView.SELECTION_CONTROL, true);
    this.setControlEnabled(ThumbnailItemView.RESIZE_CONTROL, false);
    this.setControlEnabled(ThumbnailItemView.ROTATE_CONTROL, false);
    this.setControlEnabled(ThumbnailTextView.TEXT_CONTROL, true);
    this.setControlEnabled(ThumbnailTextView.COLOR_CONTROL, true);
  }

  protected createControl(control: string) {
    super.createControl(control);
  }

  public createItemView(): Element {
    const element = super.createItemView("text");

    let updateText = () => {
      element.setAttribute("fill", this.item.getColor());

      // Delete all previous tspan
      element.innerHTML = "";

      // Cutting the text in 30 cara substring
      let txt = this.item.getText().match(/.{1,30}/g);

      // 1 line = 1 tspan
      for (let i = 0; i < txt.length; i++) {
        let ts = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "tspan"
        );
        ts.setAttribute("x", "0");
        if (i > 0) {
          ts.setAttribute("dy", "1.2em");
        }
        ts.innerHTML = txt[i];
        element.appendChild(ts);
      }

      // Used to correct an offset
      element.setAttributeNS(null, "y", "20");
    };
    updateText();
    this.changeSize();

    this.item.onItemChange.subscribe(() => {
      updateText();
    });

    return this.svgElement;
  }

  public changeText() {
    let txt = prompt("Write annotation", this.item.getText());
    if (txt == null || txt == "") {
    } else {
      this.item.setText(txt);
      this.changeSize();
    }
  }

  private changeSize() {
    let txtel = document.getElementById("thumb-element-" + this.id);
    let bbox = txtel.getBoundingClientRect();
    this.item.setEndPosition(
      this.item.getOriginX() - bbox.width,
      this.item.getOriginY() + bbox.height
    );
  }
}
