import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

  @Input() profile: any = {};

  public rolePerGroup: any = [];

  constructor(
    public modalRef: NgbActiveModal,
    public keycloakService: KeycloakService
  ) { }

  ngOnInit() {
  }

  getGroupName(group: string) {
    return group.replace('/kathra-projects/', '')
  }
}
