import Draggable from "gsap/Draggable";
import { TweenLite } from "gsap/TweenMax";
import { ThumbnailItem } from "./thumbnail-item-model";

export abstract class ThumbnailItemView {
  protected item: ThumbnailItem;
  protected parent: Element; // Parent SVG element
  protected svgElement: Element; // Item SVG element
  protected id: number;
  private controls: string[];

  protected static readonly RESIZE_CONTROL = "resize";
  protected static readonly ROTATE_CONTROL = "rotate";
  protected static readonly DRAG_CONTROL = "drag";
  protected static readonly SELECTION_CONTROL = "select";

  constructor(item: ThumbnailItem, parent: Element, id: number) {
    this.item = item;
    this.parent = parent;
    this.id = id;
    this.controls = [];
    this.svgElement = this.createItemView("ABSTRACT");
  }

  public getSVGElement() {
    return this.svgElement;
  }

  public getItem() {
    return this.item;
  }
  public getControls() {
    return this.controls;
  }

  public destroySVGElement() {
    this.setControlsVisible(false);
    document.querySelector("#thumb-element-" + this.id).remove();
  }

  public setControlsVisible(visible: boolean) {
    for (let control of this.controls) {
      if (visible) this.createControl(control);
      else this.removeControl(control);
    }
  }

  protected setControlEnabled(control: string, enabled: boolean) {
    if (enabled) {
      if (this.controls.indexOf(control) == -1) this.controls.push(control);
    } else {
      let index = this.controls.indexOf(control);
      if (index != -1) {
        this.controls = this.controls.splice(index, 1);
      }
    }
  }

  protected createControl(control: string) {
    let elementName = "thumb-element-" + control + "-" + this.id;
    if (control == ThumbnailItemView.RESIZE_CONTROL) {
      this.createResizeControl(elementName);
    } else if (control == ThumbnailItemView.ROTATE_CONTROL) {
      this.createRotateControl(elementName);
    } else if (control == ThumbnailItemView.DRAG_CONTROL) {
      this.createDragControl(elementName);
    } else if (control == ThumbnailItemView.SELECTION_CONTROL) {
      this.createSelectionControl(elementName);
    }
  }

  protected removeControl(control: string) {
    let element = document.querySelector(
      "#thumb-element-" + control + "-" + this.id
    );
    if (element) {
      element.remove();
    }
  }

  /**
   * Create the SVG element for this item display.
   *
   *
   * TweenLite (x,y) is the translation from (0,0)
   * It's used as the beginning coordinate for each element (no need to specify one on creation)
   */
  protected createItemView(svgELementType: string): Element {
    this.svgElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      svgELementType
    );
    this.svgElement.setAttributeNS(null, "id", "thumb-element-" + this.id);
    this.parent.appendChild(this.svgElement);

    // Positioning
    const refreshPosition = () => {
      TweenLite.set("#thumb-element-" + this.id, {
        rotation: 0,
      });
      TweenLite.set("#thumb-element-" + this.id, {
        x: this.item.getOriginX(),
        y: this.item.getOriginY(),
        attr: {
          width: this.item.getWidth(),
          height: this.item.getHeight(),
        },
        transformOrigin:
          this.item.getWidth() / 2 + " " + this.item.getHeight() / 2,
        rotation: this.item.getRotation(),
      });
    };
    refreshPosition();

    // Events registering
    this.item.onItemChange.subscribe(() => {
      refreshPosition();
    });
    return this.svgElement;
  }

  // Apply keyboard events to item
  private hookKeyboard() {
    document.onkeydown = (key) => {
      if (key.keyCode == 38) {
        // up arrow
        this.item.moveUp();
      } else if (key.keyCode == 40) {
        // down arrow
        this.item.moveDown();
      } else if (key.keyCode == 37) {
        // left arrow
        this.item.moveLeft();
      } else if (key.keyCode == 39) {
        // right arrow
        this.item.moveRight();
      }
    };
  }

  /**
   * Add drag ability to the specified element.
   * NB: ElementName is ignored, drag control directly applied on item view
   */
  private createDragControl(elementName: string) {
    const element = Draggable.create("#thumb-element-" + this.id, {
      type: "x,y",
      //bounds: this.parent,       // uncomment to forbid the user of mooving out of the viewport of the svg while dragging
      onDrag: (event) => {
        this.item.setOriginPosition(element[0].x, element[0].y);
      },
      onDragStart: (event) => {
        this.item.startDraging();
      },
      onClick: () => {
        this.hookKeyboard();
      },
    });
  }

  /**
   * Add a handle for rotation control, at the top left of the specified element.
   */
  private createRotateControl(elementName: string) {
    // View creation
    const elrot = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );
    elrot.setAttributeNS(null, "id", elementName);
    elrot.setAttributeNS(null, "height", "10");
    elrot.setAttributeNS(null, "width", "10");
    elrot.setAttributeNS(null, "fill", "yellow");
    this.parent.appendChild(elrot);

    // Positioning
    const updatePosition = () => {
      TweenLite.set("#" + elementName, {
        rotation: 0,
      });
      TweenLite.set("#" + elementName, {
        x: this.item.getOriginX() - 5,
        y: this.item.getOriginY() - 5,
        transformOrigin:
          (this.item.getWidth() + 10) / 2 +
          " " +
          (this.item.getHeight() + 10) / 2,
        rotation: this.item.getRotation(),
      });
    };
    updatePosition();

    // Control creation
    const elementRotate = Draggable.create("#" + elementName, {
      type: "rotation",
      onDrag: (e) => {
        this.item.setRotation(elementRotate[0].rotation);
      },
      onDragStart: (event) => {
        this.item.startDraging();
      },
    });

    // Update position on item changes
    this.item.onItemChange.subscribe(() => {
      updatePosition();
    });
  }

  /**
   * Add a handle for resize control at the right bottom of the specified element.
   */
  private createResizeControl(elementName: string) {
    // View creation
    const elres = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );
    elres.setAttributeNS(null, "id", elementName);
    elres.setAttributeNS(null, "height", "10");
    elres.setAttributeNS(null, "width", "10");
    elres.setAttributeNS(null, "fill", "green");
    this.parent.appendChild(elres);

    // Control creation
    const elementResize = Draggable.create("#" + elementName, {
      type: "x,y",
      cursor: "nwse-resize",
      bounds: this.parent,

      // Resize by keeping dimension,
      onDrag: (e) => {
        // Vector from image center to handle in rotated repair
        let vx2 = elementResize[0].x - this.item.getCenterX();
        let vy2 = elementResize[0].y - this.item.getCenterY();
        // Vector from image center to handle in non rotated repair
        let alpha = this.item.getRotation() * (Math.PI / 180);
        let vx = vx2 * Math.cos(-alpha) - vy2 * Math.sin(-alpha);

        // Scaling height with  newWidth / oldWidth
        let w = this.item.getWidth();
        let scale = (vx * 2) / w;
        let h = this.item.getHeight() * scale;

        // Security for negative width and height
        if (vx * 2 > 10 && h > 10) {
          this.item.setDimension(vx * 2, h);
        }
      },
      onDragStart: (event) => {
        this.item.startDraging();
      },
    });

    // Positioning
    const updatePosition = () => {
      let alpha = this.item.getRotation() * (Math.PI / 180);
      // Vector from image center to handle in non rotated repair
      let vx = this.item.getWidth() / 2 - 5;
      let vy = this.item.getHeight() / 2 - 5;
      // Vector from image center to handle in rotated repair
      let vx2 = vx * Math.cos(alpha) - vy * Math.sin(alpha);
      let vy2 = vx * Math.sin(alpha) + vy * Math.cos(alpha);
      // Absolute position in rotated repair
      let x = this.item.getCenterX() + vx2;
      let y = this.item.getCenterY() + vy2;

      TweenLite.set("#thumb-element-resize-" + this.id, {
        x: x,
        y: y,
        transformOrigin: 0 + " " + 0,
        rotation: this.item.getRotation(),
      });
    };
    updatePosition();

    // Update position on item changes
    this.item.onItemChange.subscribe(() => {
      updatePosition();
    });
  }

  private createSelectionControl(elementName: string) {
    // View creation
    const elselect = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );
    elselect.setAttributeNS(null, "id", elementName);
    elselect.setAttributeNS(null, "fill", "none");
    elselect.setAttributeNS(null, "stroke", "#ff7c00");
    elselect.setAttributeNS(null, "style", "pointer-events:none;");
    this.parent.appendChild(elselect);

    // Positioning
    const updatePosition = () => {
      TweenLite.set("#" + elementName, {
        rotation: 0,
      });
      TweenLite.set("#" + elementName, {
        x: this.item.getOriginX(),
        y: this.item.getOriginY(),
        height: this.item.getHeight(),
        width: this.item.getWidth(),
        transformOrigin:
          this.item.getWidth() / 2 + " " + this.item.getHeight() / 2,
        rotation: this.item.getRotation(),
      });
    };
    updatePosition();

    // Update position on item changes
    this.item.onItemChange.subscribe(() => {
      updatePosition();
    });
  }
}
