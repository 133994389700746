import { EventEmitter } from "@angular/core";
import {
  ThumbnailItem,
  ThumbnailItemTFunction,
} from "./thumbnail-items/thumbnail-item-model";

export class ThumbnailDimension {
  width: number;
  height: number;
}

export class ThumbnailPoint {
  x: number;
  y: number;
}

/**
 * Thumbnail model.
 * List of all the thumbnail items that compose the thumbnail.
 */
export class Thumbnail {
  public onThumbnailItemAdded = new EventEmitter();
  public onThumbnailItemRemoved = new EventEmitter();
  public onChange = new EventEmitter();
  public size?: number;
  public ratio?: string;

  private items: ThumbnailItem[] = [];

  constructor(data?) {
    if (data) {
      this.size = data?.size;
      this.ratio = data?.ratio;
      this.items = data.items;
    }
  }

  public copy() {
    let thumbTemp = new Thumbnail();
    for (let item of this.items) {
      thumbTemp.addItem(item.copy());
    }
    return thumbTemp;
  }

  public getItems(): ThumbnailItem[] {
    return this.items;
  }

  public isEmpty() {
    return this.items.length == 0;
  }

  public hasBackground() {
    for (let item of this.items) {
      if (item.getFunction() == ThumbnailItemTFunction.BACKGROUND) return true;
    }
    return false;
  }

  public addItemToFront(item: ThumbnailItem) {
    this.onChange.emit();
    this.items.unshift(item);
    this.onThumbnailItemAdded.emit(item);
    return item;
  }

  // Adding item to front without firing event of saving previous thumb
  public addItemToFrontPrev(item: ThumbnailItem) {
    this.items.unshift(item);
    this.onThumbnailItemAdded.emit(item);
    return item;
  }

  public addItem(item: ThumbnailItem) {
    this.onChange.emit(item);
    this.items.push(item);
    this.onThumbnailItemAdded.emit(item);
    return item;
  }

  // Adding item without firing event of saving previous thumb
  public addItemPrev(item: ThumbnailItem) {
    this.items.push(item);
    this.onThumbnailItemAdded.emit(item);
    return item;
  }

  public removeItem(item: ThumbnailItem) {
    this.onChange.emit(item);
    this.items = this.items.filter((obj) => !(obj === item));
    this.onThumbnailItemRemoved.emit(item);
  }

  // Removing item without firing event of saving previous thumb
  public removeItemPrev(item: ThumbnailItem) {
    this.items = this.items.filter((obj) => !(obj === item));
    this.onThumbnailItemRemoved.emit(item);
  }

  public removeAllItems() {
    for (let it of this.items) {
      this.removeItem(it);
    }
  }

  public updatePrev(item: ThumbnailItem[]) {
    this.items = item;
  }
}
