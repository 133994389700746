import { Component, Input, OnChanges } from "@angular/core";
import {
  ParameterPath,
} from "@MOSAR/mosar-dashboard-datamodel";
import jsonpath from "jsonpath";

@Component({
  selector: "app-path-reference-badge",
  templateUrl: "./path-reference-badge.component.html",
  styleUrls: ["./path-reference-badge.component.scss"],
})
export class PathReferenceBadgeComponent implements OnChanges {
  @Input() path: string;
  @Input() contextModel: any;
  @Input() displayAsBadge = true;
  @Input() displaySmallBlack = true;
  @Input() contextPossibleParameters: ParameterPath[] = [];

  isPathValid = true;
  pathLabel = "";

  ngOnChanges(): void {
    this.isPathValid = this.isPathValidFn();
    this.pathLabel = this.getPathLabel();
  }

  /**
   * Returns wether the local path is a valid path of the scenario or not.
   * Path is considered valid if there is a value for the parameter in the scenario or if the path is
   * listed in the scenario possible paths.
   * FIXME: We check first if there is a value because all paths of a scenario may not be listed
   * (some paths are still unhandled) in the possible paths.
   */
  private isPathValidFn() {
    // Check if a value is defined for the specified path
    if (jsonpath.query(this.contextModel, this.path).length > 0) {
      return true;
    }
    // Check of the path is listed on the scenario possible paths
    if (
      this.contextPossibleParameters.find((param) =>
        param.path.startsWith(this.path)
      )
    ) {
      return true;
    }
    return false;
  }

  private getPathLabel() {
    //NB: 'startsWith' is used instead of a regaluar comparison because of trailing '.value' in some paths
    // eslint-disable-next-line prefer-const
    let foundParameterPath = this.contextPossibleParameters.find((param) =>
      param.path.startsWith(this.path)
    );
    return foundParameterPath ? foundParameterPath.label : "";
  }
}
