<!-- ABSOLUTE or RELATIVE position switch -->
<div *ngIf="!readonly" [ngClass]="{ 'level-0': level+1 == 0, 'level-1': level+1 == 1, 'level-2': level+1 == 2, 'level-3': level+1 == 3 }">
    <div class="key help">
        Positioning
        <app-contextual-help-icon [propertyScheme]="positionTypeScheme" [objectClass]="'LateralPosition'"></app-contextual-help-icon>
    </div>
    <ng-select
        appendTo="body"
        [items]="['ABSOLUTE', 'RELATIVE_LANE', 'RELATIVE_DISTANCE']"
        [(ngModel)]="model.positionType"
        placeholder="Positionment reference"
        (change)="ngOnChanges();valueChanged($event)"
        [clearable]="true">
    </ng-select>
</div>

<!-- Segment / strip and Path attributes -->
<app-reference-view key="segment" *ngIf="!isPositionRelative" titleOverride="Reference" (update)="ngOnChanges();valueChanged($event)"></app-reference-view>
<app-reference-view key="strip" *ngIf="!isIntersection() && !isPositionRelative" (update)="ngOnChanges();valueChanged($event)"></app-reference-view>
<app-reference-view key="path" *ngIf="isIntersection()"></app-reference-view>

<!-- All other attributes -->
<app-parameter-view
    [path]="path"
    [annotations]="annotations"
    [context]="context"
    [scheme]="scheme"
    [filter]="schemefilter"
    [hiddenElements]="hiddenElements"
    [formulaEnabled]="formulaEnabled"
    [model]="model"
    [prevModel]="prevModel"
    [readonly]="readonly"
    [stepIndex]="stepIndex"
    [lastUpdatedAttr]="lastUpdatedAttr"
    (lastUpdatedEvent)="setLastUpdatedAttr($event, false)"
    (propagatedEvent)="propagateValue($event);valueChanged($event)"
    [level]="level"
    [statistics]="statistics">
</app-parameter-view>
