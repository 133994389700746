import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CollectionService } from "../../collection/collection.service";
import { Lightbox } from "ngx-lightbox";
import { DomSanitizer } from "@angular/platform-browser";
import { ThumbnailService } from "app/thumb-generator/thumbnail.service";

export interface Picturable {
  name?: string;
  label?: string;
  image: string;
}

@Component({
  selector: "app-picture-view",
  templateUrl: "./picture-view.component.html",
  styleUrls: ["./picture-view.component.scss"],
})
export class PictureViewComponent implements OnInit {
  @Input("thumbnail") thumbnail = false;
  @Input("readonly") readonly = false;
  @Input("picturable") picturable: Picturable;
  @Input("generator") generator: any;
  @Input("scenarioId") scenarioId: any;

  public storeUrl: string;

  private tempThumbData;

  @ViewChild("picture") pictureRef: ElementRef;

  constructor(
    private collectionService: CollectionService,
    private lightBox: Lightbox,
    private sanitizer: DomSanitizer,
    private thumbService: ThumbnailService
  ) {}

  public ngOnInit() {
    this.storeUrl = this.collectionService.getStepImageStoreUrl();
    this.tempThumbData = this.getLocalthumbData();
  }

  public updatePicture(imageId: string) {
    this.picturable.image = imageId;
    sessionStorage.removeItem(`thumbData-${this.scenarioId}`);
  }

  openDialog() {
    if (!this.thumbnail)
      this.lightBox.open(
        [
          {
            src: <string>(
              this.sanitizer.bypassSecurityTrustUrl(
                this.pictureRef.nativeElement.src
              )
            ),
            caption: null,
            thumb: null,
          },
        ],
        0,
        { centerVertically: true }
      );
  }

  private getLocalthumbData() {
    return JSON.parse(sessionStorage.getItem(`thumbData-${this.scenarioId}`));
  }

  thumbDialog() {
    this.tempThumbData = this.getLocalthumbData();
    if (this.picturable && this.picturable.image) {
      this.collectionService.getThumbnail(this.picturable.image).subscribe({
        next: (res) => {
          this.openThumbDialog(res);
        },
        error: () => {
          this.openThumbDialog(this.tempThumbData, true);
        },
      });
    } else {
      this.openThumbDialog(this.tempThumbData);
    }
  }

  private openThumbDialog(data?: any, errorPicturable?: boolean) {
    // Get stripes equipments of actors
    const equipments =
      this.generator?.elements
        .map((a) => {
          if (a.equipments && a.equipments.length > 0) {
            return a.equipments.map((e) => {
              if (e.image) return e;
            });
          }
        })
        ?.flatMap((equipment) => equipment)
        ?.filter((equipment) => equipment) || [];
    if (equipments && equipments.length > 0) {
      this.generator.elements = [...this.generator.elements, ...equipments];
    }

    this.thumbService
      .open(
        this.generator.elements,
        this.generator.bg,
        this.scenarioId,
        data,
        errorPicturable
      )
      .then((res) => {
        let data = new FormData();
        data.append("imageFile", res.img);

        this.collectionService.storeStepImage(data).then((response: any) => {
          let imageId = response.newid;
          this.picturable.image = imageId;
          if (this.scenarioId)
            sessionStorage.setItem(
              `thumbData-${this.scenarioId}`,
              JSON.stringify(res.data)
            );
          this.collectionService.setThumbnail(imageId, res.data).subscribe();
          res.modalRef.dismiss();
        });
      });
  }
}
