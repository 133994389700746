<ng-container>
  <div class="modal-header bg-dark text-light">
    <span class="modal-title">
      <mat-icon>search</mat-icon>&nbsp;
      <span>Filter constraint</span>
    </span>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.dismiss('close')">
      <span aria-hidden="true" class="text-light">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4 border-right">
        <div class="float-right mb-2">
          <button class="btn btn-sm btn-link" (click)="flatView=!flatView" triggers="hover" container="body" ngbtooltip="Toggle view style">
            <mat-icon>{{ flatView ? 'account_tree' : 'view_list' }}</mat-icon>
          </button>
        </div>
        <label class="mt-2">Parameter browser</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <mat-icon>filter_alt</mat-icon>
            </div>
          </div>
          <input type="text" [(ngModel)]="nameFilter" class="form-control" placeholder="Parameter name or description contains...">
        </div>
        <div class="mt-3 param-selection">
          <ng-container *ngFor="let node of filtersTree.nodes; let i = index">
            <app-filters-dialog-input-parameter
                [filtersTree]="node"
                [currentFilter]="currentFilter"
                [nameFilter]="nameFilter"
                [flatView]="flatView"
                (onCurrentFilterChange)="onCurrentFilterChange($event)">
            </app-filters-dialog-input-parameter>
          </ng-container>
        </div>
      </div>
      <div class="col-8">
        <h4 class="mb-3">Constraints</h4>
        <label>Parameter</label>
        <div *ngIf="currentFilter" class="border rounded p-2 bg-light text-dark">
          <div class="badge badge-info float-right">{{ getTypeLabel(currentFilter) }}</div>
          <b>{{ currentFilter.category }} | {{ currentFilter.title }}</b><br />
          <div class="my-1 multiline">{{ currentFilter.description }}</div>
          <small class="text-muted"><i>{{ currentFilter.name }}</i></small>
        </div>
        <div *ngIf="!currentFilter" class="text-muted"><i><small>Please select a parameter on the left</small></i></div>
        <div class="row" *ngFor="let subfilter of subFilters; let i = index">
          <hr class="w-100 mb-1">
          <div class="col-md-12">
            <div class="float-right">
              <button class="btn btn-sm btn-link text-danger m-0 p-0" (click)="removefilter(i, subfilter)" triggers="hover" container="body" ngbtooltip="Delete constraint">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <p class="mt-2">
              <label>Constraint type</label>
              <app-filters-dialog-input-constraint
                [currentFilter]="currentFilter"
                [(currentConstraint)]="subfilter.operator">
              </app-filters-dialog-input-constraint>
            </p>
            <p>
              <label>Value</label>
              <app-filters-dialog-input-value
                [currentFilter]="currentFilter"
                [currentConstraint]="subfilter.operator"
                [(currentValue)]="subfilter.value">
              </app-filters-dialog-input-value>
            </p>
          </div>
        </div>
        <hr *ngIf="currentFilter" class="w-100 mt-1">
        <div *ngIf="currentFilter" class="toolbar btn-group" role="group">
          <button class="btn btn-success btn-sm" (click)="addfilter()" triggers="hover" container="body" ngbtooltip="Add constraint">
            <mat-icon class="mx-1">add</mat-icon>
            <span class="mx-1">Or...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-light d-flex justify-content-right">
    <button type="button" class="btn btn-light" (click)="modalRef.dismiss('close')">
      Cancel
    </button>
    <button type="button" class="btn btn-success" (click)="submitFilter()" [disabled]="submitButtonDisabled()">
      Ok
    </button>
  </div>
</ng-container>