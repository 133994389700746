<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center p-3">
        <h2>{{group}} Group</h2>
        <button type="button" class="btn btn-success" (click)="addUser()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <ul class="list-group mb-3">
        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            *ngFor="let user of users; let i = index">
            {{user}}
            <app-confirm-dialog #deleteDialog (confirmed)="removeUser(user)" confirm-label="Delete" icon="warning">
                This action will remove <code>{{ user }}</code> from group <code>{{ group }}</code>
            </app-confirm-dialog>
            <button type="button" class="btn btn-danger" (click)="deleteDialog.show()">
                <mat-icon>remove</mat-icon>
            </button>
        </li>
    </ul>
</div>