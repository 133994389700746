import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNull'
})
export class RemoveNullPipe implements PipeTransform {

  transform(values: Array<any>): Array<any> {
    return values.filter(v => v);
  }

}
