import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  @Input("confirm-label") confirmLabel = "Ok";
  @Input("loader") loader = false;
  @Input("icon") icon = "question_answer";
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();
  @ViewChild("dialogTemplate", { static: true })
  private dialogModal: TemplateRef<any>;
  @Input() disabled = false;

  constructor(private modalService: NgbModal) {}

  show() {
    this.modalService.open(this.dialogModal).result.then(
      () => {
        this.confirmed.emit(true);
      },
      () => {
        this.cancel.emit(true);
      }
    );
  }
}
