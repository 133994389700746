import { DataModelService } from "@MOSAR/mosar-dashboard-datamodel";
import { CommonModule } from "@angular/common";
import { /*HttpClientModule,*/ HttpClient } from "@angular/common/http";
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { ConfigureOptions } from "./configure-options";
import { ConfigureService } from "./ng4-configure.service";

@NgModule({
  imports: [
    CommonModule,
    //HttpClientModule
  ],
  declarations: [],
  providers: [
    ConfigureService,
    ConfigureOptions,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [ConfigureService, HttpClient, DataModelService],
    },
  ],
})
export class NgConfigureModule {
  public static forRoot(): ModuleWithProviders<NgConfigureModule> {
    return {
      ngModule: NgConfigureModule,
      providers: [ConfigureService, ConfigureOptions],
    };
  }
}

export function init(configService: ConfigureService, http: HttpClient) {
  return () => configService.load();
}
