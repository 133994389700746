import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textMention',
  pure: false
})
export class TextMentionPipe implements PipeTransform {

  transform(value: string, elements: any[], html?: boolean): any {
    if(value)
      return value.replace(/@([0-9]+)/g, (item, $1) => `${html ? '<i>': '@['}${this.itemIdToName($1, elements)}${html ? '</i>': ']'}`);
  }

  private itemIdToName(id, elements) {
    const item = elements.find(item => item.id == id);
    return item ? item.name : id
  }

}
