import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigureService } from "service/ng4-configure/ng4-configure.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ContainerService {
  containers = {
    scenario: [],
    actor: [],
    infrastructure: [],
    infrastructureelement: [],
    behavior: [],
    equipment: [],
    tag: [],
    drivingautomationsystem: [],
    usecase: [],
    testprotocol: [],
  };

  constructor(
    private http: HttpClient,
    private configService: ConfigureService
  ) {}

  getContainers(model) {
    return this.http
      .get<Array<any>>(
        `${this.configService.config.apiUrl}/container/${model}/`
      )
      .pipe(map((c) => (this.containers[model] = c)));
  }

  getContainerById(model, id, skipError?: boolean) {
    const params = skipError ? { skipError: JSON.stringify(skipError) } : {};
    let i = 0;
    if (!this.getLocalContainerById(model, id))
      i = this.containers[model].push({ resources: [{ id: id, name: id }] });
    return this.http
      .get<any>(
        `${this.configService.config.apiUrl}/container/${model}/${id}`,
        { params }
      )
      .pipe(map((c) => (this.containers[model][i - 1] = c)));
  }

  getLocalContainerById(model, id) {
    return this.containers[model].find((c) => c.resources[0].id == id);
  }

  editcontainer(model, id, container) {
    return this.http
      .put(
        `${this.configService.config.apiUrl}/container/${model}/${id}`,
        container
      )
      .pipe(
        map(
          () =>
            (this.containers[model][
              this.containers[model].indexOf(
                this.containers[model].find((c) => c.resources[0].id == id)
              )
            ].resources[0] = container)
        )
      );
  }

  createContainer(model, container) {
    return this.http.post<any>(
      `${this.configService.config.apiUrl}/container/${model}`,
      container
    );
  }

  deleteContainer(model, id) {
    return this.http
      .delete(`${this.configService.config.apiUrl}/container/${model}/${id}`)
      .pipe(
        map(
          () =>
            (this.containers[model] = this.containers[model].filter(
              (c) => c.resources[0].id != id
            ))
        )
      );
  }

  getContainerHistory(model, id) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/container/${model}/${id}/history`
    );
  }
}
