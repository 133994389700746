import { ThumbnailCurve } from "./thumbnail-items/thumbnail-curve/thumbnail-curve-model";
import { ThumbnailCurveView } from "./thumbnail-items/thumbnail-curve/thumbnail-curve-view";
import { ThumbnailImage } from "./thumbnail-items/thumbnail-image/thumbnail-image-model";
import { ThumbnailImageView } from "./thumbnail-items/thumbnail-image/thumbnail-image-view";
import { ThumbnailItem } from "./thumbnail-items/thumbnail-item-model";
import { ThumbnailItemView } from "./thumbnail-items/thumbnail-item-view";
import { ThumbnailLine } from "./thumbnail-items/thumbnail-line/thumbnail-line-model";
import { ThumbnailLineView } from "./thumbnail-items/thumbnail-line/thumbnail-line-view";
import { ThumbnailText } from "./thumbnail-items/thumbnail-text/thumbnail-text-model";
import { ThumbnailTextView } from "./thumbnail-items/thumbnail-text/thumbnail-text-view";
import { Thumbnail, ThumbnailDimension } from "./thumbnail-model";

export class ThumbnailSVGView {
  protected svgElement: Element;
  protected thumbnail: Thumbnail;

  private selectedItem: ThumbnailItemView = null;
  private lastId = 1;

  // crop properties
  public sizeToCrop = 0.6;
  public selectedRatio = 2.39;

  constructor(thumbnail: Thumbnail, svgElement: Element) {
    this.svgElement = svgElement;
    this.thumbnail = thumbnail;

    thumbnail.onThumbnailItemAdded.subscribe((item) => {
      this.onThumbnailItemAdded(item);
    });
  }

  public getSVGElement(): Element {
    return this.svgElement;
  }

  public getSelectedItem() {
    return this.selectedItem;
  }

  // crop new size
  public getDimention(): ThumbnailDimension {
    const height = this.sizeToCrop * 400 <= 400 ? this.sizeToCrop * 400 : 400;
    const width =
      height * this.selectedRatio <= 600 ? height * this.selectedRatio : 600;
    return { height, width };
  }

  public resetSelection() {
    this.setSelectedItem(null);
  }

  private setSelectedItem(itemView: ThumbnailItemView) {
    if (this.selectedItem != null) this.selectedItem.setControlsVisible(false);
    this.selectedItem = itemView;
    if (this.selectedItem != null) itemView.setControlsVisible(true);
  }

  // Fired when an item is added to the model
  private onThumbnailItemAdded(item: ThumbnailItem) {
    // View creation
    const itemView = this.createItemView(item);

    // Events registering
    this.thumbnail.onThumbnailItemRemoved.subscribe((removed) => {
      if (removed === item) {
        itemView.destroySVGElement();
        this.resetSelection();
      }
    });

    itemView.getSVGElement().addEventListener("click", () => {
      this.setSelectedItem(itemView);

      let colorButton = document.getElementById("dropdownColor");
      colorButton.setAttribute("style", "visibility:hidden;");
      if (itemView.getControls().find((elt) => elt == "color") != undefined) {
        colorButton.setAttribute("style", "visibility:visible;");
      }
    });

    itemView.getSVGElement().addEventListener("dblclick", () => {
      if (itemView.getControls().find((elt) => elt == "text") != undefined) {
        let textView = <ThumbnailTextView>itemView;
        textView.changeText();
      }
    });

    // Setup
    this.setSelectedItem(itemView);
  }

  private createItemView(item: ThumbnailItem): ThumbnailItemView {
    if (item instanceof ThumbnailImage)
      return new ThumbnailImageView(item, this.svgElement, this.lastId++);
    else if (item instanceof ThumbnailCurve)
      return new ThumbnailCurveView(item, this.svgElement, this.lastId++);
    else if (item instanceof ThumbnailText)
      return new ThumbnailTextView(item, this.svgElement, this.lastId++);
    else if (item instanceof ThumbnailLine)
      return new ThumbnailLineView(item, this.svgElement, this.lastId++);
    else console.error("No creation method found for thumbnail item.", item);
    return null;
  }
}
