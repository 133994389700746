import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '../group.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewUserDialogComponent } from '../new-user-dialog/new-user-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  users = [];
  group: string;

  constructor(private route: ActivatedRoute,
    private groupService: GroupService,
    public snackBar: MatSnackBar,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.group = params.groupId;
      this.users = [];
      this.getGroups();
    })
  }

  getGroups() {
    this.groupService.getUsers(this.group).subscribe(users => this.users = users);
  }

  removeUser(user) {
    this.groupService.removeUsers(this.group, user).subscribe(() => {
      this.getGroups();
      this.snackBar.open('User removed', 'Ok', { duration: 1500 });
    });
  }

  addUser() {
    let modal = this.modalService.open(NewUserDialogComponent, { centered: true });
    modal.componentInstance.withGroups = false;
    modal.componentInstance.onSubmit.subscribe(users => {
      this.groupService.addUsers(this.group, users).subscribe(() => {
        this.getGroups();
        this.snackBar.open('User added', 'Ok', { duration: 1500 });
      });
    });
  }

}
