import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  public comment: string = "";

  @Input() submitLabel = "OK";
  @Input() cancelLabel =  "Cancel";
  @Input() title = "Are you sure ?";
  @Input() content = "This action is definitive and irreversible.";
  @Input() hasComment = false;
  @Input() showCancel = true;


  constructor(
    public modalRef: NgbActiveModal
  ) { }
}
