import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContainerResolver } from "./resolvers/container.resolver";
import { GroupComponent } from "./shared/group/group.component";
import { SwaggerComponent } from "./shared/swagger/swagger.component";
import { AuthGuard } from "guards/authGuard";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    canActivate: [AuthGuard],
    resolve: {
      containerData: ContainerResolver,
    },
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },

  {
    path: "swagger/:apiRef",
    component: SwaggerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "group/:groupId",
    component: GroupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "scenarios",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./scenario/scenario.module").then((m) => m.ScenarioModule),
  },
  {
    path: "collections",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./collection/collection.module").then((m) => m.CollectionModule),
  },
  {
    path: "usecase",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./use-case/use-case.module").then((m) => m.UseCaseModule),
  },
  {
    path: "drivingautomationsystem",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./system/system.module").then((m) => m.SystemModule),
  },
  {
    path: "testprotocol",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./test-protocol/test-protocol.module").then(
        (m) => m.TestProtocolModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
