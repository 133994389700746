<ng-container *ngIf="collection.id != 0">
  <div class="d-flex align-items-center collection-item position-relative" [ngClass]="{
      l0: level == 0,
      l1: level == 1,
      l2: level == 2,
      l3: level == 3,
      l4: level == 4,
      l5: level == 5
    }">
    <div (click)="$event.stopPropagation(); showChildren = !showChildren" class="chevron position-absolute"
      *ngIf="hasChildren()">
      <mat-icon [style.transform]="showChildren ? 'rotate(90deg)' : ''">chevron_right</mat-icon>
    </div>
    <button class="dropdown-item form-control form-control-sm limit-width"
      [disabled]="isUsed(collection) || ( disableFunctionnalScenario && !!collection && collection.type === 'FUNCTIONAL')"
      (click)="select(collection)"  popoverClass="customPopover" [ngbPopover]="
        collection.image
          ? imageCollectionPopover
          : collection.sprite
          ? spriteCollectionPopover
          : null
      " triggers="hover" container="body">
      <div class="sprite float-right" *ngIf="sprite">
        <app-sprite-view class="sprite" *ngIf="collection.sprite" [spriteable]="collection"
          [readonly]="true"></app-sprite-view>
      </div>
      <div ngbTooltip="{{ collection.description }}" triggers="hover" container="body" placement="bottom" class="item" [ngStyle]="{
          opacity: collection.name
            ?.toLowerCase()
            .includes(searchFromParent?.toLowerCase())
            ? '1'
            : '0.5'
        }">
        {{ collection.name }}
        <small><span class="text-muted">{{ collection.description }}</span></small>
      </div>
    </button>
    <ng-template #imageCollectionPopover>
      <div>
        <app-picture-view [picturable]="collection" [readonly]="true">
        </app-picture-view>
      </div>
    </ng-template>
    <ng-template #spriteCollectionPopover>
      <div>
        <app-sprite-view [spriteable]="collection" [readonly]="true">
        </app-sprite-view>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="showChildren || !level">
  <ng-container *ngFor="
      let child of collections
        | collectionParent : collection.id : searchFromParent
        | orderBy : 'name'
    ">
    <app-add-collection-item [disableFunctionnalScenario]="disableFunctionnalScenario" [collection]="child" [collections]="collections" [searchFromParent]="searchFromParent" [isBehaviorCollection]="isBehaviorCollection"
      [level]="level + 1" (selected)="select($event)" [sprite]="sprite" [usedCollections]="usedCollections">
    </app-add-collection-item>
  </ng-container>
</ng-container>