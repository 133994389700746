import { Component, Input, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CollectionService } from "../../collection/collection.service";
import { Lightbox } from "ngx-lightbox";
import { DomSanitizer } from "@angular/platform-browser";

export interface Spriteable {
  name?: string;
  label?: string;
  sprite: string;
  image?: string;
}

@Component({
  selector: "app-sprite-view",
  templateUrl: "./sprite-view.component.html",
  styleUrls: ["./sprite-view.component.scss"],
})
export class SpriteViewComponent implements OnInit {
  @Input("readonly") readonly = false;
  @Input("spriteable") spriteable: Spriteable;
  @Input("clickable") clickable = false;
  @Input("small") small: boolean;
  @Input() isSprite = true;

  @ViewChild("picture") pictureRef: ElementRef;

  public storeUrl: string;

  constructor(
    private restService: CollectionService,
    private lightBox: Lightbox,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit() {
    this.storeUrl = this.restService.getStepImageStoreUrl();
  }

  public updatePicture(imageId: string) {
    if (this.isSprite) {
      this.spriteable.sprite = imageId;
    } else {
      this.spriteable.image = imageId;
    }
  }

  openDialog() {
    if (this.clickable)
      this.lightBox.open(
        [
          {
            src: <string>(
              this.sanitizer.bypassSecurityTrustUrl(
                this.pictureRef.nativeElement.src
              )
            ),
            caption: null,
            thumb: null,
          },
        ],
        0,
        { centerVertically: true }
      );
  }
}
