import {
  DataModelService,
  MultiLevelParamAttributes,
  MultiLevelParamValue,
  ParamScheme,
} from "@MOSAR/mosar-dashboard-datamodel";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentationLinkService } from "../documentation-link.service";

@Component({
  selector: "app-multilevelparam-update",
  templateUrl: "./multilevelparam-update.component.html",
  styleUrls: ["./multilevelparam-update.component.scss"],
})
export class MultiLevelParamUpdateComponent implements OnChanges, OnInit {
  @Input("scheme") scheme: ParamScheme; // Data scheme
  @Input("parentScheme") parentScheme: ParamScheme = null; // Scheme of parent object, optional
  @Input("model") model: any; // Data value
  @Input("context") context: any;
  @Input("attributes") attributes: any;
  @Input("reference") reference: any;
  @Output() confirmed = new EventEmitter<MultiLevelParamValue>();
  @ViewChild("dialogTemplate", { static: true })
  private dialogModal: TemplateRef<any>;
  @Input() formulaEnabled = true;

  public updatedValue: any = {};

  public mlpAttributes: MultiLevelParamAttributes = {
    unitLabel: [],
    abstractValues: [],
    relativeAbstractValues: [],
  };
  public abstractValues = [];
  public unitLabels = [];
  public abstractSelected = false;
  public concreteSelected = false;
  public rangeSelected = false;
  public formulaSelected = false;
  public noneSelected = true;

  public variablesScheme;

  formula: boolean;
  isErrorRange = false;

  description: string;
  enumsDescription: string;
  docLink: string = null;

  constructor(
    private modalService: NgbModal,
    private dataModel: DataModelService,
    private docservice: DocumentationLinkService
  ) {
    this.variablesScheme = dataModel.getClassProperty("VariableMapping");
  }

  addVariable(array) {
    if (array) {
      this.updatedValue.value.variables.push(
        this.dataModel.createFullObject("VariableMapping")
      );
    } else {
      this.updatedValue.value.variables = [
        this.dataModel.createFullObject("VariableMapping"),
      ];
    }
  }

  private fieldIsSet(fieldName, obj): boolean {
    if (obj != null && fieldName in obj) {
      const value = obj[fieldName];
      if (value === 0) {
        return true;
      }
      if (value == null || value === "") {
        return false;
      }
      return true;
    }
    return false;
  }

  ngOnInit() {
    if (this.model && this.model.value && this.model.value.formula)
      this.formula = true;
  }

  ngOnChanges() {
    if (this.scheme.description) {
      const descriptionMatch = this.scheme.description.match(
        "((?:[\r\n]|.)*)[\r\n][\r\n]+((?:[\r\n]|.)*)"
      );
      this.description = descriptionMatch
        ? descriptionMatch[1]
        : this.scheme.description;
      this.enumsDescription = descriptionMatch ? descriptionMatch[2] : null;
    }
    this.mlpAttributes =
      this.attributes ??
      this.dataModel.getMultiLevelParamAttributes(this.scheme.className);

    this.abstractValues = this.mlpAttributes.abstractValues;

    // uniLabel is the array in scheme
    if (Array.isArray(this.mlpAttributes.unitLabel)) {
      this.unitLabels = [...this.mlpAttributes.unitLabel];
    }

    // uniLabel is the string in scheme
    if (
      this.mlpAttributes.unitLabel &&
      !Array.isArray(this.mlpAttributes.unitLabel)
    ) {
      if (this.unitLabels.indexOf(this.mlpAttributes.unitLabel) === -1) {
        this.unitLabels = [];
        this.unitLabels.push(this.mlpAttributes.unitLabel);
      }
    }

    if (this.reference) {
      this.abstractValues =
        this.mlpAttributes.relativeAbstractValues ??
        this.mlpAttributes.abstractValues;
    }
    if (this.model) {
      if (this.fieldIsSet("abstracted", this.model.value)) {
        this.setSelectedType("abstract");
      } else if (this.fieldIsSet("concrete", this.model.value)) {
        this.setSelectedType("concrete");
      } else if (
        this.fieldIsSet("min", this.model.value) ||
        this.fieldIsSet("max", this.model.value)
      ) {
        this.setSelectedType("range");
      } else if (this.fieldIsSet("formula", this.model.value)) {
        this.setSelectedType("formula");
      } else {
        this.setSelectedType("");
      }
    }
    if (this.model) {
      this.updatedValue = JSON.parse(JSON.stringify(this.model));
    } else {
      this.updatedValue = {};
    }

    this.docLink = this.parentScheme
      ? this.docservice.fromObjetAndPropertySchemes(
          this.parentScheme,
          this.scheme
        )
      : null;
  }

  setSelectedType(type: string) {
    this.abstractSelected = type == "abstract";
    this.concreteSelected = type == "concrete";
    this.rangeSelected = type == "range";
    this.formulaSelected = type == "formula";
    this.noneSelected =
      !this.abstractSelected && !this.concreteSelected && !this.rangeSelected;
  }

  show() {
    // Ensure that the model has at least the structure "model: { value{} }"
    this.updatedValue = { value: { unit: this.unitLabels[0] } };
    if (this.model) {
      this.updatedValue = {
        ...this.updatedValue,
        value: {
          ...this.updatedValue.value,
          ...JSON.parse(JSON.stringify(this.model))?.value,
        },
      };
    }

    this.modalService.open(this.dialogModal, { size: "lg" }).result.then(
      () => {
        if (!this.abstractSelected) {
          delete this.updatedValue.value["abstracted"];
        }
        if (!this.concreteSelected) {
          delete this.updatedValue.value["concrete"];
        }
        if (!this.rangeSelected) {
          delete this.updatedValue.value["min"];
          delete this.updatedValue.value["max"];
          delete this.updatedValue.value["minExclusive"];
          delete this.updatedValue.value["maxExclusive"];
        }
        if (!this.formulaSelected) {
          /* empty */
        }
        this.confirmed.emit(this.updatedValue);
      },
      (reason) => {
        // Form dismissed
        if (reason == "undef") {
          delete this.updatedValue.value["abstracted"];
          delete this.updatedValue.value["concrete"];
          delete this.updatedValue.value["min"];
          delete this.updatedValue.value["max"];
          delete this.updatedValue.value["minExclusive"];
          delete this.updatedValue.value["maxExclusive"];
          delete this.updatedValue.value["formula"];
          // unit
          this.confirmed.emit(this.updatedValue);
        }
        // Nothing to do if cancel of closed
      }
    );
  }

  // Check Input value
  checkInputvaluesFromRange() {
    if (
      this.updatedValue.value.min !== null &&
      this.updatedValue.value.max !== null &&
      this.updatedValue.value.min > this.updatedValue.value.max
    ) {
      this.isErrorRange = true;
    } else {
      this.isErrorRange = false;
    }
  }

  removeVariable(i, array) {
    array.splice(i, 1);
  }
}
