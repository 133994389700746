import { ThumbnailItem } from '../thumbnail-item-model'
import { ThumbnailPoint } from '../../thumbnail-model'

/**
 * Path
 * Item of a thumbnail that is a SVG path.
 */
export class ThumbnailCurve extends ThumbnailItem {

    private fromControlPoint : ThumbnailPoint = new ThumbnailPoint();
    private toControlPoint : ThumbnailPoint = new ThumbnailPoint();
    private color : string;


    public constructor(item?) {
        super(item);
        if(item){
            this.fromControlPoint.x = item.fromControlPoint.x
            this.fromControlPoint.y = item.fromControlPoint.y
            this.toControlPoint.x = item.toControlPoint.x
            this.toControlPoint.y = item.toControlPoint.y
            this.color = item.color;
        } else {
            this.fromControlPoint.x = 40;
            this.fromControlPoint.y = 0;
            this.toControlPoint.x = 60;
            this.toControlPoint.y = 100;
            this.color = 'black';
        }
    }

    public copy() {
        let temp = new ThumbnailCurve({...this});
        return temp;
    }

    public getColor() : string {
        return this.color;
    }

    public getFromControlPoint() : ThumbnailPoint {
        return this.fromControlPoint;
    }

    public getToControlPoint() : ThumbnailPoint {
        return this.toControlPoint;
    }

    public setColor(color : string) {
        this.onChange.emit(this);
        this.color = color;
        this.onItemChange.emit(this);
    }

    public setFromControlPoint(x : number, y : number) {
        this.onChange.emit(this);
        this.fromControlPoint.x = x;
        this.fromControlPoint.y = y;
        this.onItemChange.emit(this);
    }

    public setToControlPoint(x : number, y : number) {
        this.onChange.emit(this);
        this.toControlPoint.x = x;
        this.toControlPoint.y = y;
        this.onItemChange.emit(this);
    }
}

