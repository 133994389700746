import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ConfigureService } from "../../service/ng4-configure/ng4-configure.service";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LibraryService {
  constructor(
    public http: HttpClient,
    public configService: ConfigureService
  ) {}

  private handleError(method: String) {
    return (err: any) => {
      if (err.constructor.name === "String")
        if (
          err.includes(
            "The concrete scenarios of this scenario are already being computed. Please wait."
          )
        )
          return err;
      return throwError(err);
    };
  }

  createLibrary(container, libraryPostBody, nameApi: string) {
    return this.http.post(
      `${this.configService.config.apiUrl}/${nameApi}`,
      libraryPostBody,
      { params: { container } }
    );
  }

  // Duplicate library
  duplicateLibrary(id, container, move: boolean, comment, nameApi: string) {
    const params = { container, comment, move };
    return this.http.post(
      `${this.configService.config.apiUrl}/${nameApi}/${id}/duplicate`,
      null,
      { params: params }
    );
  }

  getLibraryById(id, objectModel) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/${objectModel}/${id}`
    );
  }

  findLibrary(
    parameters: { filters: any; container: string },
    nameApi: string
  ) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/${nameApi}`,
      {
        params: {
          filters: JSON.stringify(parameters.filters) ?? undefined,
          container: parameters.container,
        },
      }
    );
  }

  getLibraryHistory(id, nameApi: string) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/${nameApi}/${id}/history`
    );
  }

  deleteLibrary(id, params, nameApi) {
    return this.http.delete(
      `${this.configService.config.apiUrl}/${nameApi}/${id}`,
      { params }
    );
  }

  updateLibrary(id, libraryPostBody, nameApi: string) {
    return this.http.put(
      `${this.configService.config.apiUrl}/${nameApi}/${id}`,
      libraryPostBody
    );
  }

  exportLibrary(nameApi: string, options: any) {
    return this.http.get(
      `${this.configService.config.apiUrl}/export/${nameApi}`,
      {
        responseType: "blob",
        params: options,
      }
    );
  }

  getLibraryAnnotations(id, nameApi) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/${nameApi}/${id}/annotations`
    );
  }

  updateLibraryStatusWorkflow(library, comment, nameApi) {
    return this.http.put(
      `${this.configService.config.apiUrl}/${nameApi}/${library.id}/status/workflow`,
      comment
    );
  }

  invalidateLibraryStatus(library, comment, nameApi) {
    return this.http.put(
      `${this.configService.config.apiUrl}/${nameApi}/${library.id}/status/invalidate`,
      comment
    );
  }

  feedbackLibrary(library, comment, nameApi) {
    return this.http.put(
      `${this.configService.config.apiUrl}/${nameApi}/${library.id}/status/feedback`,
      comment
    );
  }

  reopenLibrary(library, comment, nameApi) {
    return this.http.put(
      `${this.configService.config.apiUrl}/${nameApi}/${library.id}/status/reset`,
      comment
    );
  }

  setupScenario(scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: "",
    };
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}/setup`,
      scenarioPostBody
    );
  }

  /* Scenario status method */

  updateScenariosAsync(id, scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: "",
    };
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${id}`,
      scenarioPostBody
    );
  }

  setScenarioAnnotations(id, annotations: Array<any>) {
    return this.http.put<any>(
      `${this.configService.config.apiUrl}/scenario/${id}/annotation`,
      annotations
    );
  }

  synchronizeScenario(id) {
    return this.http
      .put(
        `${this.configService.config.apiUrl}/scenario/${id}/synchronize`,
        null
      )
      .pipe(catchError(this.handleError("synchronizeScenario")));
  }

  getInputs(id, nameApi: string) {
    return this.http.get(
      `${this.configService.config.apiUrl}/${nameApi}/${id}/inputs`,
      {
        responseType: "blob",
      }
    );
  }
}
