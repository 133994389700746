<div *ngIf="!readonly" class="float-right">
  <app-file-update-dialog #updateFileDialog 
        [file-id]="filable.file" 
        (update)="updateFile($event)" >
  </app-file-update-dialog>
  <button class="btn btn-sm btn-success" (click)="updateFileDialog.show()" id="btnAttachmentView">
    <small><mat-icon aria-label="Upload">attachment</mat-icon></small>
  </button>
</div>
<a *ngIf="filable.file" class="pointer text-primary" (click)="downloadImg(filable.file)">
  <div *ngIf="showFilename">
    <mat-icon>get_app</mat-icon> {{filable.filename}}
  </div>
  <div *ngIf="!showFilename">
    <mat-icon>get_app</mat-icon> Download file
  </div>
  
</a>
<span *ngIf="!filable.file">
  <no-data>Not defined</no-data>
</span>