import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbDropdownModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { MaterialModule } from "layout/material.module";
import { ImportExportService } from "./import-export.service";
import { SecurePipe } from "./secure.pipe";
import { ThumbnailService } from "./thumbnail.service";
import { ThumbnailgeneratorComponent } from "./thumbnailgenerator/thumbnailgenerator.component";

@NgModule({
  imports: [
    CommonModule,
    NgbModalModule,
    NgbDropdownModule,
    MaterialModule,
    FormsModule,
  ],
  declarations: [ThumbnailgeneratorComponent, SecurePipe],
  providers: [ThumbnailService, ImportExportService, SecurePipe],
})
export class ThumbGeneratorModule {}
