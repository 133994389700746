import { Pipe, PipeTransform } from '@angular/core';
import { ParamScheme } from '@MOSAR/mosar-dashboard-datamodel';


@Pipe({
  name: 'propertiesFilter'
})
export class PropertiesFilter implements PipeTransform {
  private removed = ["label", "id", "image"];
  private kept = [];

  public addRemovedProperty(name: string) : PropertiesFilter {
    this.removed.push(name);
    return this;
  }

  public keepOnly(name: string) : PropertiesFilter {
    this.kept.push(name);
    return this;
  }

  transform(properties: ParamScheme[]): any {
    let result : ParamScheme[] = [];
    let onlyKeepMode = (this.kept.length > 0 ? true : false);

    for (let prop of properties) {
      if (onlyKeepMode) {
        if (this.kept.includes(prop.name)) {
          result.push(prop);
        }
      }
      else {
        if (this.removed.includes(prop.name) == false) {
          result.push(prop);
        }
      }
    }
    return result;
  }

}
