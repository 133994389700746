import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CollectionService } from "../../collection/collection.service";
import { SecurePipe } from "../secure.pipe";

// fileable => that can hold a file
export interface Filable {
  filename: string;
  file: string;
}

@Component({
  selector: "app-attachment-view",
  templateUrl: "./attachment-view.component.html",
  styleUrls: ["./attachment-view.component.scss"],
})
export class AttachmentViewComponent implements OnInit {
  @Input("readonly") readonly = false;
  @Input("filable") filable: Filable;
  @Input("showFilename") showFilename = false;
  @Output() updateFileEmitter = new EventEmitter();

  storeUrl: string;

  constructor(
    private restService: CollectionService,
    private secure: SecurePipe
  ) {}

  ngOnInit() {
    this.storeUrl = this.restService.getFileStoreUrl();
  }

  updateFile(file) {
    this.filable.file = file.id;
    this.filable.filename = file.name;
    this.updateFileEmitter.emit(this.filable);
  }

  extractName(fileId: string) {
    if (!fileId || fileId.length == 0) return "";
    if (this.filable.filename) {
      let cleanFilename = this.filable.filename.replace(/\//g, "_");
      return encodeURIComponent(cleanFilename);
    }
    return fileId.substring(fileId.indexOf("_") + 1);
  }

  downloadImg(file) {
    const extractedFileName = this.extractName(file);
    const url = this.storeUrl + "/" + file + "?name=" + extractedFileName;
    this.secure.transform(url, file).subscribe((res: any) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.setAttribute("download", extractedFileName);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
}
