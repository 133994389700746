import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppStatusService } from "../../../service/app-status/app-status.service";
import { Location } from "@angular/common";
import { ContainerService } from "../container.service";
import { Router, NavigationEnd } from "@angular/router";
import { take } from "rxjs";
import { ConstantsService } from "service/scenariomanager-api/constants.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  pathItems = [];
  pathItemsLabels = [];
  isHomePage: boolean = false;
  isSelectedMenuUseCase = false;
  isSelectedMenuTestProtocol = false;
  isSelectedMenuScenario = false;
  isSelectedMenuCollection = false;
  initialPath;

  constructor(
    public appStatus: AppStatusService,
    private location: Location,
    private containerService: ContainerService,
    private constantService: ConstantsService,
    private router: Router,
    private changeDedectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Reaction to routing events: update sections and breadcrumb display
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.updateCurrentSelectedSection(this.location.path());
        this.pathItems = this.getPathItems(this.location.path());
        this.initInitialPath();
        for (
          let itemIndex = 0;
          itemIndex < this.pathItems.length;
          itemIndex++
        ) {
          this.updatePathItemLabelAsync(
            this.pathItems[itemIndex].label,
            itemIndex
          );
        }
      }
    });
  }

  private initInitialPath() {
    this.initialPath = "home";
    if (this.pathItems.length > 0) {
      switch (this.pathItems[0].route) {
        case "/drivingautomationsystem":
          this.initialPath += "/usecase";
          break;
        case "/scenarios":
          break;
        case "/collections":
          this.initialPath = "collections";
          break;
        default:
          this.initialPath += this.pathItems[0].route;
          break;
      }
    }
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  /**
   * Construct the path tree from the current location
   */
  private getPathItems(locationPath: string) {
    let pathStack = locationPath.split("?")[0].split("/");
    pathStack = pathStack.filter((p) => p != "home");
    pathStack.shift();
    return pathStack.map((pathItem, itemIndex) => {
      return {
        route: "/" + pathStack.slice(0, itemIndex + 1).join("/"),
        label: pathItem,
      };
    });
  }

  /**
   * Update flags indicating which section is currently selected
   * @param currentPath Current routing path
   */
  private updateCurrentSelectedSection(currentPath: string) {
    this.isSelectedMenuScenario = false;
    this.isSelectedMenuTestProtocol = false;
    this.isSelectedMenuUseCase = false;
    this.isSelectedMenuCollection = false;
    if (currentPath == "/home" || currentPath.includes("scenario")) {
      this.isSelectedMenuScenario = true;
    } else if (currentPath.includes("testprotocol")) {
      this.isSelectedMenuTestProtocol = true;
    } else if (
      currentPath.includes("usecase") ||
      currentPath.includes("system")
    ) {
      this.isSelectedMenuUseCase = true;
    } else if (currentPath.includes("collections")) {
      this.isSelectedMenuCollection = true;
    }

    if (currentPath.includes("/home") || currentPath == "/collections") {
      this.isHomePage = true;
    } else {
      this.isHomePage = false;
    }
  }

  /**
   * Change the label of some path items to be more human relevant
   * @param itemValue
   * @param pathItemIndex
   */
  private updatePathItemLabelAsync(itemValue, pathItemIndex) {
    // Change the label of path items that are a resource identifier by the name of the referenced resource
    const previousItemLabel = this.pathItems[pathItemIndex - 1]
      ? this.pathItems[pathItemIndex - 1].label
      : null;
    const collectionName =
      previousItemLabel == "scenarios" ? "scenario" : previousItemLabel;
    if (
      previousItemLabel &&
      this.constantService
        .getCollectionsAndLibrariesInfo()
        .map((lib) => lib.nameApi)
        .includes(collectionName)
    ) {
      const container = this.containerService.getLocalContainerById(
        collectionName,
        itemValue
      );
      if (container) {
        this.pathItemsLabels[pathItemIndex] = container.resources[0].name;
      } else if (typeof itemValue === "string" && itemValue !== "search") {
        this.containerService
          .getContainerById(collectionName, itemValue)
          .pipe(take(1))
          .subscribe((resp) => {
            this.pathItemsLabels[pathItemIndex] = resp.resources[0].name;
          });
      } else if (itemValue === "search") {
        this.pathItemsLabels[pathItemIndex] = "search";
      }
    } else {
      this.pathItemsLabels[pathItemIndex] = itemValue;
    }

    this.applyLabelWorkarounds(pathItemIndex);
  }

  private applyLabelWorkarounds(pathItemIndex) {
    const collection = this.constantService
      .getCollectionsAndLibrariesInfo()
      .find(
        (collection) =>
          collection.nameApi === this.pathItemsLabels[pathItemIndex]
      );
    if (collection) {
      this.pathItemsLabels[pathItemIndex] = collection.label;
    }

    // Suffix upper level of sections with "containers"
    if (
      this.pathItemsLabels[pathItemIndex] == "driving automation system" ||
      this.pathItemsLabels[pathItemIndex] == "usecase" ||
      this.pathItemsLabels[pathItemIndex] == "testprotocol" ||
      this.pathItemsLabels[pathItemIndex] == "scenarios"
    ) {
      this.pathItemsLabels[pathItemIndex] += " containers";
    }
  }
}
