import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private cache = {};

  addCachedFile(file, id) {
    this.cache[id] = file;
  }

  getCachedFile(id) {
    return this.cache[id];
  }
}
