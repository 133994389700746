<ng-container *ngIf="!displayAsBadge">
  <ng-container *ngIf="displaySmallBlack">
    <small><b>{{ pathLabel ? pathLabel : path }}</b></small>
  </ng-container>
  <ng-container *ngIf="!displaySmallBlack">
    {{ pathLabel ? pathLabel : path }}
  </ng-container>

</ng-container>

<ng-container *ngIf="displayAsBadge && (path || pathLabel)">
  <span class="badge badge-pill badge-light text-secondary default-cursor" triggers="hover" container="body" ngbTooltip="{{ pathLabel ?  pathLabel : path }}">
    <i>ref.</i>
  </span>
  <mat-icon *ngIf="!isPathValid" container="body" class="text-warning" 
        ngbTooltip="The referenced parameter cannot be found" triggers="hover">
    warning
  </mat-icon>
</ng-container>