<ng-select appendTo="body"  [(ngModel)]='model' (change)="select.emit(model)" [compareWith]="compareFn" [placeholder]='placeholder'  style="overflow-x: auto;">
    <ng-option *ngFor="let item of paths" [value]="item.fullPath">
        <span *ngIf="!scenarioPossibleParameters" triggers="hover" ngbTooltip="{{ item.fullPath }}" container="body">{{ item.label }}</span>
        <app-path-reference-badge 
            *ngIf="scenarioPossibleParameters"
            [path]="item.fullPath"
            [contextModel]="context"
            [displayAsBadge]="false"
            [contextPossibleParameters]="scenarioPossibleParameters"
            [displaySmallBlack]="false">
            </app-path-reference-badge>
    </ng-option>
</ng-select>