import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch'
})
export class FilterSearchPipe implements PipeTransform {

  transform(values: Array<any>, search: string = '', filterName: string): Array<any> { // maybe add the argument of the field infrastructures or actors
      let filtered = values.map((element) => {
          return {...element, [filterName]: element[filterName].filter((subElement) => subElement.name?.toLowerCase()?.includes(search?.toLowerCase()))}
      })

      let testFiltered = []
      filtered.forEach((elm)=>{
        if(elm[filterName]?.length>0) {
          let newInfras = this.getAllElements(elm, values, filterName);
          elm[filterName] = newInfras;
          testFiltered.push(elm);
        }
      })
      return testFiltered;
  }

  getAllElements(filteredElm, initialValues, filterName){
    let initialValueElement = initialValues.find(inf => inf.container.id == filteredElm.container.id)
    //for every element in filteredElm.infrastructures find list of parents and add it to filteredElm.infrastructures
    let filteredInfras = filteredElm[filterName];
    let initialInfras = initialValueElement[filterName];

    let set = new Set(filteredInfras);
    for(let i=0; i<filteredInfras.length; i++){
        let single = filteredInfras[i];
        if (single.parent != 0 || !single.parent){
          let res = this.findRestParentsOf(single.parent, initialInfras);
          res.forEach((item) => {
            set.add(item);
          })
        }
    }
    //convert set to array and return
    let finalArray = Array.from(set);
    return finalArray
  }

  findRestParentsOf(idParent, initialInfras){
    let arr = []
    while(idParent!=0 && (idParent!= null)  ){
      let parentElm = initialInfras.find((item) => item.id == idParent);
      arr.push(parentElm)
      parentElm.parent? idParent=parentElm.parent : idParent=null

    }
    return arr;
  }

}
