import { Injectable } from "@angular/core";
import { CollectionService } from "app/collection/collection.service";
import { isNumber } from "lodash-es";
import { SVGToolkitService } from "./thumbnailgenerator/svg-toolkit-service";
import { ThumbnailCurve } from "./thumbnailgenerator/thumbnail-items/thumbnail-curve/thumbnail-curve-model";
import { ThumbnailImage } from "./thumbnailgenerator/thumbnail-items/thumbnail-image/thumbnail-image-model";
import { ThumbnailLine } from "./thumbnailgenerator/thumbnail-items/thumbnail-line/thumbnail-line-model";
import { ThumbnailText } from "./thumbnailgenerator/thumbnail-items/thumbnail-text/thumbnail-text-model";
import { Thumbnail } from "./thumbnailgenerator/thumbnail-model";

@Injectable({
  providedIn: "root",
})
export class ImportExportService {
  constructor(
    private svgToolkit: SVGToolkitService,
    private collectionService: CollectionService
  ) {}

  public exportThumbnail(thumbnail: Thumbnail) {
    return {
      size: thumbnail.size,
      ratio: thumbnail.ratio,
      items: thumbnail.getItems().map((item) => {
        let newItem: any = item.copy();
        delete newItem.onItemChange;
        delete newItem.onChange;
        if (item instanceof ThumbnailImage) {
          newItem.type = "image";
          delete newItem.source;
        }
        if (item instanceof ThumbnailCurve) newItem.type = "curve";
        if (item instanceof ThumbnailLine) newItem.type = item.getType();
        if (item instanceof ThumbnailText) newItem.type = "text";

        Object.keys(newItem).map((key) =>
          isNumber(newItem[key])
            ? (newItem[key] = Math.round(newItem[key]))
            : newItem[key]
        );
        return newItem;
      }),
    };
  }

  public importThumbnail(data: any) {
    return new Promise<Thumbnail>((resolve) => {
      this.importThumbnailItems(data.items).then((items) =>
        resolve(
          new Thumbnail({
            size: data.size,
            ratio: data.ratio,
            items: items.filter((item) => item),
          })
        )
      );
    });
  }

  private importThumbnailItems(items: any) {
    let newItems = items.map((item, i) => {
      if (item.type == "image") {
        return this.createThumbnailImage(item);
      }
      if (item.type == "curve") return this.createThumbnailCurve(item);
      if (
        item.type == "line" ||
        item.type == "dline" ||
        item.type == "arrowLine"
      )
        return this.createThumbnailLine(item);
      if (item.type == "text") return this.createThumbnailText(item);
    });
    return Promise.all(newItems);
  }

  private createThumbnailImage(item) {
    return new Promise((resolve, reject) => {
      this.svgToolkit.urlToBase64(
        `${this.collectionService.getStepImageStoreUrl()}/${item.url}`,
        (source) => {
          resolve(new ThumbnailImage({ ...item, source, url: item.url }));
        },
        (err) => {
          resolve(null);
        }
      );
    });
  }

  private createThumbnailCurve(item) {
    return new Promise((resolve) => resolve(new ThumbnailCurve(item)));
  }

  private createThumbnailLine(item) {
    return new Promise((resolve) => resolve(new ThumbnailLine(item)));
  }

  private createThumbnailText(item) {
    return new Promise((resolve) => resolve(new ThumbnailText(item)));
  }
}
