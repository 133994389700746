import { Injectable } from "@angular/core";
import {
  DataModelService,
  ParamScheme,
} from "@MOSAR/mosar-dashboard-datamodel";
import { ConfigureService } from "service/ng4-configure/ng4-configure.service";

const DOCUMENTATION_ROOT_LINK = "MOSAR-Scenario-DataModel-Reference.html#_";
const SIMULATION_GUIDE_ROOT_LINK = "MOSAR-Simulation-Guide.html#_";

/**
 * Service that create documentation link for a given property.
 * Each methods return an URL or '' if no link can be computed.
 * FIXME: To move in DataModel lib ?
 */
@Injectable({
  providedIn: "root",
})
export class DocumentationLinkService {
  constructor(
    private dataModel: DataModelService,
    private configService: ConfigureService
  ) {}

  public fromPropertyScheme(objectClass: string, propertyScheme: ParamScheme) {
    const objectScheme = this.dataModel.getClassProperty(objectClass);
    return this.fromObjetAndPropertySchemes(objectScheme, propertyScheme);
  }
  generateLink(link) {
    return (
      this.configService.config.documentationUrl +
      SIMULATION_GUIDE_ROOT_LINK +
      link
    );
  }

  public fromObjetAndPropertySchemes(
    objectScheme: ParamScheme,
    propertyScheme: ParamScheme
  ) {
    if (objectScheme) {
      let docClass = objectScheme.className;
      if (
        propertyScheme.type == "object" &&
        propertyScheme["viewType"] != "multilevel" &&
        propertyScheme.className != "primitive"
      )
        docClass = propertyScheme.className;
      return (
        this.configService.config.documentationUrl +
        DOCUMENTATION_ROOT_LINK +
        docClass.toLowerCase()
      );
    }
    return "";
  }
}
