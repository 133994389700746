import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigureService } from 'service/ng4-configure/ng4-configure.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  groups = new BehaviorSubject<Array<string>>([]);
  groups$ = this.groups.asObservable();

  constructor(private http: HttpClient,
    private configService: ConfigureService) { }

  getAdministrablesGroups() {
    return this.http.get<Array<string>>(`${this.configService.config.apiUrl}/group`).pipe(map(resp => { this.groups.next(resp); return resp; }));
  }

  getUsers(group: string) {
    return this.http.get<Array<any>>(`${this.configService.config.apiUrl}/group/${group}/user`);
  }

  addUsers(group: string, users: Array<any>) {
    return this.http.post(`${this.configService.config.apiUrl}/group/${group}/user`, users);
  }

  removeUsers(group: string, user: any) {
    return this.http.request('delete', `${this.configService.config.apiUrl}/group/${group}/user`, { body: [user] });
  }

}
