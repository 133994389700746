import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataModelService } from '@MOSAR/mosar-dashboard-datamodel';
import { ConfigureService } from 'service/ng4-configure/ng4-configure.service';
import * as jsyaml from 'js-yaml';
import { Observable } from 'rxjs';

declare const SwaggerUIBundle: any;
declare const SwaggerUIStandalonePreset: any;

export enum ApiRef {
  SCENARIO_MANAGER = 'scenariomanager',
  STATISTICS = 'statistics'
}


@Component({
  selector: 'app-swagger',
  template: `<div id="swagger-ui"></div>`
})
export class SwaggerComponent implements OnInit {

  constructor(
    private dataModelService: DataModelService,
    private configService: ConfigureService,
    private route: ActivatedRoute,
    private http: HttpClient) {
    }

  ngOnInit(): void {
    this.loadSwaggerSpec('');

    this.route.params.subscribe(params => {
      switch (params.apiRef) {
        case ApiRef.STATISTICS:
          this.loadStatisticsSwagger();
          break;

        case ApiRef.SCENARIO_MANAGER:
        default:
          this.loadSwaggerSpec(this.dataModelService.getSwaggerJson());
          break;
      }
    });
  }

  loadSwaggerSpec(swaggerSpec) {
    const ui = SwaggerUIBundle({
      spec: swaggerSpec,
      oauth2RedirectUrl: window.location.origin+"/assets/oauth2-redirect.html",
      dom_id: '#swagger-ui',
      deepLinking: true,
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIStandalonePreset
      ],
      plugins: [
        SwaggerUIBundle.plugins.DownloadUrl
      ],
      layout: "StandaloneLayout"
    })
    // End Swagger UI call region

    this.http.get<any>('/assets/keycloak.json').subscribe(kc => {
      ui.initOAuth({
        clientId: kc.resource,
        realm: kc.realm,
        appName: "swagger",
        scopeSeparator: " ",
        additionalQueryStringParams: {}
      })
    })
  }

  private loadStatisticsSwagger() {
    const url = `${this.configService.config.statisticsUrl}/util/swagger`;
    this.getFile(url).subscribe(
      (data) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
          data = jsyaml.safeLoad(e.target['result'].toString());
          this.loadSwaggerSpec(data);
        });
        reader.readAsText(data as Blob);
      }
    );
  }

  private getFile(url: string) {
    return new Observable((observer) => {
      this.http
        .get(url, {
          responseType: 'blob',
          headers: { Accept: 'application/*' },
        })
        .toPromise()
        .then((res) => observer.next(res))
        .catch((e) => observer.error(e));
    });
  }
}
