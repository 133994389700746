import { uptadeFilters } from "./../actions/shared.action";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { resetFilters } from "../actions/shared.action";
import { FiltersState, selectFilters } from "../state/shared.state";

@Injectable({
  providedIn: "root",
})
export class StorageSharedService {
  constructor(private filterReducer: Store<FiltersState>) {}
  getFilters(): Observable<FiltersState> {
    return this.filterReducer.select(selectFilters);
  }
  uptadeFilters(filter: any) {
    this.filterReducer.dispatch(uptadeFilters(filter));
  }

  resetFilters() {
    this.filterReducer.dispatch(resetFilters());
  }
}
