import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ConfigureService } from "../../service/ng4-configure/ng4-configure.service";
import { DataModelService } from "@MOSAR/mosar-dashboard-datamodel";
import { IPagination } from "../../model/pagination";
import { IFilter } from "../../model/filter";
import { flatMap, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ScenarioService {
  private lastRetrievedScenarioTree = null; // Store the result of the last scenario tree retrieved from the backend

  constructor(
    public http: HttpClient,
    public configService: ConfigureService,
    private dataModel: DataModelService
  ) {}

  private handleError(method: String) {
    return (err: any) => {
      if (err.constructor.name === "String")
        if (
          err.includes(
            "The concrete scenarios of this scenario are already being computed. Please wait."
          )
        )
          return err;
      return throwError(err);
    };
  }

  createScenario(name, parentId, container, scenarioType?) {
    return this.dataModel.getModelTemplate("scenario").pipe(
      flatMap((scenarioTemplate: any) => {
        let scenario = {
          ...scenarioTemplate,
          name,
          type: scenarioType,
          metadata: { dataModelVersion: this.dataModel.getDataModelVersion() },
        };

        let createScenario = this.http.post(
          `${this.configService.config.apiUrl}/scenario`,
          {
            scenario: scenario,
            comment: "",
          },
          { params: { container } }
        );

        if (parentId) {
          scenario.parent = parentId;
          return this.dataModel.getModelTemplate("step").pipe(
            flatMap((stepTemplate: any) => {
              if (!stepTemplate.name) stepTemplate.name = `Step 1`;
              scenario.steps = [stepTemplate];
              scenario.actors = [];
              return createScenario;
            })
          );
        } else return createScenario;
      })
    );
  }

  duplicateScenario(
    id,
    container,
    newParent,
    copyParents,
    copyChildren,
    move,
    snapshot
  ) {
    let params = {
      container,
      newParent,
      copyParents,
      copyChildren,
      move,
      snapshot,
    };
    return this.http.post(
      `${this.configService.config.apiUrl}/scenario/${id}/duplicate`,
      null,
      { params }
    );
  }

  getScenarioById(id): Observable<any> {
    return this.http.get(`${this.configService.config.apiUrl}/scenario/${id}`);
  }

  findScenarios(
    pagination: IPagination = null,
    container: string,
    filters: IFilter[] = null
  ) {
    const parameters: any = {
      // page: pagination ? pagination.page : null,
      // rowPerPage: pagination ? pagination.rowPerPage : null,
      filters: JSON.stringify(filters) ?? undefined,
      container: container,
    };

    return this.http.get<any>(`${this.configService.config.apiUrl}/scenario`, {
      params: parameters,
    });
  }

  findScenariosTree(
    pagination: IPagination = null,
    container: string,
    filters: IFilter[] = null,
    scenarioFamily?
  ) {
    const parameters: any = {
      page: pagination ? pagination.page : null,
      rowPerPage: pagination ? pagination.rowPerPage : null,
      filters: JSON.stringify(filters) ?? undefined,
      container: container,
    };
    if (scenarioFamily) parameters.scenarioFamily = scenarioFamily;

    let observable = this.http
      .get<any>(`${this.configService.config.apiUrl}/scenario/tree`, {
        params: parameters,
      })
      .pipe(
        map((r) => {
          this.lastRetrievedScenarioTree = r.resources;
          return r;
        })
      );
    return observable;
  }

  /**
   * Return the IDs (array of to values) of scenarios that appeared before and after the specified scenario in the scenario list.
   * Return [null, null] if none or if it cannot be retrieved within the context.
   */
  public getScenarioNeighbors(scenarioId, parentId) {
    if (scenarioId && this.lastRetrievedScenarioTree) {
      let siblings =
        parentId == 0
          ? this.lastRetrievedScenarioTree
          : this.findScenarioSiblings(
              scenarioId,
              this.lastRetrievedScenarioTree
            );
      if (siblings) {
        let index = siblings.findIndex(
          (item) => item.resource.id == scenarioId
        );
        let previous = index > 0 ? siblings[index - 1].resource : null;
        let next =
          index + 1 < siblings.length ? siblings[index + 1].resource : null;
        return [previous, next];
      }
    }
    return [null, null];
  }

  private findScenarioSiblings(scenarioId, array) {
    let foundNode = array.find((item) => item.resource.id == scenarioId);
    if (foundNode == undefined) {
      for (let item of array) {
        if ("children" in item) {
          let foundSiblings = this.findScenarioSiblings(
            scenarioId,
            item.children
          );
          if (foundSiblings != null) {
            return foundSiblings;
          }
        }
      }
    }
    return foundNode != undefined ? array : null;
  }

  getScenarioHistory(id) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/scenario/${id}/history`
    );
  }

  deleteScenarios(id, deleteChildren?, deleteChildrenOnly?) {
    let params = deleteChildren ? { deleteChildren, deleteChildrenOnly } : {};
    return this.http.delete(
      `${this.configService.config.apiUrl}/scenario/${id}`,
      { params }
    );
  }

  updateScenarios(scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: "",
    };
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}`,
      scenarioPostBody
    );
  }

  setupScenario(scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: "",
    };
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}/setup`,
      scenarioPostBody
    );
  }

  /* Scenario status method */

  updateScenariosAsync(id, scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: "",
    };
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${id}`,
      scenarioPostBody
    );
  }

  updateScenarioStatusWorkflow(scenario, comment) {
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}/status/workflow`,
      comment
    );
  }

  invalidateScenarioStatus(scenario, comment) {
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}/status/invalidate`,
      comment
    );
  }

  reopenScenario(scenario, comment) {
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}/status/reset`,
      comment
    );
  }

  feedbackScenario(scenario, comment) {
    return this.http.put(
      `${this.configService.config.apiUrl}/scenario/${scenario.id}/status/feedback`,
      comment
    );
  }

  getScenarioAnnotations(id) {
    return this.http.get<any>(
      `${this.configService.config.apiUrl}/scenario/${id}/annotations`
    );
  }

  setScenarioAnnotations(id, annotations: Array<any>) {
    return this.http.put<any>(
      `${this.configService.config.apiUrl}/scenario/${id}/annotation`,
      annotations
    );
  }

  synchronizeScenario(id) {
    return this.http
      .put(
        `${this.configService.config.apiUrl}/scenario/${id}/synchronize`,
        null
      )
      .pipe(catchError(this.handleError("synchronizeScenario")));
  }

  exportScenario(id) {
    return this.http.get(
      `${this.configService.config.apiUrl}/export/scenarios`,
      {
        /* FIXME: Uncomment this code -> it seems to override the header set by the keycloak interceptor
        headers : {
          Accept: 'application/*',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },*/
        responseType: "blob",
        params: { scenariosToExport: id },
      }
    );
  }

  updateSnapshot(id) {
    return this.http
      .put(
        `${this.configService.config.apiUrl}/scenario/${id}/update-snapshot`,
        null
      )
      .pipe(catchError(this.handleError("updateSnapshot")));
  }
}
