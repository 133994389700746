import { createSelector } from "@ngrx/store";

export interface FiltersState {}

export interface AppState {
  filters: FiltersState;
}

export const selectFeature = (state: AppState) => state.filters;

export const selectFilters = createSelector(
  selectFeature,
  (state: FiltersState) => state
);
