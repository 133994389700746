import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FilterNode } from "../filters-dialog.component";

@Component({
  selector: "app-filters-dialog-input-parameter",
  templateUrl: "./filters-dialog-input-parameter.component.html",
  styleUrls: ["./filters-dialog-input-parameter.component.scss"],
})
export class FiltersDialogInputParameterComponent {
  @Input() filtersTree: FilterNode;
  @Input() currentFilter;
  @Input() nameFilter = "";
  @Input() flatView: boolean = false;
  @Output() onCurrentFilterChange = new EventEmitter(true);

  setCurrentFilter(filter) {
    this.onCurrentFilterChange.emit(filter);
  }

  fireCurrentFilterChanged(filter) {
    this.onCurrentFilterChange.emit(filter);
  }

  nodeMatchFilter(filtersTree) {
    const lowerNameFilter = this.nameFilter.toLowerCase();
    let match = false;
    for (let node of filtersTree.nodes) {
      if (this.nodeMatchFilter(node)) {
        match = true;
      }
    }
    if (
      filtersTree.title &&
      filtersTree.title.toLowerCase().includes(lowerNameFilter)
    )
      return true;
    for (let filter of filtersTree.properties) {
      if (
        filter.name.toLowerCase().includes(lowerNameFilter) ||
        filter.title.toLowerCase().includes(lowerNameFilter)
      ) {
        match = true;
      }
    }
    return match;
  }

  propertyMatchFilter(property, filtersTree) {
    const lowerNameFilter = this.nameFilter.toLowerCase();
    if (
      filtersTree.title &&
      filtersTree.title.toLowerCase().includes(lowerNameFilter)
    )
      return true;
    return (
      property.name.toLowerCase().includes(lowerNameFilter) ||
      property.title?.toLowerCase().includes(lowerNameFilter)
    );
  }

  deleteStar(name: string) {
    return name.replace("*", "");
  }
}
