<ng-container>

  <!-- Multiple if statement prefered over switch so we can reuse blocks -->
  <ng-container *ngIf="currentFilter?.type === 'string'">
    <ng-container *ngIf="currentFilter?.enum; then simpleConstraintsBlock; else stringConstraintsBlock"></ng-container>
  </ng-container>

  <ng-container *ngIf="currentFilter?.type === 'boolean'; then simpleConstraintsBlock"></ng-container>

  <ng-container *ngIf="currentFilter?.type === 'integer'; then advancedConstraintsBlock"></ng-container>

  <ng-container *ngIf="currentFilter?.type === 'multiLevelParam'; then advancedConstraintsBlock"></ng-container>

  <ng-container *ngIf="currentFilter?.type === 'array' && !isRef(); then arrayConstraintsBlock"></ng-container>
  
  <ng-container *ngIf="currentFilter?.type === 'custom-enum'; then customConstraintsBlock"></ng-container>

  <ng-container *ngIf="isRef(); then objectConstraintsBlock"></ng-container>

  <ng-container *ngIf="!currentFilter; then disabledConstraintBlock"></ng-container>

  <!-- Declare blocks to be reused -->
  <ng-template #stringConstraintsBlock>
    <ng-select appendTo="body" [items]="stringConstraints" [(ngModel)]="currentConstraint" [disabled]="!currentFilter" ></ng-select>
  </ng-template>

  <ng-template #simpleConstraintsBlock>
    <ng-select appendTo="body" [items]="simpleConstraints" [(ngModel)]="currentConstraint" [disabled]="!currentFilter" ></ng-select>
  </ng-template>

  <ng-template #advancedConstraintsBlock>
    <ng-select appendTo="body" [items]="advancedConstraints" [(ngModel)]="currentConstraint" [disabled]="!currentFilter" ></ng-select>
  </ng-template>

  <ng-template #customConstraintsBlock>
    <ng-select appendTo="body" [items]="customConstraints" [(ngModel)]="currentConstraint" [disabled]="!currentFilter" ></ng-select>
  </ng-template>

  <ng-template #objectConstraintsBlock>
    <ng-select appendTo="body" [items]="customObjectConstraints" [(ngModel)]="currentConstraint" [disabled]="!currentFilter" ></ng-select>
  </ng-template>

  <ng-template #arrayConstraintsBlock>
    <ng-container *ngIf="currentFilter?.items?.type === 'string'">
      <ng-container *ngIf="currentFilter?.enum; then simpleConstraintsBlock; else stringIntermidiateConstraintsBlock"></ng-container>
      <ng-template #stringIntermidiateConstraintsBlock>
        <ng-container *ngIf="isCustomEnum(); then customConstraintsBlock; else stringConstraintsBlock"></ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="currentFilter?.items?.type === 'integer'; then advancedConstraintsBlock"></ng-container>
    
  </ng-template>

  <ng-template #disabledConstraintBlock>
    <input type="text" class="form-control" disabled>
  </ng-template>
</ng-container>