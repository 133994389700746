import { Injectable } from "@angular/core";
import { ContainerService } from "app/shared/container.service";
import { ContainerInfo } from "model/collection";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ConstantsService } from "service/scenariomanager-api/constants.service";

@Injectable({
  providedIn: "root",
})
export class ContainerResolver {
  constructor(
    private constantsService: ConstantsService,
    private containerService: ContainerService
  ) {}
  resolve(): Observable<
    { containerInfo: ContainerInfo; containers: any[]; err: string }[]
  > {
    // Get containers
    const containers$ = (
      containerInfo: ContainerInfo
    ): Observable<{
      containerInfo: ContainerInfo;
      containers: any[];
      err: string;
    }> =>
      this.containerService.getContainers(containerInfo.nameApi).pipe(
        map((r) => ({
          containerInfo,
          err: "",
          containers: r.map((c) => c.resources[0]),
        })),
        catchError((err) => of({ containerInfo, containers: [], err }))
      );

    return forkJoin(this.constantsService.getLibrariesInfo().map(containers$));
  }
}
