import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collectionParent'
})
export class CollectionParentPipe implements PipeTransform {

  transform(collections: Array<any>, parentId: any): any {
    if (!collections) return [];
    return collections.filter(
      col => (
        (col['parent'] && col['parent'] == parentId) || 
        (!col['parent'] && parentId == 0)
      )
    );
  }

}
