<div class="d-flex align-items-center element-item position-relative" [style.marginLeft.px]="10*level">
    <div (click)="$event.stopPropagation();showChildren = !showChildren" class="chevron position-absolute" *ngIf="element.children">
        <mat-icon [style.transform]="showChildren ? 'rotate(90deg)' : ''">chevron_right</mat-icon>
    </div>
    <button class="dropdown-item form-control form-control-sm" [disabled]="isUsed(element.id)"
        (click)="select(element)">
        <span class="item">
            {{element.description}}
        </span>
    </button>
</div>
<ng-container *ngIf="showChildren ">
    <ng-container *ngFor="let child of element.children">
        <app-custom-enum-item [element]="child" [level]="level+1" (selected)="select($event)"
            [usedelements]="usedelements" [usedelements]="usedelements">
        </app-custom-enum-item>
    </ng-container>
</ng-container>