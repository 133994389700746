import { Component } from "@angular/core";
import { ConfigureService } from "service/ng4-configure/ng4-configure.service";

@Component({
  selector: "app-root",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent {
  supportEmail;

  constructor(private configService: ConfigureService) {
    this.supportEmail = this.configService.config.supportEmail;
  }
}
