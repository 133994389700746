<div class="modal-body">
  <ng-container *ngFor="let user of users; let i = index">
    <form (submit)="addMore($event)">
      <div class="input-group mb-3">
        <input type="text" class="form-control" [placeholder]="'Type username'+(withGroups?' or @group':'')" id="{{ 'user' + i }}" name="{{ 'user' + i }}"
          [(ngModel)]="users[i].value" [ngClass]="{'border border-danger': !users[i].exists || (!withGroups && users[i].type == 'GROUP')}">
        <div class="input-group-append">
          <button class="btn btn-dark" type="submit">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="modal-footer bg-light">
  <button type="button" class="btn" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-success" (click)="submit()">
    Add
  </button>
</div>