import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-add-collection-item-dropdown",
  templateUrl: "./add-collection-item-dropdown.component.html",
  styleUrls: ["./add-collection-item-dropdown.component.scss"],
})
export class AddCollectionItemDropdownComponent {
  @Input("availableCollections") availableCollections: Array<any>;
  @Input("collectionName") collectionName: string;
  @Input("usedCollections") usedCollections = [];
  @Input("label") label: string;
  @Input("icon") icon: string;
  @Input("disabled") disabled: boolean;
  @Input("isBehaviorCollection") isBehaviorCollection = false;

  @Output("onSelectCollection") onSelectCollection = new EventEmitter();

  search = "";
}
