import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss']
})
export class NewUserDialogComponent implements OnInit {

  users = [{ value: '', exists: true }];

  @Input('withGroupe') withGroups;
  @Output('onSubmit') onSubmit = new EventEmitter<Array<any>>();

  constructor(public activeModal: NgbActiveModal, private utils: UtilsService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  addMore(ev) {
    ev.preventDefault();
    this.users.push({ value: '', exists: true });
    setTimeout(() => {
      let lastElement: HTMLInputElement = document.querySelector('#user' + (this.users.length - 1));
      if (lastElement)
        lastElement.focus();
    })
  }

  submit() {
    let users = this.users.map(u => u.value.trim()).filter(u => u);
    this.utils.identifiersExists(users.map(u => /^@/i.test(u) ? u.substring(1) : u)).subscribe(resp => {
      this.users = resp.map((u, i) => {
        return {
          value: /^@/i.test(this.users[i].value) ? '@' + u.identifier : u.identifier,
          exists: u.exists,
          type: u.type
        }
      })
      if (!resp.find(u => !u.exists || (!this.withGroups && u.type == 'GROUP'))) {
        this.onSubmit.emit(this.users.map(u => u.value).filter(u => u));
        this.activeModal.dismiss();
      }
    })
  }

}
