import { ThumbnailItem } from '../thumbnail-item-model';

export class ThumbnailText extends ThumbnailItem {

    protected txt: string;
    protected color: string;

    public constructor(item?) {
        super(item);
        if (item) {
            this.txt = item.txt;
            this.color = item.color;
        }
        else {
            this.txt = "double click to change text";
            this.color = "black";
        }
    }

    public copy() {
        let temp = new ThumbnailText({ ...this });
        return temp;
    }

    public getText() {
        return this.txt;
    }

    public getColor() {
        return this.color;
    }

    public setColor(color: string) {
        this.onChange.emit(this);
        this.color = color;
        this.onItemChange.emit(this);
    }

    public setText(txt: string) {
        this.onChange.emit(this);
        this.txt = txt;
        this.onItemChange.emit(this);
    }

}