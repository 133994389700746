<div class="modal-header bg-dark text-light">
  <span class="modal-title">
    <mat-icon>perm_media</mat-icon>&nbsp;
    <span>Profile</span>
  </span>
  <button type="button" class="close" aria-label="Close" (click)="modalRef.dismiss('close')">
    <span aria-hidden="true" class="text-light">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-center">
    <h4>{{ profile.lastName | uppercase }} {{ profile.firstName | titlecase }}</h4>
    <span>{{ profile.email }}</span>
    <button class="btn btn-link btn-block" (click)="keycloakService.getKeycloakInstance().accountManagement()">Edit Profile</button>
  </div>
  <h4>Groups</h4>
  <div *ngFor="let group of profile.groups" class="alert alert-success" role="alert">
    <span>
      <mat-icon>group</mat-icon>
    </span>
    <span>
      @ <b>{{ getGroupName(group) }}</b>&nbsp;
    </span>
  </div>
</div>
<div class="modal-footer bg-light">
  <button type="button" class="btn" (click)="modalRef.dismiss()">
    Close
  </button>
</div>