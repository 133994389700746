import { ThumbnailItem } from '../thumbnail-item-model';


export class ThumbnailLine extends ThumbnailItem {
    protected type: string;
    protected color: string;


    public constructor(item) {
        super(item);
        this.type = item.type;
        this.color = item.color || "#3fa5f0";


    }

    public copy() {
        let temp = new ThumbnailLine({ ...this });
        return temp;
    }


    public getType() {
        return this.type;
    }

    public getColor() {
        return this.color;
    }

    public setColor(color: string) {
        this.onChange.emit(this);
        this.color = color;
        this.onItemChange.emit(this);
    }

}
