import { createReducer, on } from "@ngrx/store";
import {
  getFilters,
  uptadeFilters,
  resetFilters,
} from "../actions/shared.action";

export const initialState = {
  filters: null,
};

export const FilterReducer = createReducer(
  initialState,
  on(uptadeFilters, (_, filters) => filters),
  on(getFilters, (state) => state),
  on(resetFilters, () => {
    return { filters: null };
  })
);
