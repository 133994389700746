<ng-container>
  <div class="modal-header bg-dark text-light">
    <span class="modal-title">
      <mat-icon>filter_list</mat-icon>&nbsp;
      <span>{{ title }}</span>
    </span>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true" class="text-light">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHtml] = "content">
    </p>
    <div class="form-group" *ngIf="hasComment">
      <label for="comment">Comment:</label>
      <textarea class="form-control" rows="5" [(ngModel)]="comment" placeholder="Please provide a comment for your action."></textarea>
    </div>
  </div>
  <div class="modal-footer bg-light">
    <button *ngIf="showCancel" type="button" class="btn" (click)="modalRef.dismiss('dismiss')">
      {{ cancelLabel }}
    </button>
    <button type="button" class="btn btn-success" [disabled]="hasComment && (!comment || comment === '')" (click)="modalRef.close(comment)" id="btnSucessConfirmationDialog">
      {{ submitLabel }}
    </button>
  </div>
</ng-container>