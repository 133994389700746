<header>
    <nav class="navbar navbar-expand-md navbar-dark sticky-top d-flex justify-content-between"
                [ngClass]="{ 'bg-color-1': section == 'scenario', 'bg-color-2': section == 'usecase','bg-color-3': section == 'testprotocol' }">
        <div>
            <img class="logo" [routerLink]="'/home'" src="../assets/images/header-left-logo.png" height="60" />
        </div>
        <div>
            <img class="logo" src="../assets/images/header-center-logo.png" height="60" />
        </div>
        <div class="d-flex flex-row" style="margin-left:auto">
            <div class="navbar-brand ml-auto">ScenarioManager <small>{{configService.config.scenarioManagerVersion}}</small>
            </div>

            <div class="mr-2" *ngIf="service.getWarning()">
                <button type="button" class="btn btn-outline-warning" triggers="hover" container="body" ngbtooltip="Indicarte warning twice session"
                    placement="left" triggers="hover" [ngbTooltip]="service.getWarning()" tooltipClass="tooltip-warning">
                    <mat-icon>warning</mat-icon>
                </button>
            </div>

            <!-- Version of components box -->
            <div class="mr-2">
                <div class="btn-group" role="group">
                    <button id="btnAbout" type="button" triggers="hover" container="body" ngbtooltip="About Scenario Manager"
                        class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <mat-icon>info</mat-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnAbout"
                    style="padding-top: 0px; width: max-content; max-width: 387px">
                        <div class="dropdown-header bg-sysx-lightblue text-light">
                            <mat-icon>info</mat-icon>
                            About
                        </div>
                        <div class="dropdown-body overflow-auto" style="padding: 5%;">
                            <div class="float-right">
                                <a role="button" class="float-right btn btn-outline-primary"
                                    href="{{docBaseUrl}}MOSAR-ReleaseNotes.html" target="_blank">
                                    <mat-icon>description</mat-icon>&nbsp;View release notes
                                </a>
                            </div>
                            <p><strong>Dashboard Version:</strong><br />{{versionInfos?.dashboardVersion}}</p>
                            <p><strong>Statistics Version:</strong><br />{{versionInfos?.statisticsVersion}}</p>
                            <p><strong>DataModel Version:</strong><br />{{versionInfos?.datamodelVersion}}</p>
                            <p><strong>API URL:</strong><br />{{versionInfos?.apiUrl}}</p>
                            <p><strong>API Version:</strong><br />{{versionInfos?.apiVersion}}</p>
                            <p><strong>Backend Version:</strong><br />{{versionInfos?.backendVersion}}</p>
                            <p><strong>Authentication URL:</strong><br />{{versionInfos?.authUrl}}</p>
                        </div>
                    </div>
                
                </div>
            </div>

            <div class="mr-2">
                <div class="btn-group" role="group">
                    <button id="btnGroupDrop1" attr.data-cy="btnGroupDrop1" triggers="hover" container="body" ngbtooltip="Information about current user" type="button"
                        class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <mat-icon>account_circle</mat-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                        <h6 class="dropdown-header">{{ profile.firstName | titlecase }} {{ profile.lastName | uppercase }}
                        </h6>
                        <small class="dropdown-header">{{ profile.email }}</small>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" type="button" (click)="openProfileDialog()">
                            <mat-icon>account_box</mat-icon>
                            <span>Profile</span>
                        </button>
                        <!--button class="dropdown-item" type="button"-->
                        <a class="dropdown-item" href="{{docBaseUrl}}MOSAR-EULA.html" target="_blank">
                            <mat-icon>verified</mat-icon> End User License Agreement
                        </a>
                        <!--/button-->
                        <ng-container *ngIf="groups.length">
                            <hr class="w-100 my-1">
                            <p class="my-1 mx-3">
                                <mat-icon class="mr-2">people</mat-icon>
                                <span>Groups Management</span>
                            </p>
                            <button class="dropdown-item pl-5" type="button" *ngFor="let group of groups"
                                [routerLink]="'group/'+group">
                                <mat-icon>person</mat-icon>
                                <span>{{group}}</span>
                            </button>
                        </ng-container>
                        <hr class="w-100 my-1">
                        <button class="dropdown-item" type="button" (click)="logout()" attr.data-cy="btnLogoutHeader" id="btnLogoutHeader">
                            <mat-icon>power_settings_new</mat-icon>
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>