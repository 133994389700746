import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  ParametersPathService
} from "@MOSAR/mosar-dashboard-datamodel";
@Component({
  selector: "app-jsonpath",
  templateUrl: "./jsonpath.component.html",
  styleUrls: ["./jsonpath.component.scss"],
})
export class JsonpathComponent implements OnInit {
  @Input("context") context: any;
  @Input("model") model: any;
  @Input("placeholder") placeholder: any;
  @Input() disabledPaths: string[] = [];
  @Input() scenarioPossibleParameters;
  @Output("select") select = new EventEmitter<any>();

  paths: Array<any>;

  constructor(private parameterPathService: ParametersPathService) {}

  ngOnInit() {
    this.paths = this.parameterPathService.getAllParameters(this.context, false, true, true).map(param => {
      return {
      path : param.path,
      label: this.parameterPathService.getParameterLabelForHumans(param),
      fullPath: param.path,
      disabled: false}
    });
  }

  compareFn(item1 ,item2) {
    if (item1.includes(".value")) {
      return item1.split(".value")[0] === item2;
    } else {
      return item1 === item2;
    }
  }

}
