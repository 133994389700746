
<ng-template #dialogTemplate let-c="close" let-d="dismiss">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header bg-dark text-light">
      <span class="modal-title">
        <mat-icon>image</mat-icon>
        Select picture
      </span>
      <button type="button" class="close" aria-label="Close" (click)="d('close')">
        <span aria-hidden="true" class="text-light">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="content">
        <ng-content></ng-content>
      </div>
      <label>Picture:</label>
      <small>Please select a PNG file</small>
      <div class="form-group custom-file">
        <label for="picture">Picture</label>
        <input type="file" id="picture" class="custom-file-input" accept="image/*"
              (change)="onFileChange($event)" #fileInput>
        <label class="custom-file-label" for="picture" id="labelPictureFileUpdateDialog">{{fileLabel}}</label>
      </div>
    </div>
    <div class="modal-footer bg-light d-flex justify-content-between">
      <button type="button" class="btn" (click)="removePicture()" id="btnRemovePictureUpdateDialog" >
        No picture
      </button>
      <div>
        <button type="button" class="btn mr-2" (click)="d('cancel')" id="btnCancelPictureUpdateDialog">
          Cancel
        </button>
        <button type="submit" [disabled]="form.invalid || loading" class="btn btn-success ml-2" id="btnOkPictureUpdateDialog">
          Ok <div *ngIf="loading" class="spin-loader spin-loader-sm"></div>
        </button>
      </div>
    </div>
  </form>
</ng-template>
