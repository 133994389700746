import { EventEmitter } from '@angular/core';

export enum ThumbnailItemTFunction {
    ELEMENT, BACKGROUND
}


/**
 * Generic thumbnail element.
 */
export abstract class ThumbnailItem {
    
    public onItemChange = new EventEmitter();
    public onChange = new EventEmitter();

    protected originX : number;
    protected originY : number;
    protected endX : number;
    protected endY : number;
    protected rotation: number;
    protected itemFunction : ThumbnailItemTFunction;

    public constructor(item?) {
        this.itemFunction = (item && item.itemFunction) || ThumbnailItemTFunction.ELEMENT;
        this.rotation = (item && item.rotation) || 0;

        // if item.originX = 0, left side is considered as false, value will be equal to right side => not the behavior we want
        this.originX = (item && item.originX) || 250;
        if (item && item.originX === 0 ){
            this.originX = 0;
        }

        this.originY = (item && item.originY) || 150;
        if (item && item.originY === 0 ){
            this.originY = 0;
        }

        this.endX = (item && item.endX) || 350;
        if (item && item.endX === 0 ){
            this.endX = 0;
        }

        this.endY = (item && item.endY) || 250;
        if (item && item.endY === 0 ){
            this.endY = 0;
        }
    }
    
    public startDraging() {
        this.onChange.emit(this);
    }


    // Each implementation should have a personalised copy() method to allow restorePrevState() method to work
    public copy(){
        return this.copy();
    }

    public getFunction() {
        return this.itemFunction;
    }

    public setFunction(itemFunction : ThumbnailItemTFunction) {
        this.itemFunction = itemFunction;
    }

    public setOriginPosition(x : number, y : number) {
        let width = this.getWidth();
        let height = this.getHeight();
        this.originX = x;
        this.originY = y;
        this.endX = x + width;
        this.endY = y + height;
        this.onItemChange.emit(this);
    }

    public setEndPosition(x : number, y : number) {
        this.endX = x;
        this.endY = y;
        this.onItemChange.emit(this);
    }

    public moveUp() {
        this.originY -= 2;
        this.endY -= 2;
        this.onItemChange.emit(this);
    }

    public moveDown() {
        this.originY += 2;
        this.endY += 2;
        this.onItemChange.emit(this);
    }

    public moveLeft() {
        this.originX -= 2;
        this.endX -= 2;
        this.onItemChange.emit(this);
    }

    public moveRight() {
        this.originX += 2;
        this.endX += 2;
        this.onItemChange.emit(this);
    }

    public setDimension(width : number, height : number) {
        let centerX = this.getCenterX();
        let centerY = this.getCenterY();
        this.originX = centerX - (width / 2);
        this.originY = centerY - (height / 2);
        this.endX = centerX + (width / 2);
        this.endY = centerY + (height / 2);
        this.onItemChange.emit(this);
    }

    public setRotation(rotation : number) {
        this.rotation = rotation;
        this.onItemChange.emit(this);
    }

    public getCenterX() {
        if (this.endX > this.originX)
            return this.originX + (this.getWidth() / 2);
        else
            return this.endX + (this.getWidth() / 2);
    }

    public getCenterY() {
        if (this.endY > this.originY)
            return this.originY + (this.getHeight() / 2);
        else
            return this.endY + (this.getHeight() / 2);
    }

    public getOriginX() {
        return this.originX;
    }

    public getOriginY() {
        return this.originY;
    }

    public getEndX() {
        return this.endX;
    }

    public getEndY() {
        return this.endY;
    }

    public getWidth() {
        if (this.endX > this.originX)
            return this.endX - this.originX;
        else
            return this.originX - this.endX;
    }

    public getHeight() {
        if (this.endY > this.originY)
            return this.endY - this.originY;
        else
            return this.originY - this.endY;
    }

    public getRotation() {
        return this.rotation;
    }
}
