import { Component, Input, OnInit } from "@angular/core";
import { DocumentationLinkService } from "../documentation-link.service";

/**
 * Component that display a little icon (question mark) that give documentation of its attached parameter.
 */
@Component({
  selector: "app-contextual-help-icon",
  templateUrl: "./contextual-help-icon.component.html",
  styleUrls: ["./contextual-help-icon.component.scss"],
})
export class ContextualHelpIconComponent implements OnInit {
  @Input("propertyScheme") propertyScheme;
  @Input("objectScheme") objectScheme;
  @Input() docLink = null;
  @Input("objectClass") objectClass = null;

  public label = "";
  public link = "";
  public image = null;

  public doc = {
    heading: "Loading...",
    body: "Loading...",
    enums: [],
  };

  constructor(private doclinkService: DocumentationLinkService) {}

  ngOnInit(): void {
    if (this.docLink) {
      this.link = this.doclinkService.generateLink(this.docLink);
    } else if (this.objectClass) {
      this.link = this.doclinkService.fromPropertyScheme(
        this.objectClass,
        this.propertyScheme
      );
    } else {
      this.link = this.doclinkService.fromObjetAndPropertySchemes(
        this.objectScheme,
        this.propertyScheme
      );
    }
    this.image = this.propertyScheme.docImage;
    this.label = this.getLabel();
    this.doc = this.getDoc();
  }

  private getLabel() {
    return this.propertyScheme.title
      ? this.propertyScheme.title
      : this.propertyScheme.name;
  }

  /**
   * Get the documentation of the given property and parse it to build a JS object.
   * @returns Documentation of the property
   */
  private getDoc() {
    const doc = { ...this.doc };

    // Retrieve full documentation text
    let fullDoc = "No description available";
    if (this.propertyScheme && this.propertyScheme.description) {
      fullDoc = this.propertyScheme.description;
    }

    // Get heading
    const headSplit = fullDoc.split(/\n|\. /, 2);
    doc.heading = headSplit[0];

    if (headSplit.length > 1) {
      // Retrieve description
      const bodySplit = fullDoc.substring(doc.heading.length).split(/\n\n/);
      doc.body = bodySplit[0];

      // Extract enums
      if (bodySplit.length > 1) {
        doc.enums = [];
        for (const item of bodySplit[1].split(/\n/)) {
          const enumSplit = item.split(/ - /);
          if (enumSplit.length > 1) {
            doc.enums.push({ name: enumSplit[0], description: enumSplit[1] });
          } else {
            if (item.trim()) doc.enums.push({ name: "", description: item });
          }
        }
      }
    } else {
      doc.body = "";
      doc.enums = [];
    }
    return doc;
  }
}
