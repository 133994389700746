import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-add-collection-item",
  templateUrl: "./add-collection-item.component.html",
  styleUrls: ["./add-collection-item.component.scss"],
})
export class AddCollectionItemComponent {
  @Input() searchFromParent = "";
  @Input() collection = { id: 0 };
  @Input() collections = [];
  @Input() usedCollections = [];
  @Input() level = 0;
  @Input() sprite: boolean;
  @Input() isBehaviorCollection = false;
  @Input() disableFunctionnalScenario = false;
  @Output() selected = new EventEmitter<any>();

  showChildren = false;
  searchMatches: any;
  showMatches = false;

  public select(selectedCollection) {
    this.selected.emit(selectedCollection);
  }

  hasChildren() {
    return (
      this.collections.filter((c) => c.parent == this.collection.id).length > 0
    );
  }

  isUsed(collection) {
    if (this.isBehaviorCollection) {
      return this.usedCollections
        ? this.usedCollections.find(
            (c) =>
              c.description == collection.description &&
              c.name == collection.name &&
              c.parent == collection.parent
          )
        : false;
    } else {
      return this.usedCollections
        ? this.usedCollections.find((c) => c.id == collection.id)
        : false;
    }
  }
}
