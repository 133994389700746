<div class="d-flex flex-column" *ngIf="nodeMatchFilter(filtersTree)">
    <button *ngIf="!flatView" class="btn btn-light text-left mb-2" (click)="filtersTree.expanded = !filtersTree.expanded">
        <mat-icon>{{ (filtersTree.expanded ? 'expand_more' : 'chevron_right') }}</mat-icon>
        <span class="px-2">{{ filtersTree.title }}</span>
    </button>
    <div *ngIf="filtersTree.expanded || flatView" class="d-flex flex-column" [ngClass]="{ 'ml-3': !flatView }">
        <ng-container *ngFor="let filter of filtersTree.properties; let i = index" >
            <ng-container *ngIf="propertyMatchFilter(filter, filtersTree)">
                <button class="btn btn-link text-left mb-2" (click)="setCurrentFilter(filter)">
                    <span  class="badge badge-secondary">{{ filtersTree.title === 'scenario' ? 'scenario' : deleteStar(filter.category) }}</span><br />{{ filter.title ? filter.title : filter.name }}
                </button>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let node of filtersTree.nodes; let i = index">
            <app-filters-dialog-input-parameter
                [filtersTree]="node"
                [currentFilter]="currentFilter"
                [nameFilter]="nameFilter"
                [flatView]="flatView"
                (onCurrentFilterChange)="fireCurrentFilterChanged($event)">
            </app-filters-dialog-input-parameter>
        </ng-container>
    </div>
</div>
