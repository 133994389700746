import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFilterParameter } from 'model/filterParameter';
import { IFilterOperator } from 'model/filterOperator';
import { DataModelService } from '@MOSAR/mosar-dashboard-datamodel';

@Component({
  selector: 'app-filters-dialog-input-value',
  templateUrl: './filters-dialog-input-value.component.html',
  styleUrls: ['./filters-dialog-input-value.component.scss']
})
export class FiltersDialogInputValueComponent implements OnInit {

  public currentMultiLevelParamField: any;
  public currentMin: number;
  public currentMax: number;
  public isFromFilter: true;

  @Input()
    set currentFilter(filter: IFilterParameter) { this.updateCurrentFilter(filter) };
    get currentFilter() { return this._currentFilter }
  private _currentFilter: IFilterParameter;

  @Input()
    set currentConstraint(constraint: IFilterOperator) { this.updateCurrentConstraint(constraint) };
    get currentConstraint() { return this._currentConstraint }
  private _currentConstraint: IFilterOperator;

  @Input() currentValue: string | number;
  @Output() currentValueChange = new EventEmitter(true); // we add true so we emit async

  constructor(private dataModel: DataModelService) { }

  ngOnInit() {
    this.setCurrentValue(this.currentValue);
    if (this._currentFilter && this._currentFilter.fields && this._currentFilter.enum) {
      const isAbstract = this._currentFilter.enum.filter((field) => this.currentValue === field).length > 0;

      this.currentMultiLevelParamField = isAbstract ? this._currentFilter.fields[0] : this._currentFilter.fields[1];
    }
  }

  public onCurrentFieldChange() {
    this.setCurrentValue(null);
  }

  isCustomEnum(){
    return this.currentFilter && this.currentFilter['x-custom-enum'];
  }

  isRef(){
    return this.currentFilter && ((this.currentFilter['items'] && this.currentFilter['items']['$ref']) || (this.currentFilter['$ref'] && this.currentFilter['$ref'] != '#/definitions/MultiLevelParam'));
  }

  public emitNewValue() {
    this.currentValueChange.emit(this.currentValue);
  }

  public onRangeChange() {
    if ((this.currentMin || '') || (this.currentMax || '')) {
      this.currentValueChange.emit(`${this.currentMin}:${this.currentMax}`);
      return
    }
    this.currentValueChange.emit(null);
  }

  private updateCurrentFilter(filter: IFilterParameter) {
    this._currentFilter = filter;
    // If is multilevelparam, select first field as default
    this.currentMultiLevelParamField = filter && filter.fields ? filter.fields[0] : null;
    // Reset values when we change filter
    this.setCurrentValue(null);
  }

  private updateCurrentConstraint(constraint: IFilterOperator) {
    // Reset values when we change constraint
    if ((this._currentConstraint || constraint) && this.isNewConstraint(this._currentConstraint, constraint)) {
      this.setCurrentValue(null);
    }
    this._currentConstraint = constraint;
  }

  private setCurrentValue(newValue: string | number) {
    this.currentValue = newValue;
    if (newValue && typeof newValue === 'string' && newValue.indexOf(":") >= 0) {
      const rangeValues = newValue.split(":");

      this.currentMin = rangeValues[0] && !isNaN(parseFloat(rangeValues[0])) ? parseFloat(rangeValues[0]) : null;
      this.currentMax = rangeValues[1] && !isNaN(parseFloat(rangeValues[1])) ? parseFloat(rangeValues[1]) : null;
      this.currentValue = this.currentMin !== null && this.currentMax !== null ? newValue : null;
    } else {
      this.currentMin = this.currentMax = null;
    }
    this.currentValueChange.emit(this.currentValue);
  }

  private isNewConstraint(obj1, obj2) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }

  setObject(obj){
    this.dataModel.getTemplate(this.currentFilter['title'].split(' / ').pop()?.toLowerCase(), obj.id).subscribe(newObj => {
      this.currentValue = newObj['path'];
      this.emitNewValue();
    })
  }

}
