import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";

@Component({
  selector: "app-custom-enum-item",
  templateUrl: "./custom-enum-item.component.html",
  styleUrls: ["./custom-enum-item.component.scss"],
})
export class CustomEnumItemComponent {
  @Input("element") element;
  @Input("usedelements") usedelements = [];
  @Input("level") level = 0;
  @Input() model;
  @Output() selected = new EventEmitter<any>();

  showChildren = false;

  public select(selectedElement) {
    this.selected.emit(selectedElement);
  }

  isUsed(id) {
    return this.usedelements
      ? !!this.usedelements.find((elem) => elem == id)
      : false;
  }
}
