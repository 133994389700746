import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'espaceHTML'
})
export class EspaceHTMLPipe implements PipeTransform {

  transform(value: any): any {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
    
    return value ? value.replace(/[&<>"']/g, function(m) { return map[m]; }) : '';
  }

}
