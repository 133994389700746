import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConfigureOptions {
  ConfigurationURL: string = "assets/config.json";
  AppVersion: string = "";
  BustCache: boolean = false;
}
