import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[loadImage]",
})
export class LoadImageDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("load") onLoad() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      "src",
      this.el.nativeElement.src
    );
  }
}

@Directive({
  selector: "[simpleAccordion]",
})
export class SimpleAccordionDirective {
  @HostBinding("class.active") isActive = false;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  @HostListener("click") onClick() {
    this.isActive = !this.isActive;
    const panel = this.renderer.nextSibling(this.el.nativeElement);
    const iconEl = this.el.nativeElement.querySelector("mat-icon");
    if (panel.style.display === "block") {
      panel.style.display = "none";
      iconEl.textContent = "chevron_right";
    } else {
      panel.style.display = "block";
      iconEl.textContent = "expand_more";
    }
    this.isActiveChange.emit(this.isActive);
  }
}
