<div ngbDropdown container="body" class="dropdown btn-group" role="group">
    <button class="btn btn-success btn-sm" [disabled]="disabled" id="addCollectionDropdown" ngbDropdownToggle>
        <mat-icon>{{ icon || 'unarchive' }}</mat-icon> {{label}}
    </button>
    <div class="pt-0 dropdown-menu" ngbDropdownMenu aria-labelledby="addCollectionDropdown">
        <div class="py-2 text-light bg-dark">
            <mat-icon triggers="hover"
                [ngbTooltip]="'Import an item from a collection. Items listed above are the items of collections that are associtated to the container of this object, see Collection setup in the container description page.'"
                placement="right" class="help float-right" container="body">help</mat-icon>

            <div class="d-flex align-items-center">
                <span class="ml-2">
                    <mat-icon>search</mat-icon>
                </span>
                <input type="text" class="form-control form-control-sm mx-2" name="search" placeholder="Search" [(ngModel)]="search" autocomplete="off">
            </div>
        </div>

        <ng-container *ngFor="let item of availableCollections | removeNull | filterSearch:search:collectionName">
            <label class="m-2">{{item.container.name}}</label>
            <app-add-collection-item [collections]="item[collectionName]" [searchFromParent]="search" [usedCollections]="usedCollections" [isBehaviorCollection]="isBehaviorCollection"
                (selected)="onSelectCollection.emit($event)" [sprite]="true"></app-add-collection-item>
        </ng-container>

        <ng-container *ngIf="!availableCollections.length">
            <div class="small m-2"><a class="disabled"><i>No {{ collectionName }} available</i></a></div>
        </ng-container>
    </div>
</div>