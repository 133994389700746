import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { KeycloakService, KeycloakAuthGuard } from "keycloak-angular";
import { AppStatusService } from "service/app-status/app-status.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected router: Router,
        protected keycloakService: KeycloakService
    ) {
        super(router, keycloakService);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.authenticated) {
                this.keycloakService.login();
                return;
            } else {
                return resolve(true);
            }
        });
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.authenticated) {
                this.keycloakService.login();
                return;
            } else {
                return resolve(true);
            }
        });
    }
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivateGuard {
    constructor(public appStatus: AppStatusService){}
    
    canDeactivate(){
        if(this.appStatus.isEditionOngoing){
            if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
                this.appStatus.setEditionOngoing(false);
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}