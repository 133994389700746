import {
  Directive,
  HostListener,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";

@Directive({
  standalone: true,
  selector: "[regexCheck]",
})
export class RegexDirective {
  @Input() disabledRegex = false;
  @Input() regex = null;
  @Output() displayError = new EventEmitter<boolean>();

  private omit_special_char(event: KeyboardEvent) {
    if (this.disabledRegex) {
      return event.key;
    } else {
      const pattern = new RegExp(this.regex);
      if (pattern.test(event.key)) {
        this.displayError.emit(false);
        return event;
      } else {
        this.displayError.emit(true);
        event.preventDefault();
      }
    }
  }

  @HostListener("keypress", ["$event"]) keypress(event) {
    this.omit_special_char(event);
  }
}
