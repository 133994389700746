<ng-container *ngIf="spriteable">
  <app-picture-update-dialog #updateDialog [image-id]="spriteable.image" (update)="updatePicture($event)" >
    <label>Picture for:</label>
    <code class="text-dark">{{ spriteable.name ? spriteable.name : spriteable.label }}</code>
  </app-picture-update-dialog>

  <div class="container">
    <div *ngIf="spriteable?.sprite">
      <img class="card-img-top" [ngClass]="{'pointer': clickable, 'sprite-sm': small}" (click)="openDialog()" [attr.src]="storeUrl+'/'+spriteable.sprite | secure | async" alt="Unavailable" #picture />
    </div>
    <div *ngIf="!spriteable?.sprite && spriteable?.image">
      <img class="card-img-top" [ngClass]="{'pointer': clickable, 'sprite-sm': small}" (click)="openDialog()" [attr.src]="storeUrl+'/'+spriteable.image | secure | async" alt="Unavailable" #picture />
    </div>
    <div *ngIf="!spriteable?.sprite && !spriteable?.image">
      <img class="card-img-top" src="../../assets/images/unavailable-xs.png" alt="" />
    </div>
    <button class="btn btn-sm btn-success" *ngIf="!readonly" (click)="updateDialog.show()" id="btnEditSpriteView">
      <small><mat-icon aria-label="Edit">edit</mat-icon></small>
    </button>
  </div>
</ng-container>