<ng-container>
  <!-- First, check if parameter and constraint are selected -->
  <ng-container *ngIf="!currentFilter || !currentConstraint; then disabledValueBlock; else rangeClauseBlock"></ng-container>

  <!-- Then we check if constraint is range -->
  <ng-template #rangeClauseBlock>
    <ng-container *ngIf="currentConstraint?.operator === 'bt'; then rangeValueBlock; else typeBasedBlock"></ng-container>
  </ng-template>

  <!-- Check for string, boolean and MLP -->
  <ng-template #typeBasedBlock>
    <ng-container *ngIf="currentFilter?.type === 'string' || currentFilter?.type === 'integer'">
      <ng-container *ngIf="currentFilter.enum; then enumValueBlock; else singleInputValueBlock"></ng-container>
    </ng-container>
  
    <ng-container *ngIf="currentFilter?.type === 'boolean'; then booleanValueBlock"></ng-container>
  
    <ng-container *ngIf="currentFilter?.type === 'multiLevelParam'; then multiLevelParamValueBlock"></ng-container>

    <ng-container *ngIf="currentFilter?.type === 'array' && !isRef(); then arrayValueBlock"></ng-container>
    
    <ng-container *ngIf="isRef(); then customObjectValueBlock"></ng-container>
  </ng-template>

  <!-- Declare blocks to be reused -->
  <ng-template #singleInputValueBlock>
    <div class="input-group">
      <input class="form-control" type="{{ currentFilter.type === 'string' ? 'string' : 'number'}}" [(ngModel)]="currentValue" (ngModelChange)="emitNewValue()">
      <div class="input-group-append" *ngIf="currentMultiLevelParamField?.type !== 'string' && currentFilter.type !== 'string'">
        <span class="input-group-text">{{ currentFilter.unit }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #singleInputArrayValueBlock>
    <div class="input-group">
      <input class="form-control" type="{{ currentFilter.items.type === 'string' ? 'string' : 'number'}}" [(ngModel)]="currentValue" (ngModelChange)="emitNewValue()">
      <div class="input-group-append" *ngIf="currentFilter.items.type !== 'string'">
        <span class="input-group-text">{{ currentFilter.unit }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #booleanValueBlock>
    <div class="radioBoxContainer">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" [(ngModel)]="currentValue" (change)="emitNewValue()" id="inlineRadio1" value="true">
        <label class="form-check-label" for="inlineRadio1">True</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" [(ngModel)]="currentValue" (change)="emitNewValue()" id="inlineRadio2" value="false">
        <label class="form-check-label" for="inlineRadio2">False</label>
      </div>
    </div>
  </ng-template>

  <ng-template #enumValueBlock>
    <ng-select appendTo="body" [items]="currentFilter.enum" [(ngModel)]="currentValue" (change)="emitNewValue()" ></ng-select>
  </ng-template>

  <ng-template #rangeValueBlock>
    <!-- Min input -->
    <div class="row range-inputs-container">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Min</span>
        </div>
        <input [(ngModel)]="currentMin" (ngModelChange)="onRangeChange()" pattern="^([-+,0-9.]+)" type="number" class="form-control">
        <div class="input-group-append">
          <span class="input-group-text">{{ currentFilter.unit }}</span>
        </div>
      </div>
      <span>to</span>
      <!-- Max input -->
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Max</span>
        </div>
        <input [(ngModel)]="currentMax" (ngModelChange)="onRangeChange()"  pattern="^([-+,0-9.]+)" type="number" class="form-control">
        <div class="input-group-append">
          <span class="input-group-text">{{ currentFilter.unit }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #multiLevelParamValueBlock>
    <div class="form-group row">
      <!-- Display select list to choose which type of value -->
      <div class="col-sm-4">
        <ng-select appendTo="body" [items]="currentFilter.fields" bindLabel="title" [(ngModel)]="currentMultiLevelParamField" (change)="onCurrentFieldChange()" [clearable]="false" ></ng-select>
      </div>
      <!-- According to the type of value choosed, we display the right input(s) -->
      <div class="col-sm-8">
        <ng-container *ngIf="currentMultiLevelParamField?.type === 'string'; then enumValueBlock; else singleInputValueBlock"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #arrayValueBlock>
    <ng-container *ngIf="isCustomEnum(); then customEnumsValueBlock"></ng-container>
    <ng-container *ngIf="!isCustomEnum(); then contSingleInputArrayValueBlock"></ng-container>
    <ng-template #contSingleInputArrayValueBlock *ngIf="!isCustomEnum() && currentFilter?.items?.type === 'string' || currentFilter?.items?.type === 'integer'; then singleInputArrayValueBlock"></ng-template>
    <ng-template #customEnumsValueBlock>
      <app-custom-enum-view
          [scheme]="currentFilter"
          [model]="currentValue ? [currentValue] : currentValue"
          [dataSource]="currentFilter['x-custom-enum']"
          [single]="true"
          [readonly]="readonly"
          [isFromFilter]="isFromFilter"
          (update)="currentValue = $event[0]; emitNewValue()">
      </app-custom-enum-view>
    </ng-template>
  </ng-template>

  <ng-template #customObjectValueBlock>
    <ng-container *ngIf="currentFilter.availableObjects?.length">
    <div class="toolbar btn-group" role="group" *ngIf="!readonly">
      <button class="btn btn-success btn-sm" disabled="true">
        <mat-icon>edit</mat-icon>
        <span *ngIf="currentFilter.availableObjectsReady == false" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
      <app-add-collection-item-dropdown
        *ngIf="currentFilter.availableObjectsReady == true"
        [label]="currentFilter.Title"
        [availableCollections]="currentFilter.availableObjects"
        [collectionName]="'objects'"
        (onSelectCollection)="setObject($event)" >
      </app-add-collection-item-dropdown>
    </div>

    <p>
      <app-collection-item-path-view
        [path]="currentValue"
        [availableObjects]="currentFilter.availableObjects"
        [availableObjectsReady]="currentFilter.availableObjectsReady">
      </app-collection-item-path-view>
    </p>
    </ng-container>
    <ng-container *ngIf="!currentFilter.availableObjects?.length">
      <div class="small m-2"><a class="disabled"><i>There is no associated collection for this filter. You can link collections in the "Frames of reference" on this container's home page.</i></a></div>
    </ng-container>

  </ng-template>

  <ng-template #disabledValueBlock>
    <input type="text" class="form-control" disabled>
  </ng-template>

</ng-container>