
<ng-template #dialogTemplate let-c="close" let-d="dismiss">
  <div class="modal-header bg-dark text-light">
    <span class="modal-title">
      <mat-icon>{{ icon }}</mat-icon>
      Confirmation
    </span>
    <button type="button" class="close" aria-label="Close" (click)="d('close')">
      <span aria-hidden="true" class="text-light">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer bg-light">
    <button type="button" class="btn" (click)="d('cancel')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="c('delete')" [disabled]="disabled && !loader" id="btnDeleteConfirmDialog">{{ confirmLabel }}</button>
  </div>
</ng-template>
