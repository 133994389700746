import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any, field: string, isResources: boolean): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      let elemA = this.elem(a, field, isResources);
      let elemB = this.elem(b, field, isResources);
      if(typeof elemA == 'string')
        elemA = elemA?.toLowerCase();
      if(typeof elemB == 'string')
        elemB = elemB?.toLowerCase();
      if (elemA < elemB) {
        return -1;
      } else if (elemA > elemB) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  private elem(elem, field, isresources) {
    return isresources ? elem.resources[0][field] : elem[field]
  }
}
