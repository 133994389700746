import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IFilter } from "model/filter";
import { FiltersService } from "service/scenariomanager-api/filters.service";
import { IFilterParameter } from "model/filterParameter";
import { ContainerService } from "../container.service";
import { CollectionService } from "app/collection/collection.service";
import {
  DataModelService,
  ParamScheme,
} from "@MOSAR/mosar-dashboard-datamodel";

const DEFAULT_CATEGORY = "Meta data";

export class FilterNode {
  path: string;
  title: string;
  properties: any[];
  nodes: FilterNode[];
  expanded?: boolean = false;
}

@Component({
  selector: "app-filters-dialog",
  templateUrl: "./filters-dialog.component.html",
  styleUrls: ["./filters-dialog.component.scss"],
})
export class FiltersDialogComponent implements OnInit {
  @Input() indexes: number[];
  @Input() filters: IFilter[];
  @Input() rootClassName: string;
  @Input() container: any;
  @Input() keepInMemory = true;

  currentFilter: IFilterParameter;
  subFilters = [];
  flattenedParameters: ParamScheme[];
  flatView = true;

  filtersTree: FilterNode;
  nameFilter = "";

  constructor(
    public modalRef: NgbActiveModal,
    private dataModel: DataModelService,
    private filtersService: FiltersService,
    private containerService: ContainerService,
    private collectionService: CollectionService
  ) {}

  ngOnInit() {
    this.flattenedParameters = this.dataModel.getFlattenedParametersForClass(
      this.rootClassName
    );
    this.filtersTree = this.createTreeFromFlatFilters();

    if (this.indexes) {
      const currentFilterName = this.filters[this.indexes[0]].parameter.name;
      const filter = this.flattenedParameters.find(
        (parameter) => parameter.name == currentFilterName
      );
      this.currentFilter = this.appendAvailableItemsToFilter(filter);
      this.subFilters = this.indexes.map((i) => {
        return {
          operator: this.filters[i].operator,
          value: this.filters[i].value,
        };
      });
    }
  }

  //FIXME: Move the code in a service or in the datamodel lib
  //-- FROM HERE

  private createTreeFromFlatFilters(): FilterNode {
    // First pass: add all filters using their real position in the tree
    const tree = { path: "/", title: "Root", properties: [], nodes: [] };
    for (const filter of this.flattenedParameters) {
      this.insertIntoTree(tree, filter.name.split("/"), filter);
    }
    // Second pass: refactor the tree to be more human-readable.
    this.moveUpUnitaryProperties(tree);
    this.moveDownRootProperties(tree);
    this.groupRootAttributes(tree);
    this.sortNodesAndAttributes(tree);
    return tree;
  }

  private moveUpUnitaryProperties(node: FilterNode, depth = 1) {
    for (const subNode of node.nodes) {
      const updatedSubProperties = [];
      for (const subProperty of subNode.properties) {
        if (subProperty.name.split("/").length <= depth) {
          node.properties.push(subProperty);
        } else {
          updatedSubProperties.push(subProperty);
        }
      }
      subNode.properties = updatedSubProperties;
      this.moveUpUnitaryProperties(subNode, depth + 1);
    }
  }

  private moveDownRootProperties(node: FilterNode) {
    const metaNode = { path: "", title: "Metadata", properties: [], nodes: [] };
    for (const property of node.properties) {
      property.category = "metadata";
    }
    metaNode.properties = node.properties;
    node.properties = [];
    node.nodes.push(metaNode);
  }

  private groupRootAttributes(node: FilterNode, groupName = "scenario") {
    const group: FilterNode = {
      path: "/",
      title: groupName,
      properties: [],
      nodes: [],
    };
    node.nodes.push(group);
    for (const subNode of node.nodes) {
      if (subNode.nodes.length == 1 && subNode.properties.length == 1) {
        group.properties.push(subNode.properties[0]);
        subNode.properties = [];
      }
    }
  }

  private sortNodesAndAttributes(node: FilterNode) {
    node.nodes.sort((a, b) => (a.path > b.path ? 1 : -1));
    node.properties.sort((a, b) => (a.path > b.path ? 1 : -1));
    for (const subNode of node.nodes) {
      this.sortNodesAndAttributes(subNode);
    }
  }

  private insertIntoTree(tree: FilterNode, paths: string[], item, level = 0) {
    const path = paths[level];
    let node = this.getNode(tree, path);

    // If the not does not already exists, creates it
    if (!node) {
      node = { path: path, title: paths[level], properties: [], nodes: [] };
      tree.nodes.push(node);
    }

    // Current path is the sheet, add the item in the properties ...
    if (level == paths.length || path == "value") {
      item.category = paths[0]; // Override category
      node.properties.push(item);
    }
    // ... its not the sheet, recursive call
    else {
      this.insertIntoTree(node, paths, item, level + 1);
    }
    return tree;
  }

  private getNode(tree: FilterNode, path: string): FilterNode {
    const node: FilterNode = tree.nodes.find((item) => item.path == path);
    return node != undefined ? node : null;
  }

  //-- END MOVE

  onCurrentFilterChange(filter: IFilterParameter) {
    this.currentFilter = this.appendAvailableItemsToFilter(filter);
    this.subFilters = [];
    this.addfilter();
  }

  getTypeLabel(filter) {
    if (!("type" in filter)) return "collection";
    if (filter.type == "string" && "enum" in filter) return "enum";
    if (
      filter.type == "array" &&
      ("enum" in filter.items || "x-custom-enul" in filter)
    )
      return "enum";
    return filter.type;
  }

  private appendAvailableItemsToFilter(
    filter: IFilterParameter
  ): IFilterParameter {
    // Append possible values when its a collection item filter
    if (
      filter &&
      ((filter["items"] && filter["items"]["$ref"]) || filter["$ref"])
    ) {
      const collectionType = filter["title"].split(" / ").pop().toLowerCase();
      const associatedObjectsContainers =
        this.container?.associatedContainers?.[collectionType + "s"];
      if (associatedObjectsContainers) {
        filter.availableObjects = [];
        filter.availableObjectsReady = false;
        let stillToResolePromises = associatedObjectsContainers.length;
        associatedObjectsContainers.map((collectionId, index) => {
          this.containerService
            .getContainerById(collectionType, collectionId, true)
            .toPromise()
            .then((container) => {
              this.collectionService
                .findCollections(collectionType, collectionId, true)
                .toPromise()
                .then((res) => {
                  filter.availableObjects[index] = {
                    container: container.resources[0],
                    objects: res.resources,
                  };
                  filter.availableObjectsReady = --stillToResolePromises == 0;
                })
                .catch((err) => {
                  filter.availableObjectsReady = --stillToResolePromises == 0;
                });
            })
            .catch((err) => {
              filter.availableObjectsReady = --stillToResolePromises == 0;
            });
        });
      }
    }
    return filter;
  }

  public submitFilter() {
    // Create a new IFilter for each sub filter having a value
    const newFilters = this.subFilters
      .map((subfilter) => {
        return {
          parameter: this.currentFilter,
          operator: subfilter.operator,
          value: subfilter.value,
        };
      })
      .filter((f) => f.value);

    // Register the filter into the context of the filter service
    if (this.indexes) {
      this.filters = this.filtersService.forceUpdateFilters(
        this.filters,
        this.indexes,
        newFilters,
        this.keepInMemory
      );
    } else {
      this.filters = this.filtersService.createFiltersWithObjects(
        this.filters,
        newFilters,
        this.keepInMemory
      );
    }
    this.modalRef.close(this.filters);
  }

  removefilter(i) {
    this.subFilters.splice(i, 1);
  }

  addfilter() {
    this.subFilters.push({ operator: undefined, value: undefined });
  }

  submitButtonDisabled(): boolean {
    return (
      this.subFilters &&
      !(this.subFilters.filter((sub) => sub.value && sub.operator).length > 0)
    );
  }
}
