<ng-container *ngIf="annotation">
    <div [ngbPopover]="popContent" #p="ngbPopover" [popoverTitle]="'Annotation'" placement="right" container="body"
        class="d-inline">
        <mat-icon>info</mat-icon>
        <div *ngIf="!!simulation" class="usecasegroup">
            <div class="usecaseinfo input" *ngIf="annotation.input"></div>
            <div class="usecaseinfo trigger" *ngIf="annotation.trigger"></div>
            <div class="usecaseinfo expected" *ngIf="annotation.expected"></div>
        </div>
    </div>
    <ng-template #popContent>
        <div (click)="$event.stopPropagation()">
            <ng-container *ngIf="showParameterStatusSection()">
                <div class="d-flex align-items-center justify-content-between mx-2">
                    <label class="m-0">Unknown :</label>
                    <label *ngIf="!readonly" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.isUnknown"
                            (ngModelChange)="setAnnotation('isUnknown'); save()">
                        <span class="slider round"></span>
                    </label>
                    <label *ngIf="readonly" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.isUnknown" [disabled]="true">
                        <span *ngIf="annotation.isUnknown" class="badge badge-pill badge-info custom-badge">Yes</span>
                        <span *ngIf="!annotation.isUnknown" class="badge badge-pill badge-light custom-badge">No</span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between mx-2">
                    <label class="m-0">Mandatory :</label>
                    <label *ngIf="!readonly" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.mandatory"
                            (ngModelChange)="setAnnotation('mandatory'); save()">
                        <span class="slider round"></span>
                    </label>
                    <label *ngIf="readonly" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.mandatory" [disabled]="true">
                        <span *ngIf="annotation.mandatory" class="badge badge-pill badge-info custom-badge">Yes</span>
                        <span *ngIf="!annotation.mandatory" class="badge badge-pill badge-light custom-badge">No</span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between mx-2">
                    <label class="m-0">Optional :</label>
                    <label *ngIf="!readonly" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.optional"
                            (ngModelChange)="setAnnotation('optional'); save()">
                        <span class="slider round"></span>
                    </label>
                    <label *ngIf="readonly" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.optional" [disabled]="true">
                        <span *ngIf="annotation.optional" class="badge badge-pill badge-info custom-badge">Yes</span>
                        <span *ngIf="!annotation.optional" class="badge badge-pill badge-light custom-badge">No</span>
                    </label>
                </div>
            </ng-container>
            <ng-container *ngIf="showUseCaseRoleSection()">
                <hr *ngIf="showParameterStatusSection()">
                <label>Use Case setting</label>
                <div>
                    <input type="text" class="form-control" [(ngModel)]="annotation.name"
                        [disabled]="readonly && !overrideReadonly()" placeholder="Parameter Name">
                </div>
                <div class="d-flex align-items-center justify-content-between mx-2">
                    <label class="m-0">Input</label>
                    <label *ngIf="!readonly ||  overrideReadonly()" class="switch m-2" [matTooltip]="annotation.expected? 'Disabled because expected is selected' : ''">
                        <input type="checkbox" [(ngModel)]="annotation.input" (ngModelChange)="save()" 
                            [disabled]="annotation.expected">
                        <span class="slider round"></span>
                    </label>
                    <label *ngIf="readonly && !overrideReadonly()" class="switch m-2" >
                        <input type="checkbox" [(ngModel)]="annotation.input" [disabled]="true">
                        <span *ngIf="annotation.input" class="badge badge-pill badge-info custom-badge">Yes</span>
                        <span *ngIf="!annotation.input" class="badge badge-pill badge-light custom-badge">No</span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between mx-2">
                    <label class="m-0">Trigger</label>
                    <label *ngIf="!readonly || overrideReadonly()" class="switch m-2" [matTooltip]="annotation.expected? 'Disabled because expected is selected' : ''">
                        <input type="checkbox" [(ngModel)]="annotation.trigger" (ngModelChange)="save()"  
                            [disabled]="annotation.expected">
                        <span class="slider round"></span>
                    </label>
                    <label *ngIf="readonly && !overrideReadonly()" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.trigger" [disabled]="true">
                        <span *ngIf="annotation.trigger" class="badge badge-pill badge-info custom-badge">Yes</span>
                        <span *ngIf="!annotation.trigger" class="badge badge-pill badge-light custom-badge">No</span>
                    </label>
                </div>
                <div class="d-flex align-items-center justify-content-between mx-2">
                    <label class="m-0">Expected</label>
                    <label *ngIf="!readonly || overrideReadonly()" class="switch m-2" [matTooltip]="(annotation.input || annotation.trigger )? 'Disabled because input or trigger is selected' : ''">
                        <input type="checkbox" [(ngModel)]="annotation.expected" (ngModelChange)="save()" 
                            [disabled]="annotation.input || annotation.trigger">
                        <span class="slider round"></span>
                    </label>
                    <label *ngIf="readonly && !overrideReadonly()" class="switch m-2">
                        <input type="checkbox" [(ngModel)]="annotation.expected" [disabled]="true">
                        <span *ngIf="annotation.expected" class="badge badge-pill badge-info custom-badge">Yes</span>
                        <span *ngIf="!annotation.expected" class="badge badge-pill badge-light custom-badge">No</span>
                    </label>
                </div>
            </ng-container>
        </div>
    </ng-template>
</ng-container>