<ng-container *ngIf="picturable">
  <app-picture-update-dialog #updateDialog [image-id]="picturable.image" (update)="updatePicture($event)">
    <label>Picture for:</label>
    <code class="text-dark">{{ picturable.name ? picturable.name : picturable.label }}</code>
  </app-picture-update-dialog>

  <div class="container">

    
    <!-- <i> -->
      <img *ngIf="picturable.image" align="left" class="card-img-top" [ngClass]="{'pointer': !thumbnail}" #picture
        [attr.src]="storeUrl+'/'+picturable.image | secure | async" alt="" (click)="openDialog()" />
      <img *ngIf="!picturable.image" align="left" class="card-img-top" src="../../assets/images/unavailable-sm.png" alt="Picture unavailable" />
    <!-- </i> -->
    <div class="bottom-btn">
      <button class="btn btn-sm btn-success" *ngIf="!readonly && generator" (click)="thumbDialog()" id="btnGeneratorPictureView">
        <small>
          <mat-icon aria-label="Palette">palette</mat-icon>
        </small>
      </button>
      <button class="btn btn-sm btn-success btn-right" *ngIf="!readonly" (click)="updateDialog.show()" id="btnEditPictureView">
        <small>
          <mat-icon aria-label="Edit">edit</mat-icon>
        </small>
      </button>
    </div>
  </div>
</ng-container>