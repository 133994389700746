
<ng-template #dialogTemplate let-c="close" let-d="dismiss">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header bg-dark text-light">
      <span class="modal-title">
        <mat-icon>attach_file</mat-icon>
        Attach file
      </span>
      <button type="button" class="close" aria-label="Close" (click)="d('close')">
        <span aria-hidden="true" class="text-light">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="content">
        <ng-content></ng-content>
      </div>
      <!--label>File:</label-->
      <small>Please select a file</small>
      <div class="form-group custom-file">
        <label for="attachment">File</label>
        <input type="file" id="attachment" class="custom-file-input"
              (change)="onFileChange($event)" #fileInput>
        <label class="custom-file-label" for="attachment" id="labelAttachmentFileUpdateDialog">{{fileLabel}}</label>
      </div>
    </div>
    <div class="modal-footer bg-light d-flex justify-content-between">
      <button type="button" class="btn" (click)="removeFile()">
        No file
      </button>
      <div>
        <button type="button" class="btn mr-2" (click)="d('cancel')" id="btnCancelFileUpdateDialog">
        Cancel
      </button>
      <button type="submit" [disabled]="form.invalid || loading" class="btn btn-success ml-2" id="btnOkFileUpdateDialog">
        Ok <div *ngIf="loading" class="spin-loader spin-loader-sm"></div>
      </button>
      </div>
    </div>
  </form>
</ng-template>
