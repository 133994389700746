import { ThumbnailItem } from '../thumbnail-item-model';

/**
 * Image
 * Item of a thumbnail that is an image.
 */
export class ThumbnailImage extends ThumbnailItem {
    protected source: any;
    protected url: string;

    public constructor(item: any) {
        super(item);
        this.source = item.source;
        this.url = item.url;

        if (item.width && item.height) {
            this.setDimension(item.width, item.height);
        }
        // Resize the image if bigger than 600*400
        this.resizeImg(item);

    }

    public copy() {
        let temp = new ThumbnailImage({ ...this });
        return temp;
    }

    public getSource() {
        return this.source;
    }

    private resizeImg(item) {
        if (item.width && item.height) {
            let w = item.width;
            let h = item.height;
            let scale = 1;

            if (item.width - 600 > item.height - 400) {
                if (item.width > 600) {
                    scale = item.width / 600;
                }
            }
            else {
                if (item.height > 400) {
                    scale = item.height / 400;
                }
            }
            this.setDimension(w / scale, h / scale);
        }
    }
}
